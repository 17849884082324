import { cssFns, useKeyframes } from "@superweb/css";

import { useUiColors } from "../theme";

/**
 * Shimmer animation style.
 *
 * Apply it to an element that is stretched to fill its container.
 *
 * @example
 * ```
 * const shimmer = useShimmer();
 *
 * <div
 *   css={{
 *     display: "grid",
 *   }}>
 *   <div css={{ ...cssFns.gridArea("1") }}>
 *     {content}
 *   </div>
 *   <div
 *    css={{
 *      ...shimmer,
 *      ...cssFns.gridArea("1"),
 *    }}
 *   />
 * </div>
 * ```
 */
export const useShimmer = (): {
  backgroundImage: string;
  animationDuration: string;
  animationIterationCount: string;
  animationName: string;
} => {
  const keyframes = useKeyframes();
  const uiColors = useUiColors();

  return {
    backgroundImage: cssFns.linearGradient("90deg", [
      `${cssFns.setOpacity(uiColors.background, 0)} 0%`,
      `${cssFns.setOpacity(uiColors.background, 0.2)} 20%`,
      `${cssFns.setOpacity(uiColors.background, 0.8)} 60%`,
      `${cssFns.setOpacity(uiColors.background, 0)} 100%`,
    ]),
    animationDuration: "2s",
    animationIterationCount: "infinite",
    animationName: keyframes({
      0: { transform: "translateX(-100%)" },
      100: { transform: "translateX(100%)" },
    }),
  };
};
