/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import React, {
  createContext,
  useContext,
  useRef,
  type HTMLAttributes,
  type ReactNode,
} from "react";
import type { AriaDialogProps } from "react-aria";
import {
  FocusScope,
  OverlayContainer,
  mergeProps,
  useDialog,
  useModal,
  useOverlay,
  usePreventScroll,
  type AriaOverlayProps,
} from "react-aria";

import { icons as fleetIcons } from "@fleet/ui";
import { cssFns } from "@superweb/css";
import { useLocale } from "@superweb/intl";
import { Button, UiColors, icons, useTypo, useUiColors } from "@superweb/ui";

import { useMessage } from "#intl";

type GalleryProps = {
  title?: string;
  onClose?: () => void;
  onNextClick?: () => void;
  onPrevClick?: () => void;
} & AriaOverlayProps &
  AriaDialogProps;

const GalleryContext = createContext<GalleryProps>({});

const GalleryButton = ({
  icon,
  ariaLabel,
  onPress,
}: {
  icon: React.ComponentType;
  ariaLabel: string;
  onPress?: () => void;
}) => {
  const uiColors = useUiColors();

  return (
    <UiColors
      value={{
        text: uiColors.textInvert,
      }}
    >
      <Button
        view="ghost"
        icon={icon}
        ariaLabel={ariaLabel}
        size="s"
        onPress={onPress}
      />
    </UiColors>
  );
};

const GalleryHeader = (titleProps: HTMLAttributes<HTMLElement>) => {
  const typo = useTypo();
  const { title, onClose } = useContext(GalleryContext);
  const message = useMessage();
  const uiColors = useUiColors();

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        paddingInlineStart: "16px",
      }}
    >
      <div
        css={{
          flexGrow: "1",
        }}
      >
        {title && (
          <h3
            {...titleProps}
            css={{
              ...typo({
                level: "caption1",
                weight: "regular",
                density: "normal",
              }),
              ...cssFns.margin("0px"),
              color: uiColors.textInvert,
            }}
          >
            {title}
          </h3>
        )}
      </div>
      <div
        css={{
          ...cssFns.padding("8px"),
          minWidth: "56px",
          boxSizing: "border-box",
        }}
      >
        <GalleryButton
          icon={icons.CrossL}
          ariaLabel={message({
            id: "8e0aaffd-b238-47ec-8599-d03a741b06e7",
            context: "Gallery. Close button text",
            default: "Close",
          })}
          onPress={onClose}
        />
      </div>
    </div>
  );
};

const GalleryContainer = ({
  children,
  ...props
}: AriaOverlayProps & AriaDialogProps & { children: ReactNode }) => {
  const uiColors = useUiColors();
  const message = useMessage();

  const { onNextClick, onPrevClick, onClose } = useContext(GalleryContext);

  const {
    textInfo: { direction },
  } = useLocale();

  const ref = useRef(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(
    { onClose, ...props },
    ref,
  );
  const { modalProps } = useModal();
  const { dialogProps, titleProps } = useDialog(props, ref);

  usePreventScroll();

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (!event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
      switch (event.key) {
        case "ArrowRight":
          direction === "ltr" ? onNextClick?.() : onPrevClick?.();
          break;
        case "ArrowLeft":
          direction === "ltr" ? onPrevClick?.() : onNextClick?.();
          break;
        case "Escape":
          onClose?.();
          break;
      }
    }
  };

  return (
    <div
      css={{
        position: "fixed",
        ...cssFns.inset("0px"),
        display: "grid",
        backgroundColor: cssFns.setOpacity(uiColors.fog, 0.5),
        zIndex: "4",
      }}
      {...underlayProps}
    >
      <FocusScope contain restoreFocus autoFocus>
        <div
          {...mergeProps(overlayProps, dialogProps, modalProps)}
          ref={ref}
          css={{
            display: "grid",
            gridTemplateRows: "1fr auto",
            width: "100%",
            height: "100vh",
            backdropFilter: "blur(40px)",
          }}
          onKeyDown={onKeyDown}
        >
          <div
            css={{
              display: "grid",
              gridTemplateColumns: "100%",
              gridTemplateRows: "56px 1fr 56px",
              ...cssFns.placeContent("start"),
              ...cssFns.overflow("hidden"),
              outlineStyle: "none",
            }}
            ref={childrenRef}
          >
            <GalleryHeader {...titleProps} />
            <div
              css={{
                display: "grid",
                paddingLeft: "8px",
                paddingRight: "8px",
                gridTemplateColumns: "40px 1fr 40px",
                columnGap: "8px",
                alignItems: "center",
              }}
            >
              {!!onPrevClick && (
                <GalleryButton
                  icon={fleetIcons.CornerInlineStart}
                  ariaLabel={message({
                    id: "1aeb0720-3680-4b58-9f9d-8454eed02370",
                    context: "Gallery. Previous button text",
                    default: "Previous image",
                  })}
                  onPress={onPrevClick}
                />
              )}
              <div
                css={{
                  justifySelf: "center",
                  height: "calc(100vh - 112px)",
                  gridColumnStart: "2",
                }}
              >
                {children}
              </div>
              {!!onNextClick && (
                <GalleryButton
                  icon={fleetIcons.CornerInlineEnd}
                  ariaLabel={message({
                    id: "1a391a20-b2e4-451c-9b31-06d1de118822",
                    context: "Gallery. Next button text",
                    default: "Next image ",
                  })}
                  onPress={onNextClick}
                />
              )}
            </div>
          </div>
        </div>
      </FocusScope>
    </div>
  );
};

export const Gallery = ({
  children,
  title,
  onClose,
  onNextClick,
  onPrevClick,
}: GalleryProps & {
  children: ReactNode;
}) => (
  <GalleryContext.Provider
    value={{
      title,
      onClose,
      onNextClick,
      onPrevClick,
    }}
  >
    <OverlayContainer>
      <GalleryContainer isOpen isDismissable>
        {children}
      </GalleryContainer>
    </OverlayContainer>
  </GalleryContext.Provider>
);
