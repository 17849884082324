/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { cssFns } from "@superweb/css";
import { LinkButton, useIsMobile, useTypo, useUiColors } from "@superweb/ui";

import { Message, useMessage } from "#internal/intl";
import { useLogAction } from "#internal/analytics";

import CarWithRoadAvif from "../images/car-with-road.avif";
import CarWithRoad from "../images/car-with-road.svg";
import CarWithRoadWebp from "../images/car-with-road.webp";
import TruckWithRoadAvif from "../images/truck-with-road.avif";
import TruckWithRoad from "../images/truck-with-road.svg";
import TruckWithRoadWebp from "../images/truck-with-road.webp";
import YandexEndPageAvif from "../images/yandex-end-page.avif";
import YandexEndPage from "../images/yandex-end-page.svg";
import YandexEndPageWebp from "../images/yandex-end-page.webp";
import { useBrand } from "../router";

export const DownloadAppPage = ({
  successMessage,
  androidHref,
  iosHref,
  isDelivery,
  country,
}: {
  successMessage: string;
  androidHref: string;
  iosHref?: string;
  isDelivery: boolean;
  country: string;
}) => {
  const typo = useTypo();
  const brand = useBrand();
  const message = useMessage();
  const uiColors = useUiColors();
  const isMobile = useIsMobile();
  const logActionSubmit = useLogAction("submit");

  const yangoBrand = brand === "yango";

  const imageSrc = yangoBrand
    ? isDelivery
      ? { avif: TruckWithRoadAvif, webp: TruckWithRoadWebp, svg: TruckWithRoad }
      : { avif: CarWithRoadAvif, webp: CarWithRoadWebp, svg: CarWithRoad }
    : { avif: YandexEndPageAvif, webp: YandexEndPageWebp, svg: YandexEndPage };

  const imageSize = isDelivery
    ? isMobile
      ? { width: 600, height: 280 }
      : { width: 630, height: 300 }
    : isMobile
      ? { width: 300, height: 260 }
      : { width: 330, height: 280 };

  const downloadButtonText = isMobile
    ? {
        android: message({
          id: "deaa7ee6-c18d-4fa9-a856-bcdd13d416c8",
          context: "Yandex forms. Download app page. Download button. Andorid",
          default: "Android",
        }),
        ios: message({
          id: "df988b6f-eaa1-4107-afc5-af9b528c7203",
          context: "Yandex forms. Download app page. Download button. IOS",
          default: "iOS",
        }),
      }
    : {
        android: message({
          id: "b011b396-25ce-4899-8699-7e2b821c00d2",
          context: "Yandex forms. Download app page. Download button. Andorid",
          default: "Android app",
        }),
        ios: message({
          id: "b011b396-25ce-4899-8699-7e2b821c00d1",
          context: "Yandex forms. Download app page. Download button. IOS",
          default: "iOS app",
        }),
      };

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        ...cssFns.padding("16px"),
        minWidth: "0",
      }}
    >
      <picture>
        <source src={imageSrc.avif} type="image/avif" />
        <source src={imageSrc.webp} type="image/webp" />
        <img
          src={imageSrc.svg}
          alt={message({
            id: "4310129f-45cf-40b1-b8ad-4472c6b25cc4",
            context: "Yango forms. Download app page. Image",
            default: "Vehicle",
          })}
          width={imageSize.width}
          height={imageSize.height}
        />
      </picture>
      <span
        css={{
          paddingBlockStart: "30px",
          paddingBlockEnd: "10px",
          ...typo({
            level: "title4",
            weight: "medium",
            density: "normal",
          }),
          color: uiColors.text,
        }}
      >
        {successMessage}
      </span>
      <span
        css={{
          paddingBlockEnd: "30px",
          ...typo({
            level: "body2",
            weight: "regular",
            density: "tight",
          }),
          color: uiColors.text,
          textAlign: "center",
          maxWidth: "600px",
        }}
      >
        {yangoBrand ? (
          <Message
            id="5855aa95-2a3f-4f6a-97b3-184c34fa1120"
            context="Yango forms. Download app page. Description"
            default="You've completed the registration and can start earning now. Please download the app and follow the instructions in it."
          />
        ) : (
          <Message
            id="9cadfa93-0072-4bdd-ab9c-586743bb9e2e"
            context="Yandex forms. Download app page. Description"
            default="It will be processed soon. In the meantime, download the Yandex Pro app to get further instructions and go online"
          />
        )}
      </span>
      {yangoBrand ? (
        <LinkButton
          text={message({
            id: "b011b396-25ce-4899-8699-7e2b821c00d0",
            context: "Yango forms. Download app page. Download button",
            default: "Download the app for free",
          })}
          view="action"
          link={{
            external: true,
            href: androidHref,
          }}
          onPress={() => logActionSubmit("download_app")}
        />
      ) : (
        <div
          css={{
            display: "grid",
            gridTemplateColumns: iosHref ? "1fr 1fr" : "1fr",
            columnGap: "8px",
          }}
        >
          {iosHref && (
            <LinkButton
              text={downloadButtonText["ios"]}
              link={{
                external: true,
                href: iosHref,
              }}
              onPress={() => logActionSubmit("download_app")}
            />
          )}
          <LinkButton
            text={downloadButtonText["android"]}
            link={{
              external: true,
              href: androidHref,
            }}
            onPress={() => logActionSubmit("download_app")}
          />
        </div>
      )}
      {country === "RU" ? (
        <div
          css={{
            marginBlockStart: "16px",
          }}
        >
          <LinkButton
            link={{
              external: true,
              href: "https://pro.yandex.ru/ru-ru/knowledge-base/taxi/new-driver/emulation",
            }}
            text={message({
              id: "a7ccf53b-34c3-47c1-a4f2-e8360266dd16",
              context: "Yandex forms. Download app page. Education link",
              default: "Education",
            })}
          />
        </div>
      ) : null}
    </div>
  );
};
