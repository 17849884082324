export const config =
  import.meta.env.FLEET_FORMS_ENV === "production"
    ? // production
      {
        version: "yango",
        passportURL: "https://passport.yango.com",
      }
    : // testing
      {
        version: "yango",
        passportURL: "https://passport-test.yango.com",
      };
