export class HttpError extends Error {
  #status: number;
  #headers: Headers;

  constructor(response: Response) {
    super(response.statusText);
    this.name = "HttpError";
    this.#status = response.status;
    this.#headers = response.headers;
  }

  get status() {
    return this.#status;
  }

  get headers() {
    return this.#headers;
  }
}

export type ApiShape = {
  [url: string]: {
    [method: string]: {
      request: {
        url: string;
        method: string;
        headers?: Record<string, string>;
        body?: unknown;
        query?: Record<string, string | number | boolean>;
        path?: Record<string, string>;
      };
      response: {
        status: number;
        headers?: Record<string, string>;
        body?: unknown;
      };
    };
  };
};

export const fetcher = async <
  Api extends ApiShape,
  Url extends keyof Api,
  Method extends keyof Api[Url],
>({
  url,
  query,
  path,
  headers,
  body,
  method,
  baseUrl,
}: Api[Url][Method]["request"] & { baseUrl: string }): Promise<
  Api[Url][Method]["response"]
> => {
  const searchParams = new URLSearchParams(
    query &&
      Object.entries(query).map(([key, value]) => [key, value.toString()]),
  );

  const urlWithPath = path
    ? url.replaceAll(/\{(\w+)\}/g, (_, key) => {
        return path[key]!;
      })
    : url;

  const normalizedBaseUrl = baseUrl.endsWith("/") ? baseUrl : baseUrl + "/";
  const normalizedPathUrl = urlWithPath.startsWith("/")
    ? urlWithPath.slice(1)
    : urlWithPath;
  const normalizedSearch = searchParams.size ? `?${searchParams}` : "";

  const finalUrl = `${normalizedBaseUrl}${normalizedPathUrl}${normalizedSearch}`;

  const request = new Request(finalUrl, {
    headers,
    body:
      body !== undefined && headers?.["content-type"] === "application/json"
        ? JSON.stringify(body)
        : (body as BodyInit),
    method,
  });

  const res = await fetch(request);

  if (
    res.status === 401 ||
    res.status === 403 ||
    res.status === 429 ||
    res.status >= 500
  ) {
    throw new HttpError(res);
  }

  const contentType = res.headers.get("content-type")?.split(";")[0]?.trim();

  return {
    status: res.status,
    headers: Object.fromEntries(res.headers),
    body:
      res.status === 204 || res.body === null
        ? undefined
        : contentType === "application/json"
          ? ((await res.json().catch((error) => {
              if (error instanceof SyntaxError) {
                throw new Error(
                  "A non-empty response body was expected. Use the 204 response status code if body is not needed",
                );
              }
              throw error;
            })) as Api[Url][Method]["response"]["body"])
          : ((await res.blob()) as Api[Url][Method]["response"]["body"]),
  };
};
