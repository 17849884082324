import {
  useEffect,
  useRef,
  useState,
  useContext,
  type PropsWithChildren,
  type RefObject,
} from "react";
import { Layer as BaseLayer } from "@deck.gl/core/typed";
import {
  ScatterplotLayer as BaseScatterplotLayer,
  type ScatterplotLayerProps,
  IconLayer as BaseIconLayer,
  type IconLayerProps,
  PathLayer as BasePathLayer,
  type PathLayerProps,
  TextLayer as BaseTextLayer,
  type TextLayerProps,
  PolygonLayer as BasePolygonLayer,
  type PolygonLayerProps,
  ArcLayer as BaseArcLayer,
  type ArcLayerProps,
  LineLayer as BaseLineLayer,
  type LineLayerProps,
  BitmapLayer as BaseBitmapLayer,
  type BitmapLayerProps,
  PointCloudLayer as BasePointCloudLayer,
  type PointCloudLayerProps,
  ColumnLayer as BaseColumnLayer,
  type ColumnLayerProps,
  GridCellLayer as BaseGridCellLayer,
  type GridCellLayerProps,
  GeoJsonLayer as BaseGeoJsonLayer,
  type GeoJsonLayerProps,
  SolidPolygonLayer as BaseSolidPolygonLayer,
  type SolidPolygonLayerProps,
} from "@deck.gl/layers/typed";
import {
  QuadkeyLayer as BaseQuadkeyLayer,
  type QuadkeyLayerProps,
} from "@deck.gl/geo-layers/typed";
import { v4 as uuidv4 } from "uuid";

import { LayersContext } from "./layers-context";

export const Layer = <D extends {}>({ layer }: { layer: BaseLayer<D> }) => {
  const { updateLayer, removeLayer } = useContext(LayersContext);

  useEffect(() => {
    updateLayer(layer);
  }, [layer, updateLayer]);

  useEffect(() => {
    return () => {
      removeLayer(layer.id);
    };
  }, [layer.id, removeLayer]);

  return null;
};

export const ScatterplotLayer = <D extends {}>(
  props: Omit<ScatterplotLayerProps<D>, "id">,
) => {
  const [id] = useState(() => uuidv4());
  return <Layer layer={new BaseScatterplotLayer<D>({ ...props, id })} />;
};

export const PolygonLayer = <D extends {}>(
  props: Omit<PolygonLayerProps<D>, "id">,
) => {
  const [id] = useState(() => uuidv4());
  return <Layer layer={new BasePolygonLayer<D>({ ...props, id })} />;
};

export const IconLayer = <D extends {}>(
  props: Omit<IconLayerProps<D>, "id">,
) => {
  const [id] = useState(() => uuidv4());
  return <Layer layer={new BaseIconLayer<D>({ ...props, id })} />;
};

export const PathLayer = <D extends {}>(
  props: Omit<PathLayerProps<D>, "id">,
) => {
  const [id] = useState(() => uuidv4());
  return <Layer layer={new BasePathLayer<D>({ ...props, id })} />;
};

export const TextLayer = <D extends {}>(
  props: Omit<TextLayerProps<D>, "id">,
) => {
  const [id] = useState(() => uuidv4());
  return <Layer layer={new BaseTextLayer<D>({ ...props, id })} />;
};

export const ArcLayer = <D extends {}>(props: Omit<ArcLayerProps<D>, "id">) => {
  const [id] = useState(() => uuidv4());
  return <Layer layer={new BaseArcLayer<D>({ ...props, id })} />;
};

export const LineLayer = <D extends {}>(
  props: Omit<LineLayerProps<D>, "id">,
) => {
  const [id] = useState(() => uuidv4());
  return <Layer layer={new BaseLineLayer<D>({ ...props, id })} />;
};

export const BitmapLayer = (props: Omit<BitmapLayerProps, "id">) => {
  const [id] = useState(() => uuidv4());
  return <Layer layer={new BaseBitmapLayer({ ...props, id })} />;
};

export const PointCloudLayer = <D extends {}>(
  props: Omit<PointCloudLayerProps<D>, "id">,
) => {
  const [id] = useState(() => uuidv4());
  return <Layer layer={new BasePointCloudLayer({ ...props, id })} />;
};

export const ColumnLayer = <D extends {}>(
  props: Omit<ColumnLayerProps<D>, "id">,
) => {
  const [id] = useState(() => uuidv4());
  return <Layer layer={new BaseColumnLayer({ ...props, id })} />;
};

export const GridCellLayer = <D extends {}>(
  props: Omit<GridCellLayerProps<D>, "id">,
) => {
  const [id] = useState(() => uuidv4());
  return <Layer layer={new BaseGridCellLayer({ ...props, id })} />;
};

export const GeoJsonLayer = (props: Omit<GeoJsonLayerProps, "id">) => {
  const [id] = useState(() => uuidv4());
  return <Layer layer={new BaseGeoJsonLayer({ ...props, id })} />;
};

export const SolidPolygonLayer = <D extends {}>(
  props: Omit<SolidPolygonLayerProps<D>, "id">,
) => {
  const [id] = useState(() => uuidv4());
  return <Layer layer={new BaseSolidPolygonLayer({ ...props, id })} />;
};

export const QuadkeyLayer = <D extends {}>(
  props: Omit<QuadkeyLayerProps<D>, "id">,
) => {
  const [id] = useState(() => uuidv4());
  return <Layer layer={new BaseQuadkeyLayer({ ...props, id })} />;
};

export const HtmlLayer = ({
  coordinates,
  pointerEvents = "auto",
  children,
}: PropsWithChildren<{
  coordinates: [number, number];
  pointerEvents?: "auto" | "none";
}>) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [id] = useState(() => uuidv4());

  return (
    <>
      <div
        ref={divRef}
        style={{
          position: "absolute",
          top: "-10000px",
          left: "-10000px",
          pointerEvents,
        }}
      >
        {children}
      </div>
      <Layer layer={new HtmlLayerBase({ divRef, coordinates, id })} />
    </>
  );
};

export default class HtmlLayerBase extends BaseLayer<{
  divRef: RefObject<HTMLDivElement>;
  coordinates: [number, number];
}> {
  // @ts-ignore
  initializeState() {}

  // @ts-ignore
  draw() {
    const { viewport } = this.context;

    const position = viewport.project(this.props.coordinates);

    if (this.props.divRef.current?.style) {
      this.props.divRef.current.style.left = `${position[0]}px`;
      this.props.divRef.current.style.top = `${position[1]}px`;
    }
  }
}

HtmlLayerBase.layerName = "HtmlLayerBase";
