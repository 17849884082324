import { useCallback } from "react";

export const useOnShowErrors = <T extends {}>(
  setFormState: (generator: (state: T) => T) => void,
) => {
  const onShowErrors = useCallback(
    () =>
      setFormState((state) => {
        const keys = Object.keys(state) as (keyof T)[];
        return keys.reduce((acc, key) => {
          acc[key] = {
            ...state[key],
            errorVisible: true,
          };
          return acc;
        }, {} as T);
      }),
    [setFormState],
  );
  return onShowErrors;
};
