import { config as yandex } from "./yandex";
import { config as yango } from "./yango";

const getConfig = () => {
  if (/\.yandex\.(ru|com|md)$/.test(window.location.hostname)) {
    return yandex;
  }
  if (/\.yango\.com$/.test(window.location.hostname)) {
    return yango;
  }
  throw Error("no app config for current hostname");
};

export const config = getConfig();
