import { MessageCache, createMessages } from "@superweb/intl";

export const { Messages, Message, useMessage } = createMessages({
  cache: new MessageCache({
    key: (locale) => locale.toString(),
    load: (locale: string) =>
      import(`./translations/${locale}.json`)
        .then((module) => module.default)
        .catch(() => undefined),
  }),
  sourceLanguage: "en",
});
