import {
  createContext,
  useContext,
  useInsertionEffect,
  useState,
  type ReactNode,
} from "react";

const CssInstanceContext = createContext<
  | {
      dangerouslyAllowUnknownProperties: boolean;
      inject: ({ id, rules }: { id: string; rules: string[] }) => void;
    }
  | undefined
>(undefined);

export const useCssInstance = () => {
  const value = useContext(CssInstanceContext);

  if (value === undefined) {
    throw new Error("useCssInstance must be used within a CssInstance");
  }

  return value;
};

export const CssInstance = ({
  nonce,
  dangerouslyAllowUnknownProperties = true,
  children,
}: {
  /**
   * Nonce will be added to dynamically created `<style>` element
   * to allow stricter CSP.
   *
   * A nonce should be obtained dynamically, e.g. from a `<meta>` element.
   *
   * The `nonce` is applied during `style` element creation,
   * which only happens once. After that, updates to `nonce` option
   * are ignored.
   *
   * ```tsx
   * const nonce = document
   *   .querySelector("meta[property=csp-nonce]")
   *   .nonce
   *
   * <CssInstance nonce={nonce}>
   *   {children}
   * </CssInstance>
   * ```
   *
   * [MDN Reference](https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/nonce)
   * [Vite Docs](https://vitejs.dev/guide/features.html#nonce-random)
   */
  nonce?: string;

  /**
   * If enabled, all unknown or unsupported CSS properties will be injected.
   *
   * ⚠️ Caution!
   *
   * This library implements Atomic CSS approach.
   *
   * It has many benefits; but the limitation is that every individual
   * CSS property is injected as a separate atomic CSS rule
   * in a non-deterministic order.
   *
   * It's fine as long as CSS properties do not override each other.
   * But if you try to combine e.g. `margin` and `marginTop` the end result
   * will depend on rule order, which may change depending on how the user
   * navigates through the app.
   *
   * @defaultValue true
   */
  dangerouslyAllowUnknownProperties?: boolean;
  children: ReactNode;
}) => {
  const [instance] = useState(() => createInstance());

  useInsertionEffect(() => {
    instance.setup(nonce);
    return () => {
      instance.cleanup();
    };
  }, [instance, nonce]);

  return (
    <CssInstanceContext.Provider
      value={{
        dangerouslyAllowUnknownProperties,
        inject: instance.inject,
      }}
    >
      {children}
    </CssInstanceContext.Provider>
  );
};

const createInstance = () => {
  let installed = false;
  let element: HTMLStyleElement | null = null;
  const injectedIds = new Set<string>();
  const rulesToBeInjected = new Set<string>();

  return {
    setup: (nonce?: string) => {
      if (installed) {
        throw new Error("Style element already injected");
      }
      element = document.createElement("style");
      element.nonce = nonce;
      document.head.append(element);
      rulesToBeInjected.forEach((rule) => {
        element!.sheet?.insertRule(rule);
      });
      rulesToBeInjected.clear();

      installed = true;
    },
    cleanup: () => {
      rulesToBeInjected.clear();
      injectedIds.clear();

      element!.remove();
      element = null;

      installed = false;
    },
    inject: ({ id, rules }: { id: string; rules: string[] }) => {
      if (injectedIds.has(id)) {
        return;
      }

      for (const rule of rules) {
        if (installed) {
          element!.sheet?.insertRule(rule);
        } else {
          rulesToBeInjected.add(rule);
        }
      }
      injectedIds.add(id);
    },
  };
};
