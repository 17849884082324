import { useMemo } from "react";

import {
  useDebouncedState,
  type ComboBoxOption,
  type ComboBoxState,
} from "@superweb/ui";
import { useLocale } from "@superweb/intl";

import { useV1AddressSuggestPost } from "@fleet/api/fleet-forms";

import { FormSuggest } from "./form-suggest";

export const AddressSuggest = ({
  label,
  state,
  refId,
  phone,
  required,
  onChange,
  description,
  confirmationId,
  disabled,
}: {
  phone: string;
  refId: string;
  label: string;
  required?: boolean;
  description?: string;
  confirmationId: string;
  disabled?: boolean;
  state: ComboBoxState<ComboBoxOption>;
  onChange: (state: ComboBoxState<ComboBoxOption>) => void;
}) => {
  const locale = useLocale();
  const debouncedInputState = useDebouncedState(state, 300);

  const { data } = useV1AddressSuggestPost(
    {
      query: {
        ref_id: refId,
      },
      headers: {
        "content-type": "application/json",
        language: locale.toString(),
      },
      body: {
        query: {
          text: debouncedInputState.inputValue,
        },
        limit: 100,
        phone,
        confirmation_id: confirmationId,
      },
    },
    {
      enabled:
        Boolean(debouncedInputState.inputValue) &&
        Boolean(phone) &&
        Boolean(confirmationId) &&
        !disabled,
    },
  );

  const options = useMemo(() => {
    const addresses = data?.ok ? data.data.addresses : [];
    const addressMap = addresses.reduce<Record<string, ComboBoxOption>>(
      (acc, option) => {
        const { country, province, district, city, street, house } = option;
        const description = [country, province, district]
          .filter(Boolean)
          .join(", ");
        const label = [city, street, house].filter(Boolean).join(", ");
        const key = description + label;

        if (!acc[key] && label) {
          acc[key] = { key, label, description };
        }

        return acc;
      },
      {},
    );

    return Object.values(addressMap);
  }, [data]);

  return (
    <FormSuggest
      required={required}
      disabled={disabled || !confirmationId}
      data={options}
      enableFilterOptionsOnInputValue={false}
      label={label}
      description={description}
      state={state}
      onChange={(state) =>
        onChange({
          ...state,
          inputValue: !state.inputValue
            ? [state.value?.description, state.value?.label]
                .filter(Boolean)
                .join(" · ")
            : state.inputValue,
        })
      }
    />
  );
};
