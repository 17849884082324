/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { type ReactElement, useMemo } from "react";
import { createContext, type ReactNode, useContext } from "react";

import { useColorScheme } from "../color-scheme";
import { useTheme } from "../theme";

type Provider<T extends Record<string, unknown>> = (props: {
  value: Partial<T>;
  children: ReactNode;
}) => ReactElement;

type Hook<T extends Record<string, unknown>> = () => T;

export const createColorsContext = <T extends Record<string, unknown>>(
  defaultValues: Record<"light" | "dark", T>,
): [Colors: Provider<T>, useColors: Hook<T>] => {
  const context = createContext<Partial<T> | undefined>(undefined);

  const Colors: Provider<T> = ({ value, children }) => {
    const defaultValue = useColors();
    const contextValue = useContext(context);
    const prevValue = contextValue ?? defaultValue;
    const nextValue = useMemo(() => {
      const result = { ...prevValue };
      for (const key in result) {
        if (value[key as keyof T] !== undefined) {
          result[key as keyof T] = value[key as keyof T];
        }
      }
      return result;
    }, [value, prevValue]);
    return <context.Provider value={nextValue}>{children}</context.Provider>;
  };

  const useColors: Hook<T> = () => {
    const contextValue = useContext(context);
    const scheme = useColorScheme();
    const defaultValue = defaultValues[scheme];
    return (contextValue ?? defaultValue) as T;
  };

  return [Colors, useColors];
};

export const createThemeColorsContext = <T extends Record<string, unknown>>(
  type: "uiColors" | "brandColors" | "datavisColors" | "uiShadows",
): [Colors: Provider<T>, useColors: Hook<T>] => {
  const context = createContext<Partial<T> | undefined>(undefined);

  const Colors: Provider<T> = ({ value, children }) => {
    const defaultValue = useColors();
    const contextValue = useContext(context);
    const prevValue = contextValue ?? defaultValue;
    const nextValue = useMemo(() => {
      const result = { ...prevValue };
      for (const key in result) {
        if (value[key as keyof T] !== undefined) {
          result[key as keyof T] = value[key as keyof T];
        }
      }
      return result;
    }, [value, prevValue]);
    return <context.Provider value={nextValue}>{children}</context.Provider>;
  };

  const useColors: Hook<T> = () => {
    const contextValue = useContext(context);
    const scheme = useColorScheme();
    const theme = useTheme();
    const defaultValue = theme[scheme][type];
    return (contextValue ?? defaultValue) as T;
  };

  return [Colors, useColors];
};
