let signDisplayNegative: "negative" | "auto" | undefined;

export const normalizeSignDisplayValue = (
  signDisplay: Intl.NumberFormatOptionsSignDisplay,
): Intl.NumberFormatOptionsSignDisplay => {
  if (signDisplay === "negative") {
    if (signDisplayNegative === undefined) {
      try {
        new Intl.NumberFormat("en", { signDisplay: "negative" });
        signDisplayNegative = "negative";
      } catch {
        signDisplayNegative = "auto";
      }
    }

    return signDisplayNegative;
  }

  return signDisplay;
};
