import { useContext } from "react";

import { ParkAvatar, UserToolbarMenuButton } from "@fleet/ui";
import { useIsMobile, icons } from "@superweb/ui";
import { useLocale } from "@superweb/intl";

import { useMessage } from "#internal/intl";
import { useProfile } from "#internal/auth";
import { AppLocaleContext } from "#internal/app-locale";
import { logout } from "#internal/auth/passport";

import { availableLanguages } from "./language-selector";

export const ProfileButton = () => {
  const isMobile = useIsMobile();
  const message = useMessage();
  const { login, uid } = useProfile();
  const locale = useLocale();
  const { setAppLocale } = useContext(AppLocaleContext);

  return (
    <UserToolbarMenuButton
      icon={<ParkAvatar id={uid} name={login} size={isMobile ? "l" : "m"} />}
      label={!isMobile ? login : undefined}
      // TODO add logging
      onOpenChange={() => {}}
      items={[
        {
          type: "group",
          items: [
            {
              type: "submenu",
              icon: <icons.PlanetOutline />,
              label: message({
                id: "2d65e198-d22f-4ae7-927b-e5e1773b5add",
                context: "User menu - language button label",
                default: "Language",
              }),
              items: Object.entries(availableLanguages).map(([lang, label]) => {
                return {
                  label,
                  type: "checkbox",
                  selected: locale.toString() === lang,

                  onPress: () => {
                    // TODO add logging
                    setAppLocale(new Intl.Locale(lang));
                  },
                };
              }),
            },
            {
              type: "button",
              icon: <icons.Logout />,
              label: message({
                id: "305058c9-5d27-445d-9892-2adecdcaa1d2",
                context: "User menu - logout button label",
                default: "Log out",
              }),
              onPress: () => {
                // TODO add logging
                logout();
              },
            },
          ],
        },
      ]}
    />
  );
};
