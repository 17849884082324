/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { createCalendar, type DateValue } from "@internationalized/date";
import { useRef, type RefObject } from "react";
import { useDateField, type AriaDatePickerProps } from "react-aria";
import {
  useDateFieldState as useAriaDateFieldState,
  type DateFieldStateOptions,
} from "react-stately";

import { useLocale } from "@superweb/intl";

import { DateTimeInputSegment } from "./segment";

export const DateInput = ({
  ariaPickerProps,
  firstSegmentRef,
  onChange,
}: {
  ariaPickerProps: AriaDatePickerProps<DateValue>;
  firstSegmentRef?: RefObject<HTMLDivElement>;
  onChange: (hasSegmentsWithValue: boolean) => void;
}) => {
  const locale = useLocale();

  const ref = useRef<HTMLDivElement>(null);

  const ariaProps: DateFieldStateOptions = {
    ...ariaPickerProps,
    locale: locale.toString(),
    createCalendar,

    onKeyUp: (e) => {
      if (
        (e.key >= "0" && e.key <= "9") ||
        e.key === "Backspace" ||
        e.key === "Delete"
      ) {
        const hasSegmentsWithValue = ariaState.segments.some(
          (s) => s.type !== "literal" && !s.isPlaceholder,
        );
        onChange(hasSegmentsWithValue);
      }
    },
  };

  const ariaState = useAriaDateFieldState(ariaProps);

  const { fieldProps } = useDateField(ariaProps, ariaState, ref);

  return (
    <div {...fieldProps} ref={ref}>
      {ariaState.segments.map((segment, i) => (
        <DateTimeInputSegment
          key={i}
          segment={segment}
          segmentRef={i === 0 ? firstSegmentRef : undefined}
          state={ariaState}
        />
      ))}
    </div>
  );
};
