import { cssFns, type CssProps } from "@superweb/css";
import { useUiColors } from "../theme";

export const useCustomScrollbar = (): Omit<CssProps, "css"> => {
  const uiColors = useUiColors();

  return {
    __experimental_webkitScrollbarCss: {
      width: "16px",
      height: "16px",
      backgroundColor: "transparent",
    },
    __experimental_webkitScrollbarThumbCss: {
      ...cssFns.border({
        width: "5px",
        style: "solid",
        color: "transparent",
        radius: "8px",
      }),
      backgroundColor: "transparent",
      boxSizing: "border-box",
      boxShadow: `inset 0 0 0 4px ${uiColors.hover}`,
    },
    __experimental_webkitScrollbarThumbHoverCss: {
      boxShadow: `inset 0 0 0 4px ${uiColors.press}`,
    },
    __experimental_webkitScrollbarCornerCss: {
      backgroundPositionX: "0",
      backgroundPositionY: "0",
    },
  };
};
