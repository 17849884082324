import { useLocale } from "@superweb/intl";

import { useV1ParkInfoGet } from "@fleet/api/fleet-forms";

import { useOperationIdContext } from "../ui";

import { stringSearchParamParser } from "./parser/string";
import { useSearchParams } from "./search-params";

export const useBrand = () => {
  const locale = useLocale();

  const { sessionId } = useOperationIdContext();

  const [params] = useSearchParams({
    ref_id: stringSearchParamParser(),
    iframe: stringSearchParamParser(),
    skip_vehicle: stringSearchParamParser(),
    skip_license: stringSearchParamParser(),
    specification: stringSearchParamParser(),
  });

  const { data: parkInfo } = useV1ParkInfoGet(
    {
      headers: {
        language: locale.toString(),
        "x-session-id": sessionId,
      },
      query: { ref_id: params.ref_id },
    },
    {
      enabled: Boolean(params.ref_id),
      suspense: true,
    },
  );

  return parkInfo?.status !== 400 ? parkInfo?.data.brand : "";
};
