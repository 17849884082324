/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef, useState, type ReactNode } from "react";
import {
  mergeProps,
  useFocusRing,
  useHover,
  useId,
  useRadio,
  useRadioGroup,
} from "react-aria";
import type { RadioGroupProps, RadioGroupState } from "react-stately";
import { useRadioGroupState as useRadioGroupStateBase } from "react-stately";

import { useLocale } from "@superweb/intl";
import { cssFns } from "@superweb/css";
import {
  ExperimentalForm,
  ExperimentalFormGroup,
  ExperimentalFormRow,
  TextField,
  createComboBoxState,
  createRadioGroupState,
  createTextFieldState,
  icons,
  useDebouncedState,
  useTypo,
  useUiColors,
} from "@superweb/ui";
import { useFlag } from "@superweb/flags";

import { RowWithScroll } from "@fleet/ui";
import {
  useV1CarBrandsGet,
  useV1CarColorsGet,
  useV1CarModelsGet,
  useV1CarSubmitPost,
  useV1CarYearsGet,
} from "@fleet/api/fleet-forms";

import { Message, useMessage } from "#internal/intl";
import { useLogAction } from "#internal/analytics";
import {
  SubmitBottomSheet,
  FormSuggest,
  useOperationIdContext,
} from "#internal/ui";
import {
  useErrorMessages,
  useValidate,
  useOnChangePartial,
  useOnShowErrors,
} from "#internal/utils";

type GroupState = {
  value?: "car" | "bike" | "rickshaw";
  errorMessage?: string;
  errorVisible?: boolean;
};

type CarFormState = {
  number: string;
  brand: string;
  model: string;
  color_code: string;
  year: number;
};

const Group = ({
  options,
  state,
  onChange,
}: {
  options: { value: string; label: string }[];
  state: GroupState;
  onChange: (state: GroupState) => void;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  const groupProps: RadioGroupProps = {
    errorMessage: state.errorMessage,
    value: state.value ?? null,
    validationState:
      state.errorVisible && state.errorMessage ? "invalid" : "valid",
    orientation: "horizontal",
    onChange: (key) => {
      onChange({
        ...state,
        value: key as "car" | "bike" | "rickshaw",
        errorVisible: false,
        errorMessage: undefined,
      });
    },
  };

  const groupState = useRadioGroupStateBase(groupProps);

  const { radioGroupProps, errorMessageProps } = useRadioGroup(
    groupProps,
    groupState,
  );

  return (
    <div {...radioGroupProps}>
      <RowWithScroll disabledShadow>
        <div css={{ display: "flex", columnGap: "16px" }}>
          {options.map(({ value, label }) => (
            <GroupRadio
              key={value}
              value={value}
              label={label}
              state={groupState}
            />
          ))}
        </div>
      </RowWithScroll>
      {state.errorMessage && state.errorVisible && (
        <div
          css={{
            ...cssFns.padding("0", "16px"),
            paddingBlockStart: "4px",
            ...typo({
              level: "caption1",
              density: "tight",
              weight: "regular",
            }),
            color: uiColors.error,
          }}
          {...errorMessageProps}
        >
          {state.errorMessage}
        </div>
      )}
    </div>
  );
};

const GroupRadio = ({
  value,
  label,
  state,
}: {
  value: string;
  label: string;
  state: RadioGroupState;
}) => {
  const uiColors = useUiColors();
  const typo = useTypo();
  const { focusProps, isFocusVisible: isFocused } = useFocusRing();
  const { hoverProps, isHovered } = useHover({});

  const descriptionId = useId();

  const inputRef = useRef<HTMLInputElement>(null);
  const { inputProps, isSelected } = useRadio(
    { value, "aria-label": label, "aria-describedby": descriptionId },
    state,
    inputRef,
  );

  const radioIcons = () => {
    switch (value) {
      case "car":
        return <icons.Car />;
      case "bike":
        return <icons.MotorcycleProfile />;
      case "rickshaw":
        return <icons.TucktuckProfile />;
      default:
        return <div></div>;
    }
  };

  const backgroundColor =
    isSelected || isHovered ? uiColors.controlMinor : uiColors.background;

  return (
    <label
      {...hoverProps}
      css={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: `0 0 0 0 transparent, ${
          isFocused
            ? "inset 0 0 0 2px " + uiColors.focus
            : "0 0 0 0 transparent"
        }`,
        cursor: "pointer",
        width: "172px",
        height: "112px",
        backgroundColor,
        outlineStyle: "none",
        ...cssFns.border({
          style: "solid",
          radius: "16px",
          width: "2px",
          color: isSelected ? "transparent" : uiColors.controlMinor,
        }),
        boxSizing: "border-box",
        ...cssFns.padding("14px"),
        paddingBlockEnd: "18px",
        color: uiColors.text,
        ...typo({ level: "body2", weight: "medium", density: "tight" }),
      }}
    >
      {radioIcons()}
      <span id={descriptionId}>{label}</span>
      <input
        {...mergeProps(inputProps, focusProps)}
        css={{
          width: "100%",
          height: "100%",
          left: "0",
          top: "0",
          position: "absolute",
          opacity: "0",
          cursor: "pointer",
          ...cssFns.margin("0"),
        }}
        ref={inputRef}
      />
    </label>
  );
};

export const VehicleDetailsForm = ({
  refId,
  vehicleTypes,
  bottomSheetContent,
  onSubmit,
  onError,
  onSuccess,
}: {
  refId: string;
  vehicleTypes: { id: "car" | "bike" | "rickshaw"; name: string }[];
  bottomSheetContent?: ReactNode;
  onSubmit: () => void;
  onError: (code?: string) => void;
  onSuccess: () => void;
}) => {
  const flag = useFlag();
  const typo = useTypo();
  const message = useMessage();
  const uiColors = useUiColors();
  const locale = useLocale();
  const logActionSubmit = useLogAction("submit");
  const logActionInvalid = useLogAction("invalid");
  const errorMessages = useErrorMessages();
  const { validateTextField, validateComboboxField } = useValidate();

  const { submissionId } = useOperationIdContext();

  const enableVehicleTypesSelection = flag({
    id: "7fd41e03-8f0f-4153-b9ed-a0e613ed5500",
    description: "Enable vehicle types selection",
  });

  const [formState, setFormState] = useState(() => {
    return {
      number: createTextFieldState({
        value: "",
        errorMessage: errorMessages.requiredField,
      }),
      vehicle_type: createRadioGroupState<"car" | "bike" | "rickshaw">({
        value: !enableVehicleTypesSelection
          ? "car"
          : vehicleTypes.length === 1
            ? vehicleTypes[0]?.id
            : undefined,
        errorMessage:
          enableVehicleTypesSelection && vehicleTypes.length > 1
            ? errorMessages.requiredField
            : undefined,
      }),
      brand: createComboBoxState({
        errorMessage: errorMessages.requiredField,
      }),
      model: createComboBoxState({
        errorMessage: errorMessages.requiredField,
      }),
      color_code: createComboBoxState({
        errorMessage: errorMessages.requiredField,
      }),
      year: createComboBoxState({
        errorMessage: errorMessages.requiredField,
      }),
    };
  });

  const onChangePartial = useOnChangePartial(setFormState);
  const onShowErrors = useOnShowErrors(setFormState);

  const { mutate: submit, isLoading } = useV1CarSubmitPost({
    onSuccess(data) {
      if (data.ok) {
        onSuccess();
        onSubmit();
        logActionSubmit("submit_vehicle");
      } else {
        switch (data.error.code) {
          case "invalid_car_number": {
            logActionInvalid("invalid_car_number", { onlyYM: true });
            onChangePartial({
              number: {
                ...formState.number,
                errorMessage: errorMessages.invalidCarNumber,
                errorVisible: true,
              },
            });
            break;
          }
          default: {
            onError(data.error.code);
            break;
          }
        }
      }
    },
    onError: () => onError(),
  });

  const vehicleTypeOptions: {
    value: "car" | "bike" | "rickshaw";
    label: string;
  }[] = vehicleTypes.map(({ id, name }) => {
    return { value: id, label: name };
  });

  const debouncedVehicleBrandState = useDebouncedState(formState.brand, 300);

  const { data: brandData } = useV1CarBrandsGet(
    {
      query: {
        text_query: debouncedVehicleBrandState.inputValue,
        ref_id: refId,
        vehicle_type: formState.vehicle_type.value,
      },
      headers: { language: locale.toString() },
    },
    {
      enabled: !!formState.vehicle_type.value,
    },
  );

  const vehicleBrandsOptions = brandData?.ok
    ? brandData.data.brands.map((item) => ({ label: item, key: item }))
    : [];

  const debouncedVehicleModelInput = useDebouncedState(
    formState.model.inputValue,
    300,
  );

  const { data: modelData } = useV1CarModelsGet(
    {
      query: {
        brand: String(debouncedVehicleBrandState.value?.key),
        text_query: debouncedVehicleModelInput,
        ref_id: refId,
        vehicle_type: formState.vehicle_type.value,
      },
      headers: { language: locale.toString() },
    },
    {
      enabled:
        !!debouncedVehicleBrandState.value && !!formState.vehicle_type.value,
    },
  );

  const vehicleModelsOptions = modelData?.ok
    ? modelData.data.models.map((item) => ({ label: item, key: item }))
    : [];

  const { data: colorData } = useV1CarColorsGet({
    headers: { language: locale.toString() },
  });

  const vehicleColorOptions = colorData?.ok
    ? colorData.data.colors.map(({ name, hex_code }) => ({
        label: name,
        key: hex_code,
      }))
    : [];

  const { data: yearData } = useV1CarYearsGet({
    headers: { language: locale.toString() },
  });

  const vehicleYearsOptions = yearData?.ok
    ? yearData.data.years.map((item) => ({
        label: String(item),
        key: String(item),
      }))
    : [];

  return (
    <ExperimentalForm
      main={
        <>
          <div
            css={{
              ...typo({
                level: "body2",
                density: "tight",
                weight: "regular",
              }),
              color: uiColors.textMinor,
              marginBlockEnd: "4px",
            }}
          >
            <Message
              id="695eee7f-9c7a-4aca-a06e-b840b761f639"
              context="Yango forms. Vehicle details form. Step description"
              default="You're registered! Add details to start earning right now"
            />
          </div>
          <TextField
            required
            label={message({
              id: "41652579-a5a6-45f4-a3ce-2e9ca03078a4",
              context: "Yango forms. Vehicle number field",
              default: "License plate number",
            })}
            state={formState.number}
            onChange={(state) => {
              const errorMessage = validateTextField(state.value);
              onChangePartial({
                number: {
                  value: state.value.toUpperCase(),
                  errorMessage,
                  errorVisible: !!errorMessage,
                },
              });
            }}
          />
          {vehicleTypeOptions.length > 1 && enableVehicleTypesSelection && (
            <Group
              state={formState.vehicle_type}
              onChange={(state) => {
                onChangePartial({
                  vehicle_type: state,
                  brand: {
                    value: undefined,
                    inputValue: "",
                    errorMessage: errorMessages.requiredField,
                  },
                  model: {
                    value: undefined,
                    inputValue: "",
                    errorMessage: errorMessages.requiredField,
                  },
                });
              }}
              options={vehicleTypeOptions}
            />
          )}
          {formState.vehicle_type.value && (
            <ExperimentalFormGroup columnsCount={1}>
              <FormSuggest
                required
                data={vehicleBrandsOptions}
                label={message({
                  id: "0e684bb9-b11f-498a-8f0c-a73a6da9523d",
                  context: "Yango forms. Vehicle brand field",
                  default: "Make",
                })}
                state={formState.brand}
                onChange={(state) => {
                  const errorMessage = validateComboboxField(state);
                  onChangePartial({
                    brand: {
                      ...state,
                      errorMessage,
                    },
                    model: {
                      value:
                        formState.vehicle_type.value === "car"
                          ? undefined
                          : vehicleModelsOptions[0],
                      inputValue: "",
                      errorMessage:
                        formState.vehicle_type.value === "car"
                          ? errorMessages.requiredField
                          : undefined,
                    },
                  });
                }}
              />
              {formState.vehicle_type.value === "car" && (
                <FormSuggest
                  required
                  data={vehicleModelsOptions}
                  label={message({
                    id: "0e684bb9-b11f-498a-8f0c-a73a6da9522d",
                    context: "Yango forms. Vehicle model field",
                    default: "Model",
                  })}
                  state={formState.model}
                  onChange={(state) => {
                    const errorMessage = validateComboboxField(state);
                    onChangePartial({
                      model: {
                        ...state,
                        errorMessage,
                        errorVisible: Boolean(errorMessage),
                      },
                    });
                  }}
                  disabled={!formState.brand.value}
                />
              )}
              <ExperimentalFormRow columnsCount={2}>
                <FormSuggest
                  required
                  data={vehicleColorOptions}
                  label={message({
                    id: "25636b9c-84b9-44de-b602-5cb0272fef39",
                    context: "Yango forms. Vehicle color field",
                    default: "Color",
                  })}
                  state={formState.color_code}
                  onChange={(state) => {
                    const errorMessage = validateComboboxField(state);
                    onChangePartial({
                      color_code: {
                        ...state,
                        errorMessage,
                        errorVisible: Boolean(errorMessage),
                      },
                    });
                  }}
                />
                <FormSuggest
                  required
                  data={vehicleYearsOptions}
                  label={message({
                    id: "1194351e-cc7a-4143-9004-2d300c506a72",
                    context: "Yango forms. Vehicle year field",
                    default: "Year",
                  })}
                  state={formState.year}
                  onChange={(state) => {
                    const errorMessage = validateComboboxField(state);
                    onChangePartial({
                      year: {
                        ...state,
                        errorMessage,
                        errorVisible: Boolean(errorMessage),
                      },
                    });
                  }}
                />
              </ExperimentalFormRow>
            </ExperimentalFormGroup>
          )}
          <SubmitBottomSheet
            isLoading={isLoading}
            text={message({
              id: "ca0dfe03-7215-463e-8f07-d1db5c04a303",
              context: "Yango forms. Add info button text",
              default: "Add details",
            })}
          >
            {bottomSheetContent}
          </SubmitBottomSheet>
        </>
      }
      onSubmit={() => {
        onShowErrors();
        const hasErrors = Object.keys(formState).some(
          (field) =>
            formState[field as keyof CarFormState].errorMessage !== undefined,
        );

        if (hasErrors) {
          if (
            formState.number.errorMessage === errorMessages.invalidCarNumber
          ) {
            logActionInvalid("invalid_car_number", { onlyYM: true });
          }

          return;
        }

        submit({
          headers: {
            "x-submission-id": submissionId,
            language: locale.toString(),
            "content-type": "application/json",
          },
          body: {
            ref_id: refId,
            number: formState.number.value,
            brand: String(formState.brand.value?.key),
            model: String(formState.model.value?.key),
            color_code: String(formState.color_code.value?.key),
            year: Number(formState.year.value?.key),
          },
        });
      }}
    />
  );
};
