/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  useEffect,
  useRef,
  useState,
  type ReactNode,
  type RefObject,
} from "react";
import {
  useListBox,
  useOption,
  useFocusRing,
  useKeyboard,
  ListKeyboardDelegate,
  useFocusManager,
  type AriaListBoxOptions,
  type Key,
} from "react-aria";

import { type ListState, useListState, Item } from "react-stately";
import { mergeProps } from "@react-aria/utils";

import { cssFns } from "@superweb/css";
import { useUiColors } from "../theme";
import { useTypo } from "../typo";

const SIZES = {
  s: { height: "32px" },
  m: { height: "56px" },
};

export type CalendarGridItem = {
  key: string;
  label: string;
  ariaLabel?: string;
  selected?: boolean;
  disabled?: boolean;
  highlighted?: boolean;
  disabledDescription?: string;
  description?: string;
  render?: () => ReactNode;
};

/**
 * Component for display a calendar months or years in popover.
 * @internal
 * Used listbox with layout grid options
 */
const ListBox = <T extends {}>({
  ariaListBoxProps,
  listBoxRef,
  state,
  options,
  onItemFocus,
  onArrowNavigation,
  direction,
  columns,
  size,
}: {
  columns: number;
  ariaListBoxProps: AriaListBoxOptions<T>;
  listBoxRef: RefObject<HTMLDivElement>;
  state: ListState<T>;
  options: CalendarGridItem[];
  onItemFocus: (key: Key) => void;
  onArrowNavigation: (
    keyCode: "ArrowUp" | "ArrowDown" | "ArrowLeft" | "ArrowRight",
  ) => void;
  direction: "ltr" | "rtl";
  size: "s" | "m";
}) => {
  const optionsMap: Record<string, CalendarGridItem> = {};
  options.forEach((option) => {
    optionsMap[option.key] = option;
  });

  const [focusedKey, setFocusedKey] = useState<null | string | number>(
    state.selectionManager.focusedKey,
  );

  const keyboardDelegate = new ListKeyboardDelegate({
    collection: state.collection,
    ref: listBoxRef,
    disabledKeys: state.disabledKeys,
    layout: "grid",
    orientation: "vertical",
    direction,
  });

  const focusManager = useFocusManager();
  const { focusProps, isFocused } = useFocusRing({
    within: true,
  });

  const { listBoxProps } = useListBox(
    {
      ...ariaListBoxProps,
      keyboardDelegate,
      disallowEmptySelection: true,
    },
    state,
    listBoxRef,
  );

  const { keyboardProps } = useKeyboard({
    onKeyDown: (e) => {
      if (!state.selectionManager.focusedKey) {
        return;
      }
      if (state.selectionManager.focusedKey !== focusedKey) {
        return;
      }
      switch (e.key) {
        case "ArrowUp":
        case "ArrowDown":
        case "ArrowLeft":
        case "ArrowRight":
          e.preventDefault();
          onArrowNavigation(e.key);
          break;
        default:
          // workaround change focus with save prew focus element
          e.continuePropagation();
          focusManager?.focusPrevious({ wrap: true });
          focusManager?.focusNext({ wrap: true });
          break;
      }
    },
  });

  useEffect(() => {
    if (!state.selectionManager.focusedKey) {
      return;
    }

    if (state.selectionManager.focusedKey !== focusedKey) {
      onItemFocus(state.selectionManager.focusedKey);
      setFocusedKey(state.selectionManager.focusedKey);
    }
  }, [focusedKey, onItemFocus, state.selectionManager.focusedKey]);

  return (
    <div {...mergeProps(listBoxProps, keyboardProps, focusProps)}>
      <div
        css={{
          ...cssFns.padding("0"),
          ...cssFns.border({ width: "0" }),
          ...cssFns.margin("0"),
          ...cssFns.gap("2px"),
          position: "relative",
          display: "grid",
          gridTemplateColumns: `repeat(${columns}, 1fr)`,
          gridAutoFlow: "row",
        }}
        ref={listBoxRef}
      >
        {[...state.collection].map(({ key, rendered }) => {
          return (
            <Option
              key={key}
              item={{
                ariaLabel: optionsMap[key.toString()]?.ariaLabel,
                key: String(key),
                rendered,
                selected: optionsMap[key.toString()]?.selected,
                disabled: optionsMap[key.toString()]?.disabled,
                highlighted: optionsMap[key.toString()]?.highlighted,
              }}
              listboxFocus={isFocused}
              state={state}
              size={size}
            />
          );
        })}
      </div>
    </div>
  );
};

const Option = <T extends {}>({
  item,
  state,
  listboxFocus,
  size,
}: {
  item: {
    key: string;
    rendered: ReactNode;
    ariaLabel?: string;
    selected?: boolean;
    disabled?: boolean;
    highlighted?: boolean;
  };
  state: ListState<T>;
  listboxFocus: boolean;
  size: "s" | "m";
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const uiColors = useUiColors();
  const typo = useTypo();

  const isFocused =
    listboxFocus && state.selectionManager.focusedKey === item.key;
  const { optionProps } = useOption({ key: item.key }, state, ref);

  return (
    <button
      ref={ref}
      css={{
        ...SIZES[size],
        cursor: item.disabled ? "default" : "pointer",
        boxSizing: "border-box",
        position: "relative",
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        outlineStyle: "none",
        backgroundColor: item.selected
          ? uiColors.controlMain
          : item.highlighted
            ? uiColors.backgroundMinor
            : "transparent",
        color: item.selected ? uiColors.textOnControlMain : uiColors.text,
        ...cssFns.border({ style: "none", radius: "8px" }),
        ...cssFns.padding("0", "8px"),
        ...cssFns.boxShadow(
          isFocused && {
            inset: true,
            spreadRadius: "2px",
            color: uiColors.focus,
          },
        ),
        ...(size === "s"
          ? {
              ...cssFns.border({ radius: "8px" }),
              ...typo({
                level: "caption1",
                density: "tight",
                weight: "regular",
              }),
            }
          : {
              ...cssFns.border({ radius: "14px" }),
              ...typo({
                level: "body2",
                density: "normal",
                weight: "regular",
              }),
            }),
      }}
      {...mergeProps(optionProps)}
      aria-label={item.ariaLabel}
      aria-selected={item.selected ?? false}
    >
      {item.rendered}
    </button>
  );
};

export const OptionContent = ({
  label,
  disabled = false,
  selected,
}: {
  label: string;
  disabled?: boolean;
  selected?: boolean;
  size?: "s" | "m";
}) => {
  const uiColors = useUiColors();

  return (
    <div
      css={{
        display: "grid",
        alignItems: "center",
        color: selected
          ? uiColors.textOnControlMain
          : disabled
            ? uiColors.textMinor
            : uiColors.text,
      }}
    >
      <div
        css={{
          display: "grid",
        }}
      >
        <span
          css={{
            ...cssFns.overflow("hidden"),
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {label}
        </span>
      </div>
    </div>
  );
};

export const CalendarGrid = ({
  selectionMode = "single",
  options,
  columns,
  size = "m",
  initialFocusedKey,
  shouldFocusOnHover = false,
  onChange = () => {},
  onArrowNavigation = () => {},
  onItemFocus = () => {},
  label,
  ariaLabel,
}: {
  selectionMode?: "single" | "multiple";
  options: CalendarGridItem[];
  columns: number;
  label: string;
  ariaLabel?: string;
  size?: "s" | "m";
  initialFocusedKey?: string;
  shouldFocusOnHover?: boolean;
  onChange?: (keys: string[]) => void;
  onArrowNavigation?: (
    keyСode: "ArrowUp" | "ArrowDown" | "ArrowLeft" | "ArrowRight",
    state: ListState<CalendarGridItem>,
  ) => void;
  onItemFocus?: (key: Key, state: ListState<CalendarGridItem>) => void;
}) => {
  const listBoxRef = useRef<HTMLDivElement>(null);

  const handleChange = (keys: string[]) => {
    onChange(keys);
  };

  const handleArrowNavigation = (
    keyСode: "ArrowUp" | "ArrowDown" | "ArrowLeft" | "ArrowRight",
    state: ListState<CalendarGridItem>,
  ) => {
    onArrowNavigation(keyСode, state);
  };

  const handleItemFocus = (key: Key, state: ListState<CalendarGridItem>) => {
    onItemFocus(key, state);
  };

  const disabledKeys: string[] = [];
  options.forEach((option) => {
    if (option.disabled) {
      disabledKeys.push(option.key);
    }
  });

  const listState = useListState<CalendarGridItem>({
    selectionMode,
    disabledKeys,
    disallowEmptySelection: true,
    items: options.map((option) => ({ ...option, key: option.key })),
    children: (option: CalendarGridItem) => (
      <Item key={option.key} textValue={option.label}>
        <OptionContent
          label={option.label}
          disabled={option.disabled}
          selected={option.selected}
        />
      </Item>
    ),
    onSelectionChange: (options) => {
      const keys = [...options];
      handleChange(keys.map((key) => String(key)));
    },
  });

  const [isInitialMount, setInitialMount] = useState(true);

  useEffect(() => {
    if (initialFocusedKey && isInitialMount) {
      listState.selectionManager.setFocusedKey(initialFocusedKey);
      setInitialMount(false);
    }
  }, [initialFocusedKey, listState.selectionManager, isInitialMount]);

  return (
    <ListBox
      ariaListBoxProps={{
        label: ariaLabel || label,
        shouldFocusOnHover: shouldFocusOnHover,
        shouldFocusWrap: false,
        disallowEmptySelection: false,
        shouldSelectOnPressUp: true,
      }}
      size={size}
      columns={columns}
      direction="ltr"
      listBoxRef={listBoxRef}
      state={listState}
      options={options}
      onArrowNavigation={(key) => {
        handleArrowNavigation(key, listState);
      }}
      onItemFocus={(key) => handleItemFocus(key, listState)}
    />
  );
};
