const w = window as {
  ttq?: {
    load: (...args: unknown[]) => void;
    page: () => void;
    track: (...args: unknown[]) => void;
  };
};

export const initTTQ = (pixel_id?: string) => {
  if (w.ttq !== undefined) {
    w.ttq.load(pixel_id);
    w.ttq.page();
  }
};

export const sendTTQEvent = (eventName: string, eventCategory: string) => {
  w.ttq?.track(eventName, { eventCategory });
};
