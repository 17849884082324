/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  forwardRef,
  useEffect,
  useRef,
  useState,
  type ForwardedRef,
  type PointerEvent,
  type ReactNode,
  type RefObject,
  useCallback,
} from "react";
import { mergeProps, useFocusRing, usePress, useCheckbox } from "react-aria";
import { useToggleState } from "react-stately";
import { Tooltip, useTooltip } from "../tooltip";

import { cssFns, useCss, type Style } from "@superweb/css";
import { useLocale } from "@superweb/intl";

import { useUiColors, useUiShadows } from "../theme";
import { icons } from "../icons";
import { useTypo } from "../typo";
import { CheckboxInternal } from "../checkbox-internal";
import { useUiOptions } from "../ui-options-context";

import type { Column, LinkProps, SortingDirection } from "./types";
import type { SelectionState } from "./table";

const zIndexMap = {
  headerFirstColumn: "6",
  headerColumn: "5",
  stickyFirstColumn: "4",
  stickyColumn: "3",
  stickyRow: "3",
};

const getSortIcon = (direction?: SortingDirection) => {
  if (direction === "asc") return icons.ArrowUp;
  if (direction === "desc") return icons.ArrowDown;
  return icons.ArrowSorting;
};

const useBaseCellStyle = ({
  isFocusVisible,
  isHeaderCell,
  position,
  variant = "fill",
  isPinned = false,
}: {
  isFocusVisible: boolean;
  isHeaderCell?: boolean;
  isPinned?: boolean;
  position?: {
    firstLeft?: boolean;
    firstRight?: boolean;
    lastLeft?: boolean;
    lastRight?: boolean;
  };
  variant?: "ghost" | "fill";
}): Style => {
  const uiColors = useUiColors();

  const disableBorderRadius = isPinned;

  const bottomBorder =
    variant === "fill"
      ? isHeaderCell
        ? {
            borderBlockEndColor: cssFns.setOpacity(uiColors.text, 0.5),
            borderBlockEndStyle: "solid",
            borderBlockEndWidth: "1px",
          }
        : {
            borderBlockEndColor: uiColors.controlMinor,
            borderBlockEndStyle: "solid",
            borderBlockEndWidth: "1px",
          }
      : {};

  return {
    ...cssFns.padding("16px"),
    ...cssFns.overflow("hidden"),
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    minWidth: "32px",
    maxWidth: "320px",
    boxSizing: "border-box",
    outlineStyle: "none",
    ...(isFocusVisible &&
      cssFns.boxShadow({
        inset: true,
        spreadRadius: "2px",
        color: uiColors.focus,
      })),
    ...(position?.firstLeft &&
      !disableBorderRadius && {
        borderStartStartRadius: "26px",
      }),
    ...(position?.firstRight &&
      !disableBorderRadius && {
        borderStartEndRadius: "26px",
      }),
    ...(position?.lastLeft &&
      !disableBorderRadius && {
        borderEndStartRadius: "26px",
      }),
    ...(position?.lastRight &&
      !disableBorderRadius && {
        borderEndEndRadius: "26px",
      }),
    ...bottomBorder,
  };
};

type BaseProps = {
  children: ReactNode;
  align: Column<unknown>["align"];
  index: number;
  tabIndex?: number;
  isHovered?: boolean;
  isFooter?: boolean;
  isTopPinned?: boolean;
  isBottomPinned?: boolean;
  isStickyFirstColumn?: boolean;
  stickyFirstColumnShift?: number;
  updateFocusPointer: () => void;
};

const stickyColumnBoxShadowMap = {
  ltr: {
    clipPath: "inset(0px -28px -10px 0px)",
    boxShadow: "6px 0px 6px 0px rgba(0, 0, 0, 0.12)",
  },
  rtl: {
    clipPath: "inset(0px 0px -10px -28px)",
    boxShadow: "-6px 0px 6px 0px rgba(0, 0, 0, 0.12)",
  },
};

export const HeaderCell = forwardRef(
  (
    {
      children,
      align = "start",
      sortable = false,
      sort,
      onSortChange,
      id,
      index,
      tabIndex,
      updateFocusPointer,
      stickyShift,
      variant = "fill",
      stickyFirstColumnShift = 0,
      hasSelection,
      selectionOffset,
      isStickyFirstColumn = false,
    }: BaseProps & {
      sortable?: boolean;
      sort?: [string, SortingDirection];
      onSortChange?: (sort?: [string, SortingDirection]) => void;
      id: Column<unknown>["id"];
      stickyShift?: string;
      variant?: "fill" | "ghost";
      hasSelection: boolean;
      selectionOffset: number;
    },
    ref: ForwardedRef<HTMLTableCellElement>,
  ) => {
    const css = useCss();
    const typo = useTypo();
    const { focusProps, isFocusVisible } = useFocusRing({ within: true });
    const uiColors = useUiColors();

    let zIndex =
      index === 1 ? zIndexMap.headerFirstColumn : zIndexMap.headerColumn;
    let insetInlineStart = `${stickyFirstColumnShift}px`;

    if (hasSelection && index === 2) {
      zIndex = zIndexMap.headerFirstColumn;
      insetInlineStart = `${stickyFirstColumnShift + selectionOffset}px`;
    }

    const style: Style = {
      ...useBaseCellStyle({ isFocusVisible, isHeaderCell: true, variant }),
      ...typo({
        level: "caption1",
        weight: "medium",
        density: "tight",
      }),
      zIndex,
      position: "sticky",
      ...(isStickyFirstColumn && { insetInlineStart }),
      top: stickyShift || "0",
      textAlign: align,

      ...(variant === "ghost"
        ? {
            backgroundColor: uiColors.backgroundMinor,
            color: uiColors.textMinor,
            height: "56px",
          }
        : {
            backgroundColor: uiColors.background,
            color: uiColors.text,
          }),
    };

    const currentDirection = sort?.[0] === id ? sort[1] : undefined;
    const [ariaSort, setAriaSort] = useState<
      "ascending" | "descending" | "none"
    >();

    useEffect(() => {
      if (!currentDirection) setAriaSort("none");
      if (currentDirection === "asc") setAriaSort("ascending");
      if (currentDirection === "desc") setAriaSort("descending");
    }, [currentDirection]);

    const { pressProps } = usePress({
      onPress: () => {
        if (!sortable) return;
        switch (currentDirection) {
          case undefined: {
            onSortChange?.([id, "desc"]);
            break;
          }
          case "desc": {
            onSortChange?.([id, "asc"]);
            break;
          }
          case "asc": {
            onSortChange?.(undefined);
            break;
          }
        }
      },
    });

    const Icon = getSortIcon(currentDirection);

    if (sortable) {
      return (
        <th
          ref={ref}
          aria-colindex={index}
          aria-sort={ariaSort}
          css={style}
          {...focusProps}
          onClick={(e) => {
            updateFocusPointer();
            (e.currentTarget.firstElementChild as HTMLElement).focus();
          }}
        >
          <span
            css={{
              display: "inline-flex",
              alignItems: "center",
              flexDirection: align === "start" ? "row" : "row-reverse",
              outlineStyle: "none",
              cursor: "pointer",
            }}
            tabIndex={tabIndex}
            {...pressProps}
          >
            {children}
            <Icon
              className={css({
                ...cssFns.margin("0", "8px"),
                flexShrink: "0",
                width: "16px",
                height: "16px",
              })}
            />
          </span>
        </th>
      );
    }

    return (
      <th
        css={style}
        aria-colindex={index}
        ref={ref}
        tabIndex={tabIndex}
        {...focusProps}
      >
        {children}
      </th>
    );
  },
);

const useCellStyle = ({
  index,
  variant,
  align = "start",
  isHovered = false,
  isFocusVisible = false,
  isFooter = false,
  isStickyFirstColumn = false,
  stickyFirstColumnShift = 0,
  isTopPinned = false,
  isBottomPinned = false,
  isLastRow = false,
  clickable = false,
  hasSelection = false,
  selectionOffset,
  position,
}: {
  index: number;
  variant: "fill" | "ghost";
  align?: "start" | "end";
  isHovered: boolean;
  isFocusVisible: boolean;
  isFooter?: boolean;
  isStickyFirstColumn?: boolean;
  stickyFirstColumnShift?: number;
  isTopPinned?: boolean;
  isBottomPinned?: boolean;
  isLastRow?: boolean;
  clickable?: boolean;
  hasSelection?: boolean;
  selectionOffset: number;
  position?: {
    firstLeft?: boolean;
    firstRight?: boolean;
    lastLeft?: boolean;
    lastRight?: boolean;
  };
}): Style => {
  const typo = useTypo();
  const uiColors = useUiColors();
  const uiShadows = useUiShadows();

  const isPinned = isTopPinned || isBottomPinned;

  const locale = useLocale();

  const stickyColumnBoxShadow =
    stickyColumnBoxShadowMap[locale.textInfo.direction];

  let zIndex = "1";
  if (isPinned && !isStickyFirstColumn) {
    zIndex = zIndexMap.stickyRow;
  }

  if ((isStickyFirstColumn && index === 1) || (hasSelection && index === 2)) {
    zIndex = zIndexMap.stickyColumn;
  }
  let insetInlineStart = `${stickyFirstColumnShift + 24}px`;
  if (!hasSelection && index === 1) {
    insetInlineStart = `${stickyFirstColumnShift}px`;
  } else if (hasSelection && index === 1) {
    insetInlineStart = `${stickyFirstColumnShift}px`;
  } else if (hasSelection && index === 2) {
    insetInlineStart = `${stickyFirstColumnShift + selectionOffset}px`;
  }

  const showStickyBoxShadow =
    (isStickyFirstColumn && index === 1 && !isFocusVisible) ||
    (isStickyFirstColumn && hasSelection && index === 2 && !isFocusVisible);

  return {
    ...useBaseCellStyle({
      isFocusVisible,
      isHeaderCell: false,
      ...(variant === "ghost" && { position }),
      variant,
      isPinned,
    }),

    ...(variant === "ghost" &&
      !isTopPinned &&
      !isBottomPinned &&
      !isLastRow && {
        borderBottomStyle: "solid",
        borderBottomColor: cssFns.setOpacity(uiColors.line, 0.1),
        borderBottomWidth: "2px",
      }),

    ...typo({
      level: "body2",
      weight: "regular",
      density: "tight",
    }),
    ...cssFns.padding("0"),
    cursor: clickable ? "pointer" : "default",
    textAlign: align,
    backgroundColor: isPinned
      ? uiColors.background
      : isHovered || isFooter
        ? cssFns.blend(uiColors.background, uiColors.hover)
        : uiColors.background,
    color: uiColors.text,
    zIndex,
    ...(isStickyFirstColumn && {
      position: "sticky",
      insetInlineStart,
    }),
    ...(showStickyBoxShadow && stickyColumnBoxShadow),
    // https://github.com/mdn/content/issues/29839
    // workaround for safari box-shadow <tr>
    ...(isTopPinned &&
      !isStickyFirstColumn && {
        boxShadow: uiShadows.bottomNormal,
        clipPath: `inset(0 0 -40px 0);`,
      }),
    ...(isBottomPinned &&
      !isStickyFirstColumn && {
        boxShadow: uiShadows.topNormal,
        clipPath: `inset(-40px 0 0 0);`,
      }),
  };
};

export const Cell = forwardRef(
  (
    {
      children,
      align = "start",
      isHovered = false,
      isFooter = false,
      isStickyFirstColumn = false,
      stickyFirstColumnShift = 0,
      index,
      tabIndex,
      onPress,
      updateFocusPointer,
      tooltip,
      linkProps,
      variant = "fill",
      position,
      isTopPinned = false,
      isBottomPinned = false,
      isLastRow = false,
      hasSelection,
      selectionOffset,
    }: BaseProps & {
      linkProps?: LinkProps;
      onPress?: () => void;
      tooltip?: ReactNode;
      variant?: "fill" | "ghost";
      isLastRow?: boolean;
      hasSelection: boolean;
      selectionOffset: number;
      position: {
        firstLeft?: boolean;
        firstRight?: boolean;
        lastLeft?: boolean;
        lastRight?: boolean;
      };
    },
    ref: ForwardedRef<HTMLTableCellElement>,
  ) => {
    const uiOptions = useUiOptions();
    const newTableTooltipRef = uiOptions.experimental?.newTableTooltipRef;

    const uiColors = useUiColors();

    const { focusProps, isFocusVisible } = useFocusRing();
    const { pressProps } = usePress({
      onPress: () => {
        updateFocusPointer();
        onPress?.();
      },
    });

    const targetRef = useRef(null);
    const {
      triggerProps,
      tooltipProps,
      state: tooltipState,
    } = useTooltip(targetRef, {
      delay: 1200,
      shouldCloseOnScroll: true,
    });

    let cellProps = onPress ? mergeProps(focusProps, pressProps) : focusProps;
    cellProps = tooltip ? mergeProps(cellProps, triggerProps) : cellProps;

    const cellStyle = useCellStyle({
      index,
      variant,
      align,
      isHovered,
      isFocusVisible,
      isFooter,
      isStickyFirstColumn,
      stickyFirstColumnShift,
      isTopPinned,
      isBottomPinned,
      isLastRow,
      clickable: Boolean(linkProps || onPress),
      hasSelection,
      selectionOffset,
      position,
    });

    const style: Style = {
      ...cssFns.overflow("hidden"),
      textOverflow: "ellipsis",
      display: "block",
      ...(variant === "ghost" && { lineHeight: "24px" }),
    };

    return (
      <td css={cellStyle} aria-colindex={index} ref={ref}>
        {linkProps ? (
          <a
            {...mergeProps(focusProps, pressProps, triggerProps)}
            href={linkProps.href}
            onClick={(e: PointerEvent<HTMLAnchorElement>) => {
              updateFocusPointer();
              onPress?.();
              linkProps.onClick?.(e);
            }}
            tabIndex={tabIndex}
            css={{
              color: uiColors.text,
              textDecorationLine: "none",
              outlineStyle: "none",
              ...cssFns.padding("16px"),
              ...style,
            }}
            ref={newTableTooltipRef ? undefined : targetRef}
          >
            {newTableTooltipRef ? (
              <span ref={targetRef}>{children}</span>
            ) : (
              children
            )}
          </a>
        ) : (
          <div
            {...cellProps}
            tabIndex={tabIndex}
            css={{
              outlineStyle: "none",
              ...cssFns.padding("16px"),
              ...style,
            }}
            ref={newTableTooltipRef ? undefined : targetRef}
          >
            {newTableTooltipRef ? (
              <span ref={targetRef}>{children}</span>
            ) : (
              children
            )}
          </div>
        )}

        {tooltip && tooltipState.isOpen ? (
          <Tooltip
            state={tooltipState}
            tooltipProps={tooltipProps}
            targetRef={targetRef}
            variant="info"
          >
            {tooltip}
          </Tooltip>
        ) : undefined}
      </td>
    );
  },
);

export const CheckboxHeaderCell = forwardRef(
  (
    {
      index,
      stickyShift,
      tabIndex,
      selection,
      onChange,
      inputRef,
      variant = "fill",
      isStickyFirstColumn,
      stickyFirstColumnShift,
    }: {
      index: number;
      stickyShift?: string;
      tabIndex?: number;
      selection: SelectionState;
      onChange: (state: SelectionState) => void;
      inputRef: RefObject<HTMLInputElement>;
      variant?: "fill" | "ghost";
      isStickyFirstColumn?: boolean;
      stickyFirstColumnShift?: number;
    },
    ref: ForwardedRef<HTMLTableCellElement>,
  ) => {
    const uiColors = useUiColors();

    const style: Style = {
      ...useBaseCellStyle({
        isFocusVisible: false,
        isHeaderCell: true,
        variant,
      }),
      zIndex:
        index === 1 ? zIndexMap.headerFirstColumn : zIndexMap.headerColumn,
      position: "sticky",
      top: stickyShift || "0",
      ...(isStickyFirstColumn && {
        insetInlineStart: `${stickyFirstColumnShift}px`,
      }),

      ...(variant === "ghost"
        ? {
            backgroundColor: uiColors.backgroundMinor,
            minHeight: "56px",
          }
        : {
            backgroundColor: uiColors.background,
          }),
    };

    const onHeaderCheckboxValueChange = useCallback(
      (isSelected: boolean) => {
        onChange(
          isSelected
            ? {
                all: isSelected,
                exclude: new Set<string>(),
              }
            : {
                all: isSelected,
                include: new Set<string>(),
              },
        );
      },
      [onChange],
    );

    const toggleHeaderCheckboxState = useToggleState({
      isSelected: selection.all,
      onChange: onHeaderCheckboxValueChange,
    });

    const indeterminate = selection.all && selection.exclude.size !== 0;

    const { inputProps } = useCheckbox(
      {
        isSelected: selection.all,
        onChange: onHeaderCheckboxValueChange,
        "aria-label": "Select all",
        isIndeterminate: indeterminate,
      },
      toggleHeaderCheckboxState,
      inputRef,
    );

    return (
      <th css={style} aria-colindex={index} ref={ref}>
        <CheckboxInternal
          value={selection.all}
          indeterminate={indeterminate}
          inputProps={{
            ...inputProps,
            "aria-checked": indeterminate
              ? "mixed"
              : selection.all
                ? "true"
                : "false",
            tabIndex,
          }}
          inputRef={inputRef}
        />
      </th>
    );
  },
);

export const CheckboxCell = forwardRef(
  (
    {
      checkboxKey,
      selection,
      onChange,
      inputRef,
      tabIndex,
      isHovered = false,
      isStickyFirstColumn = false,
      index,
      variant = "fill",
      position,
      isTopPinned,
      isBottomPinned,
      stickyFirstColumnShift = 0,
      isLastRow = false,
    }: {
      checkboxKey: string;
      selection: SelectionState;
      onChange: (state: SelectionState) => void;
      inputRef: RefObject<HTMLInputElement>;
      tabIndex: number;
      isHovered?: boolean;
      isStickyFirstColumn?: boolean;
      index: number;
      variant?: "fill" | "ghost";
      isTopPinned?: boolean;
      isBottomPinned?: boolean;
      stickyFirstColumnShift?: number;
      isLastRow?: boolean;
      position: {
        firstLeft?: boolean;
        lastLeft?: boolean;
      };
    },
    ref: ForwardedRef<HTMLTableCellElement>,
  ) => {
    const uiColors = useUiColors();
    const uiShadows = useUiShadows();

    const isPinned = isTopPinned || isBottomPinned;

    const value = selection.all
      ? !selection.exclude.has(checkboxKey)
      : selection.include.has(checkboxKey);

    const onValueChange = useCallback(
      (isSelected: boolean) => {
        let res;
        if (selection.all) {
          res = new Set<string>(selection.exclude);
          if (isSelected) {
            res.delete(checkboxKey);
          } else {
            res.add(checkboxKey);
          }
        } else {
          res = new Set<string>(selection.include);
          if (isSelected) {
            res.add(checkboxKey);
          } else {
            res.delete(checkboxKey);
          }
        }
        onChange(
          selection.all
            ? { all: selection.all, exclude: res }
            : {
                all: selection.all,
                include: res,
              },
        );
      },
      [checkboxKey, selection, onChange],
    );

    const toggleState = useToggleState({
      isSelected: value,
      onChange: onValueChange,
    });

    const { inputProps } = useCheckbox(
      {
        isSelected: value,
        onChange: onValueChange,
        "aria-label": "Select",
      },
      toggleState,
      inputRef,
    );

    return (
      <td
        width={56}
        css={{
          ...useBaseCellStyle({
            isFocusVisible: false,
            isHeaderCell: false,
            ...(variant === "ghost" && { position }),
            variant,
            isPinned,
          }),
          ...(isTopPinned &&
            !isStickyFirstColumn && {
              zIndex: zIndexMap.stickyRow,
              boxShadow: uiShadows.bottomNormal,
              clipPath: `inset(0 0 -40px 0);`,
            }),
          ...(isBottomPinned &&
            !isStickyFirstColumn && {
              zIndex: zIndexMap.stickyRow,
              boxShadow: uiShadows.topNormal,
              clipPath: `inset(-40px 0 0 0);`,
            }),

          ...(isStickyFirstColumn && {
            position: "sticky",
            insetInlineStart: `${stickyFirstColumnShift}px`,
            zIndex: zIndexMap.stickyFirstColumn,
          }),

          ...(variant === "ghost" &&
            !isPinned &&
            !isLastRow && {
              borderBottomStyle: "solid",
              borderBottomColor: cssFns.setOpacity(uiColors.line, 0.1),
              borderBottomWidth: "2px",
            }),

          backgroundColor: isPinned
            ? uiColors.background
            : isHovered
              ? cssFns.blend(uiColors.background, uiColors.hover)
              : uiColors.background,
        }}
        aria-colindex={index}
        ref={ref}
      >
        <CheckboxInternal
          inputProps={{ ...inputProps, tabIndex }}
          inputRef={inputRef}
          value={value}
        />
      </td>
    );
  },
);
