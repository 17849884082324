/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";

import { cssFns } from "@superweb/css";
import { useIsMobile, useTypo, useUiColors, LinkButton } from "@superweb/ui";

import type { UseV1ParkInfoGetResult } from "@fleet/api/fleet-forms";

import { Message, useMessage } from "#internal/intl";
import { Offer, PageHeader } from "#internal/ui";

import YangoCarArchivePage from "../images/car-with-road.svg";
import YangoDeliveryArchivePage from "../images/yango-delivery-archive.svg";
import YandexArchivePage from "../images/yandex-archive.svg";

type ParkInfoData = Extract<
  UseV1ParkInfoGetResult,
  {
    status: 410;
  }
>["data"];

const LOGO_HEIGHT = 31;

export const ArchivePage = ({
  parkInfo,
  isIframe,
}: {
  parkInfo: ParkInfoData;
  isIframe: boolean;
}) => {
  const isMobile = useIsMobile();
  const uiColors = useUiColors();
  const typo = useTypo();
  const message = useMessage();
  const [searchParams] = useSearchParams();

  const isDelivery = searchParams.get("specification") === "delivery";

  const title = message({
    id: "7643d784-2fe0-4a7a-89ae-9f8d80196f80",
    context: "Fleet forms. Archive link. Page name",
    default: "Archived link",
  });

  const phone = parkInfo.phone;
  const messageLink = parkInfo.messenger_link;
  const phoneNumber = phone?.slice(1);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          ...(isMobile
            ? { rowGap: "8px", ...cssFns.padding("10px") }
            : { rowGap: "20px", ...cssFns.padding("16px") }),
          backgroundColor: uiColors.backgroundMinor,
        }}
      >
        {!isIframe && (
          <PageHeader
            logoHeight={LOGO_HEIGHT}
            partnerName={parkInfo.partner_name}
          />
        )}
        <main
          css={{
            alignSelf: "center",
            display: "grid",
            justifyItems: "center",
            alignItems: "center",
            height: `calc(100% - ${LOGO_HEIGHT}px)`,
            gridTemplateRows: "auto 1fr",
            maxWidth: "764px",
            width: "100%",
            rowGap: "16px",
          }}
        >
          <div
            css={{
              width: "100%",
            }}
          >
            <Offer
              offerText={parkInfo.marketing_offer}
              isDelivery={isDelivery}
              phone={parkInfo.phone}
              stage="archive"
              messageLink={parkInfo.messenger_link}
            />
          </div>
          <div
            css={{
              height: "100%",
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              rowGap: "16px",
              width: "100%",
            }}
          >
            <div
              css={{
                display: "flex",
                flexGrow: "1",
                position: "relative",
                isolation: "isolate",
                boxSizing: "border-box",
                rowGap: "16px",
              }}
            >
              <div
                css={{
                  flexGrow: "1",
                  display: "grid",
                  backgroundColor: uiColors.background,
                  ...cssFns.border({ radius: "26px" }),
                  color: uiColors.text,
                }}
              >
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    rowGap: "30px",
                    ...cssFns.margin("auto"),
                    textAlign: "center",
                    ...cssFns.padding("16px"),
                  }}
                >
                  <img
                    src={
                      parkInfo.brand === "yandex"
                        ? YandexArchivePage
                        : isDelivery
                          ? YangoDeliveryArchivePage
                          : YangoCarArchivePage
                    }
                    css={{ height: isMobile ? "185px" : "280px" }}
                  />
                  <div
                    css={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      rowGap: "4px",
                    }}
                  >
                    <div
                      css={{
                        ...typo({
                          level: "title4",
                          weight: "medium",
                          density: "normal",
                        }),
                      }}
                    >
                      <Message
                        id="bbac6c50-59e0-4f28-b20f-c8cc3cd400d3"
                        context="Fleet forms. Archive link. Title"
                        default="Recruitment with this link completed"
                      />
                    </div>
                    <div
                      css={{
                        ...typo({
                          level: "body2",
                          weight: "regular",
                          density: "tight",
                        }),
                      }}
                    >
                      <Message
                        id="60f230b8-a0fe-47ee-8c39-327fe4fccf4f"
                        context="Fleet forms. Archive link. Text"
                        default="Contact the partner to learn about other options"
                      />
                    </div>
                  </div>
                  <div
                    css={{
                      display: "grid",
                      gridTemplateColumns:
                        messageLink && phone ? "1fr 1fr" : "minmax(180px, 1fr)",
                      columnGap: "8px",
                    }}
                  >
                    {messageLink && (
                      <LinkButton
                        size="m"
                        link={{
                          href: messageLink,
                        }}
                        view="default"
                        text={message({
                          id: "0984d2b1-e356-4974-8c0c-cc8224cbb366",
                          context:
                            "Fleet forms. Archive link. Messanger button",
                          default: "Message",
                        })}
                      />
                    )}
                    {phone && (
                      <LinkButton
                        size="m"
                        view="action"
                        link={{ href: `tel:${phoneNumber}` }}
                        text={message({
                          id: "e628b723-af2b-408a-832f-41bbb43df17c",
                          context: "Fleet forms. Archive link. Call button",
                          default: "Call",
                        })}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
