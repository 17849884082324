/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { createContext, useContext, useMemo, type ReactNode } from "react";

export type UiOptionsValue = {
  /** Options for enabling and disabling experimental or testing features */
  experimental?: {
    /**
     * If enabled, popover element e.g. listbox in Select or menu in MenuButton
     * will be rendered inside AppContainer instead of document.body.
     */
    renderPopoversInsideAppContainer?: boolean;

    /**
     * If enabled, Field's label placeholder will be extended on focus (when value is entered) to fill the gap.
     */
    extendFieldLabelOnFocus?: boolean;

    /**
     * If enabled, the page will have a bottom padding.
     */
    enabledPagePaddingBottom?: boolean;

    /**
     * If enabled, tooltip reference element will be child of cell (not cell)
     */
    newTableTooltipRef?: boolean;

    /**
     * If true, snackbars will be arranged in an animated stack.
     */
    enableSnackbarStackAnimation?: boolean;

    /**
     * If true, ExperimentalTagField will have new design.
     */
    enableExperimentalTagFieldNewDesign?: boolean;

    /**
     * If true, Dialog backdrop will have blur.
     */
    enableExperimentalDialogBackdropBlur?: boolean;

    /**
     * If true, all popovers use backgroundFloating as background.
     */
    enableBackgroundFloatingInPopover?: boolean;

    /**
     * If true, in dark mode will use new color for background and backgroundMinor
     */
    enableNewBackgroundColorInDarkMode?: boolean;

    /**
     * If true, icon and label show together.
     */
    enableIconWithLabelInSegments?: boolean;
  };
};

const defaultValue: UiOptionsValue = {
  experimental: {},
};

const context = createContext<UiOptionsValue>(defaultValue);

/**
 * Allows to set options for all components from `@superweb/ui`
 */
export const UiOptions = ({
  value,
  children,
}: {
  value: UiOptionsValue;
  children: ReactNode;
}) => {
  const nextValue = useMemo(() => {
    return {
      ...defaultValue,
      ...value,
      experimental: {
        ...defaultValue.experimental,
        ...value.experimental,
      },
    };
  }, [value]);

  return <context.Provider value={nextValue}>{children}</context.Provider>;
};
/**
 * @internal
 * Hook for get options
 */
export const useUiOptions = () => {
  return useContext(context);
};
