/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */
import {
  useRef,
  useState,
  type ReactNode,
  useEffect,
  useCallback,
  type PointerEvent,
  forwardRef,
  type RefObject,
} from "react";
import {
  Overlay,
  useFocusWithin,
  useHover,
  usePopover,
  useSearchField,
} from "react-aria";
import { useOverlayTriggerState, useSearchFieldState } from "react-stately";

import { cssFns } from "@superweb/css";
import {
  Button,
  Dialog,
  Spin,
  icons,
  useIsMobile,
  useTypo,
  useUiColors,
  useCustomScrollbar,
  useShimmer,
} from "@superweb/ui";

import { ClearButton } from "./clear-button";
import { ErrorFallback } from "./error-fallback";
import { NoResults } from "./no-results";

type Link = {
  href: string;
  onClick?: (e: PointerEvent<HTMLAnchorElement>) => void;
};
type SearchOption = {
  key: string;
  label: string;
  link?: Link;
};

type SearchProps<T> = {
  placeholder: string;
  listPlaceholder?: string;
  value: string;
  ariaLabel: string;
  options?: T[];
  isLoading?: boolean;
  isEmpty?: boolean;
  isError?: boolean;
  maxLength?: number;
  onChange: (value: string) => void;
  optionContent?: (option: T) => ReactNode;
  onReloadButtonPress?: () => void;
  enableMobileSearchVersion?: boolean;
};

const LinkOption = ({
  children,
  isFocused,
  isHoverFocusBlocked,
  link,
  unblockHoverFocus,
  getRef,
  onHover,
}: {
  children: ReactNode;
  isFocused?: boolean;
  isHoverFocusBlocked?: boolean;
  link?: Link;
  unblockHoverFocus: () => void;
  getRef: (element: HTMLLIElement) => void;
  onHover: () => void;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  const { hoverProps } = useHover({
    onHoverStart: onHover,
    isDisabled: isHoverFocusBlocked,
  });

  requestAnimationFrame(() => {
    if (isHoverFocusBlocked) {
      unblockHoverFocus();
    }
  });

  return (
    <li {...hoverProps} ref={getRef} tabIndex={-1}>
      <a
        {...link}
        css={{
          ...cssFns.padding("8px"),
          ...typo({
            level: "body2",
            density: "tight",
            weight: "regular",
          }),
          boxSizing: "border-box",
          minHeight: "56px",
          display: "grid",
          alignItems: "center",
          columnGap: "4px",
          gridTemplateColumns: "1fr auto",
          color: uiColors.text,
          overflowX: "hidden",
          textOverflow: "ellipsis",
          backgroundColor: isFocused ? uiColors.backgroundMinor : undefined,
          outlineStyle: "none",
          cursor: "pointer",
          textDecorationLine: "none",
        }}
      >
        {children}
      </a>
    </li>
  );
};

const SearchInput = forwardRef<
  HTMLDivElement,
  {
    size?: "s" | "l";
    placeholder: string;
    ariaLabel: string;
    value: string;
    onChange: (value: string) => void;
    setIndexOfFocusedOption: (value?: number) => void;
    setLastFocusedBy: (value?: "keyboard" | "mouse") => void;
    optionRef: RefObject<HTMLLIElement | undefined>;
    popoverRef?: RefObject<HTMLDivElement>;
    setIsOptionsOpened?: (value: boolean) => void;
    optionsLength?: number;
    indexOfFocusedOption?: number;
    autoFocus?: boolean;
    maxLength?: number;
  }
>(
  (
    {
      size = "s",
      placeholder,
      ariaLabel,
      value,
      onChange,
      setIsOptionsOpened,
      indexOfFocusedOption,
      setIndexOfFocusedOption,
      setLastFocusedBy,
      optionsLength,
      popoverRef,
      optionRef,
      autoFocus = false,
      maxLength,
    },
    ref,
  ) => {
    const uiColors = useUiColors();
    const typo = useTypo();

    const inputRef = useRef<HTMLInputElement>(null);

    const [isFocused, setIsFocused] = useState(false);

    const state = useSearchFieldState({
      value,
      onChange,
    });

    const { inputProps } = useSearchField(
      { placeholder, "aria-label": ariaLabel, autoFocus, maxLength },
      state,
      inputRef,
    );

    const { focusWithinProps: inputFocusWithinProps } = useFocusWithin({
      onFocusWithin: () => {
        setIsFocused(true);
        setIsOptionsOpened?.(true);
      },
      onBlurWithin: (e) => {
        if (popoverRef?.current?.contains(e.relatedTarget)) {
          inputRef.current?.focus();
          return;
        }
        setIsFocused(false);
        setIsOptionsOpened?.(false);
        setIndexOfFocusedOption(undefined);
      },
    });

    return (
      <div css={{ position: "relative" }} {...inputFocusWithinProps}>
        <div
          ref={ref}
          css={{
            boxSizing: "border-box",
            display: "grid",
            gridTemplateColumns: "min-content auto min-content",
            height: size === "s" ? "40px" : "56px",
            alignContent: "center",
            backgroundColor: isFocused
              ? uiColors.backgroundFloating
              : uiColors.controlMinor,
            ...cssFns.border({
              width: "2px",
              radius: "16px",
              style: "solid",
              color: isFocused ? uiColors.text : "transparent",
            }),
          }}
          onClick={() => {
            setIsOptionsOpened?.(true);
            inputRef.current?.focus();
          }}
        >
          <div
            css={{
              gridColumnStart: "1",
              gridColumnEnd: "4",
              gridRowStart: "1",
              gridRowEnd: "2",
              alignSelf: "start",
              color: uiColors.text,
            }}
          >
            <input
              {...inputProps}
              onKeyDown={(e) => {
                if (!optionsLength) return;
                switch (e.key) {
                  case "ArrowUp":
                    e.preventDefault();
                    if (indexOfFocusedOption === undefined) {
                      setIndexOfFocusedOption(optionsLength - 1);
                    } else if (indexOfFocusedOption > 0) {
                      setIndexOfFocusedOption(indexOfFocusedOption - 1);
                    }
                    setLastFocusedBy("keyboard");
                    break;
                  case "ArrowDown":
                    e.preventDefault();
                    if (indexOfFocusedOption === undefined) {
                      setIndexOfFocusedOption(0);
                    } else if (indexOfFocusedOption < optionsLength - 1) {
                      setIndexOfFocusedOption(indexOfFocusedOption + 1);
                    }
                    setLastFocusedBy("keyboard");
                    break;
                  case "Tab":
                  case "Enter":
                    if (indexOfFocusedOption !== undefined) {
                      e.preventDefault();
                      optionRef.current?.firstChild instanceof
                        HTMLAnchorElement &&
                        optionRef.current.firstChild.click();
                    }
                }
              }}
              ref={inputRef}
              __experimental_webkitSearchCancelButtonCss={{
                display: "none",
              }}
              __experimental_webkitSearchDecorationCss={{ display: "none" }}
              __experimental_placeholderCss={{
                color: uiColors.textMinor,
              }}
              css={{
                appearance: "none",
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                paddingBlockStart: "8px",
                paddingInlineEnd: "40px",
                paddingBlockEnd: "8px",
                paddingInlineStart: size === "s" ? "40px" : "54px",
                ...cssFns.margin("0"),
                ...typo({
                  level: "body2",
                  density: "tight",
                  weight: "regular",
                }),
                color: uiColors.text,
                backgroundColor: "transparent",
                outlineStyle: "none",
                ...cssFns.border({
                  style: "none",
                  radius: "16px",
                  width: "0",
                }),
              }}
            />
          </div>
          <div
            css={{
              gridColumnStart: "1",
              gridColumnEnd: "2",
              gridRowStart: "1",
              gridRowEnd: "2",
              alignSelf: "start",
              marginBlockStart: "6px",
              marginInlineStart: size === "s" ? "6px" : "14px",
              color: uiColors.text,
            }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
          >
            <div
              css={{
                display: "flex",
                alignItems: "center",
                marginInlineEnd: "8px",
                color: uiColors.textMinor,
              }}
            >
              <icons.Search />
            </div>
          </div>
          <div
            css={{
              gridColumnStart: "3",
              gridColumnEnd: "4",
              gridRowStart: "1",
              gridRowEnd: "2",
              alignSelf: "center",
              marginInlineStart: "8px",
            }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
          >
            <div
              css={{
                width: "32px",
                height: "32px",
                marginInlineEnd: "8px",
              }}
            >
              {state.value && isFocused && (
                <ClearButton
                  onPress={() => onChange("")}
                  onFocus={() => inputRef.current?.focus()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

const DesktopSearch = <T extends SearchOption>({
  options,
  value,
  placeholder,
  ariaLabel,
  isLoading,
  isEmpty,
  isError,
  onChange,
  optionContent,
  onReloadButtonPress,
}: SearchProps<T>) => {
  const uiColors = useUiColors();

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const optionRef = useRef<HTMLLIElement>();
  const customScrollbarCSS = useCustomScrollbar();

  const overlayTriggerState = useOverlayTriggerState({});

  const { popoverProps } = usePopover(
    {
      offset: 8,
      isNonModal: true,
      popoverRef,
      triggerRef: ref,
    },
    overlayTriggerState,
  );

  const [indexOfFocusedOption, setIndexOfFocusedOption] = useState<number>();

  const isOptionFullyVisible = () => {
    if (!listRef.current || !optionRef.current) {
      return;
    }
    const listTop = listRef.current.scrollTop;
    const listBottom = listTop + listRef.current.offsetHeight;

    const optionTop = optionRef.current.offsetTop;
    const optionBottom = optionTop + optionRef.current.offsetHeight;

    return optionTop >= listTop && optionBottom <= listBottom;
  };

  const scrollToOptionIfNeeded = useCallback(() => {
    if (!listRef.current || !optionRef.current) {
      return;
    }

    if (!isOptionFullyVisible()) {
      listRef.current.scrollTop =
        optionRef.current.offsetTop - listRef.current.offsetTop;

      setIsHoverFocusBlocked(true);
    }
  }, [listRef, optionRef]);

  const [isHoverFocusBlocked, setIsHoverFocusBlocked] = useState(false);
  const [lastFocusedBy, setLastFocusedBy] = useState<
    "keyboard" | "mouse" | undefined
  >();

  useEffect(() => {
    if (lastFocusedBy === "keyboard") {
      scrollToOptionIfNeeded();
    }
  }, [indexOfFocusedOption, lastFocusedBy, scrollToOptionIfNeeded]);

  return (
    <>
      <SearchInput
        placeholder={placeholder}
        ariaLabel={ariaLabel}
        value={value}
        onChange={onChange}
        setIsOptionsOpened={(value) => overlayTriggerState.setOpen(value)}
        optionsLength={options?.length}
        setIndexOfFocusedOption={setIndexOfFocusedOption}
        indexOfFocusedOption={indexOfFocusedOption}
        setLastFocusedBy={setLastFocusedBy}
        popoverRef={popoverRef}
        optionRef={optionRef}
        ref={ref}
      />
      {overlayTriggerState.isOpen &&
      (options?.length || isLoading || isEmpty || isError) ? (
        <Overlay>
          <div {...popoverProps} ref={popoverRef}>
            <div
              css={{
                width: `${ref.current?.offsetWidth}px`,
                maxHeight: "350px",
                ...((isLoading || isEmpty || isError) && {
                  height: "350px",
                }),
                backgroundColor: uiColors.background,
                ...cssFns.border({ radius: "16px" }),
                ...cssFns.boxShadow({
                  offsetY: "8px",
                  blurRadius: "20px",
                  color: uiColors.fog,
                }),
                overflowY: "auto",
                overscrollBehaviorY: "contain",
              }}
              ref={listRef}
              {...customScrollbarCSS}
            >
              {isError ? (
                <ErrorFallback
                  onReloadButtonPress={() => {
                    inputRef.current?.focus();
                    onReloadButtonPress?.();
                  }}
                />
              ) : isLoading ? (
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <Spin />
                </div>
              ) : isEmpty ? (
                <NoResults />
              ) : options?.length ? (
                <ul
                  css={{
                    ...cssFns.margin("0"),
                    ...cssFns.padding("0"),
                    listStyleType: "none",
                  }}
                >
                  {options.map((option, index) => (
                    <LinkOption
                      key={option.key}
                      link={
                        option.link && {
                          ...option.link,
                          onClick: (e: PointerEvent<HTMLAnchorElement>) => {
                            option.link?.onClick?.(e);
                            overlayTriggerState.close();
                          },
                        }
                      }
                      isFocused={index === indexOfFocusedOption}
                      isHoverFocusBlocked={isHoverFocusBlocked}
                      getRef={(element) => {
                        if (indexOfFocusedOption === index) {
                          optionRef.current = element;
                        }
                      }}
                      unblockHoverFocus={() => setIsHoverFocusBlocked(false)}
                      onHover={() => {
                        setIndexOfFocusedOption(index);
                        setLastFocusedBy("mouse");
                      }}
                    >
                      {optionContent?.(option)}
                    </LinkOption>
                  ))}
                </ul>
              ) : null}
            </div>
          </div>
        </Overlay>
      ) : undefined}
    </>
  );
};

const MobileSearch = <T extends SearchOption>(props: SearchProps<T>) => {
  const [isSearchOpen, setSearchOpen] = useState(false);

  return (
    <>
      <Button
        view="ghost"
        icon={icons.Search}
        size="s"
        onPress={() => setSearchOpen(true)}
        ariaLabel={props.ariaLabel}
      />
      {isSearchOpen && (
        <Dialog
          variant="slideover"
          title={props.ariaLabel}
          onClose={() => setSearchOpen(false)}
          content={<SearchContent {...props} />}
        />
      )}
    </>
  );
};

export const SearchContent = <T extends SearchOption>({
  ariaLabel,
  placeholder,
  value,
  options,
  isEmpty,
  isError,
  isLoading,
  listPlaceholder,
  maxLength,
  optionContent,
  onReloadButtonPress,
  onChange,
  enableV2,
}: SearchProps<T> & { enableV2?: boolean }) => {
  const uiColors = useUiColors();
  const customScrollbarCSS = useCustomScrollbar();
  const shimmer = useShimmer();
  const isMobile = useIsMobile();

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const optionRef = useRef<HTMLLIElement>();

  const [indexOfFocusedOption, setIndexOfFocusedOption] = useState<number>();

  const isOptionFullyVisible = useCallback(() => {
    if (!listRef.current || !optionRef.current) {
      return;
    }
    const listTop = listRef.current.offsetTop + listRef.current.scrollTop;
    const listBottom = listTop + listRef.current.offsetHeight;

    const optionTop =
      optionRef.current.offsetTop + (enableV2 ? listRef.current.offsetTop : 0);
    const optionBottom = optionTop + optionRef.current.offsetHeight;

    return optionTop >= listTop && optionBottom <= listBottom;
  }, [enableV2]);

  const scrollToOptionIfNeeded = useCallback(() => {
    if (!listRef.current || !optionRef.current) {
      return;
    }

    if (!isOptionFullyVisible()) {
      listRef.current.scrollTop =
        optionRef.current.offsetTop -
        listRef.current.offsetTop +
        (enableV2 ? listRef.current.offsetTop : 0);

      setIsHoverFocusBlocked(true);
    }
  }, [listRef, optionRef, enableV2, isOptionFullyVisible]);

  const [isHoverFocusBlocked, setIsHoverFocusBlocked] = useState(false);
  const [lastFocusedBy, setLastFocusedBy] = useState<
    "keyboard" | "mouse" | undefined
  >();

  useEffect(() => {
    if (lastFocusedBy === "keyboard") {
      scrollToOptionIfNeeded();
    }
  }, [indexOfFocusedOption, lastFocusedBy, scrollToOptionIfNeeded]);

  if (enableV2) {
    return (
      <div
        css={{
          display: "grid",
          gridAutoRows: "auto 1fr",
          rowGap: "12px",
          height: isMobile ? "100%" : "660px",
          overflowY: "hidden",
        }}
      >
        <div css={cssFns.padding("0", "16px")}>
          <SearchInput
            size="l"
            placeholder={placeholder}
            ariaLabel={ariaLabel}
            value={value}
            onChange={onChange}
            optionsLength={options?.length}
            setIndexOfFocusedOption={setIndexOfFocusedOption}
            indexOfFocusedOption={indexOfFocusedOption}
            setLastFocusedBy={setLastFocusedBy}
            optionRef={optionRef}
            ref={ref}
            autoFocus
            maxLength={maxLength}
          />
        </div>
        {options?.length || isLoading || isEmpty || isError ? (
          <div
            css={{
              display: "grid",
              alignItems: "center",
              backgroundColor: uiColors.background,
              overflowY: "auto",
              overscrollBehaviorY: "contain",
              overflowX: "hidden",
              position: "relative",
            }}
            ref={listRef}
            {...customScrollbarCSS}
          >
            {isError ? (
              <ErrorFallback
                onReloadButtonPress={() => {
                  inputRef.current?.focus();
                  onReloadButtonPress?.();
                }}
              />
            ) : isEmpty && isLoading ? (
              <div
                css={{
                  display: "grid",
                  ...(isMobile
                    ? {
                        alignItems: "start",
                        paddingBlockStart: "140px",
                      }
                    : {
                        alignItems: "center",
                      }),
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Spin />
              </div>
            ) : isEmpty ? (
              <NoResults />
            ) : options?.length ? (
              <>
                <ul
                  css={{
                    ...cssFns.margin("0"),
                    ...cssFns.padding("0"),
                    listStyleType: "none",
                    height: "100%",
                  }}
                >
                  {options.map((option, index) => (
                    <LinkOption
                      key={option.key}
                      link={
                        option.link && {
                          ...option.link,
                          onClick: (e: PointerEvent<HTMLAnchorElement>) => {
                            setIndexOfFocusedOption(undefined);
                            option.link?.onClick?.(e);
                          },
                        }
                      }
                      isFocused={index === indexOfFocusedOption}
                      isHoverFocusBlocked={isHoverFocusBlocked}
                      getRef={(element) => {
                        if (indexOfFocusedOption === index) {
                          optionRef.current = element;
                        }
                      }}
                      unblockHoverFocus={() => setIsHoverFocusBlocked(false)}
                      onHover={() => {
                        setIndexOfFocusedOption(index);
                        setLastFocusedBy("mouse");
                      }}
                    >
                      {optionContent?.(option)}
                    </LinkOption>
                  ))}
                </ul>
                {isLoading && (
                  <div
                    css={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                      ...shimmer,
                    }}
                  />
                )}
              </>
            ) : null}
          </div>
        ) : (
          listPlaceholder && <NoResults text={listPlaceholder} />
        )}
      </div>
    );
  }

  return (
    <div
      css={{
        display: "grid",
        gridAutoRows: "auto 1fr",
        rowGap: "12px",
        height: `${window.innerHeight - 72}px`,
      }}
    >
      <div
        css={{
          ...cssFns.padding("0", "16px"),
        }}
      >
        <SearchInput
          placeholder={placeholder}
          ariaLabel={ariaLabel}
          value={value}
          onChange={onChange}
          optionsLength={options?.length}
          setIndexOfFocusedOption={setIndexOfFocusedOption}
          indexOfFocusedOption={indexOfFocusedOption}
          setLastFocusedBy={setLastFocusedBy}
          optionRef={optionRef}
          ref={ref}
        />
      </div>
      {options?.length || isLoading || isEmpty || isError ? (
        <div
          css={{
            display: "grid",
            alignItems: "center",
            backgroundColor: uiColors.background,
            overflowY: "auto",
            overscrollBehaviorY: "contain",
          }}
          ref={listRef}
        >
          {isError ? (
            <ErrorFallback
              onReloadButtonPress={() => {
                inputRef.current?.focus();
                onReloadButtonPress?.();
              }}
            />
          ) : isLoading ? (
            <div
              css={{
                display: "grid",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Spin />
            </div>
          ) : isEmpty ? (
            <NoResults />
          ) : options?.length ? (
            <ul
              css={{
                ...cssFns.margin("0"),
                ...cssFns.padding("0"),
                listStyleType: "none",
                height: "100%",
              }}
            >
              {options.map((option, index) => (
                <LinkOption
                  key={option.key}
                  link={
                    option.link && {
                      ...option.link,
                      onClick: (e: PointerEvent<HTMLAnchorElement>) => {
                        option.link?.onClick?.(e);
                      },
                    }
                  }
                  isFocused={index === indexOfFocusedOption}
                  isHoverFocusBlocked={isHoverFocusBlocked}
                  getRef={(element) => {
                    if (indexOfFocusedOption === index) {
                      optionRef.current = element;
                    }
                  }}
                  unblockHoverFocus={() => setIsHoverFocusBlocked(false)}
                  onHover={() => {
                    setIndexOfFocusedOption(index);
                    setLastFocusedBy("mouse");
                  }}
                >
                  {optionContent?.(option)}
                </LinkOption>
              ))}
            </ul>
          ) : null}
        </div>
      ) : undefined}
    </div>
  );
};

export const Search = <T extends SearchOption>({
  enableMobileSearchVersion,
  ...props
}: SearchProps<T>) => {
  const isMobile = useIsMobile();

  return isMobile && enableMobileSearchVersion ? (
    <MobileSearch {...props} />
  ) : (
    <DesktopSearch {...props} />
  );
};
