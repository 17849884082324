/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef, type ReactNode } from "react";
import {
  mergeProps,
  useButton,
  useFocusRing,
  type AriaButtonProps,
} from "react-aria";

import { cssFns } from "@superweb/css";

import { useUiColors } from "../theme";
import { icons } from "../icons";

export const FieldButton = ({
  children,
  ...otherProps
}: AriaButtonProps & {
  children: ReactNode;
}) => {
  const uiColors = useUiColors();

  const ref = useRef<HTMLButtonElement>(null);

  const { isFocused, focusProps } = useFocusRing();

  const { buttonProps, isPressed } = useButton(otherProps, ref);

  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      ref={ref}
      css={{
        width: "32px",
        height: "32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...cssFns.border({ width: "0", radius: "4px", style: "none" }),
        ...cssFns.padding("0"),
        outlineStyle: "none",
        backgroundColor:
          isFocused && !isPressed ? uiColors.controlMinor : "transparent",
        color: uiColors.text,
        cursor: otherProps.isDisabled ? "default" : "pointer",
      }}
    >
      {children}
    </button>
  );
};

export const ClearButton = ({
  visible,
  onPress,
}: {
  visible?: boolean;
  onPress: () => void;
}) => {
  const ariaButtonProps = {
    "aria-label": "Clear",
    onPress,
    preventFocusOnPress: true,
  };

  return (
    <div
      css={{
        width: "32px",
        height: "32px",
      }}
    >
      {visible && (
        <FieldButton {...ariaButtonProps}>
          <icons.CrossM />
        </FieldButton>
      )}
    </div>
  );
};
