/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef, useState, type RefObject, type ReactNode } from "react";
import {
  useButton,
  useComboBox,
  useFocusWithin,
  type AriaButtonProps,
  type Key,
} from "react-aria";
import {
  Item,
  useComboBoxState as useAriaComboBoxState,
  type ComboBoxStateOptions,
} from "react-stately";

import { cssFns } from "@superweb/css";

import { useMessage } from "#intl";

import { FetchMore } from "../fetch-more";
import {
  ClearButton,
  Field,
  FieldDescription,
  FieldErrorMessage,
  FieldLabel,
  useTextInputStyle,
} from "../fields";
import { icons } from "../icons";
import { ListBox, ListBoxPopover, OptionContent } from "../listbox";
import { useIsMobile } from "../mobile-context";
import { useShimmer } from "../shimmer/shimmer";
import { Spin } from "../spin/spin";
import { useDebouncedState } from "../state/state";
import { useUiColors } from "../theme";
import { useTypo } from "../typo";

type InvalidRules = {
  required: boolean;
};

export type ComboBoxOption = {
  /**
   * The unique value of the option
   */
  key: string;

  /**
   * The text displayed in the list option and in the input field if option selected
   */
  label: string;

  /**
   * Additional text displayed below the main text in the option
   */
  description?: string;

  /**
   * ComboBox option icon.
   * maximum: 40px
   */
  icon?: ReactNode;
};

export type ComboBoxState<T extends ComboBoxOption> = {
  /**
   * Currently selected option.
   */
  value?: T;

  /**
   * Current input's value, that user entered to search for option.
   */
  inputValue: string;

  /**
   * When set `false` the error message is not visible even if is set.
   * Is set on change depending on the interaction.
   * Can be set externally to force hide/show the error message.
   * The field has invalid state when both the `errorMessage` is not empty and `errorVisible` is `true`.
   */
  errorVisible?: boolean;

  /**
   * The error message associated with the field.
   * Visible only when `errorVisible` is `true`.
   * The field has invalid state when both the `errorMessage` is not empty and `errorVisible` is `true`.
   */
  errorMessage?: string;

  /**
   * Is set to `true` on change if the field contains invalid value.
   */
  invalid?: boolean;
};

export const ComboBox = <T extends ComboBoxOption>({
  label,
  description,
  ariaDescribedBy,
  required = false,
  state,
  placeholder,
  disabled = false,
  options,
  icon,
  onChange,
  onOpenChange,
}: {
  /**
   * Text for field's label, that describes field's meaning.
   * No need to specify input examples in label's text.
   * The label is used for the accessibility of the element
   *
   * Links:
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label
   * https://www.w3.org/WAI/tutorials/forms/labels/
   */
  label: string;

  /**
   * Text for field's description, that describes in detail the purpose of this field.
   *
   * Links:
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-description
   */
  description?: string;

  /**
   * Text for field's description, that describes in detail the purpose of this field.
   * Use `ariaDescribedBy` when you need to use an element with text and additional content
   * (e.g., icons, images, or formatting) to describe the field.
   * This allows for associating the field with rich, external descriptions that provide additional context.
   *
   * Links:
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby
   */
  ariaDescribedBy?: string;

  /**
   * If `true`, the input element is required.
   * If `true`, the clear button element isn't rendered.
   * @defaultValue false
   */
  required?: boolean;

  /**
   * Current field's state.
   * The state stores the interactive fields, that change when interacting with the component.
   */
  state: ComboBoxState<T>;

  /**
   * Temporary text that displays in field when it is empty.
   */
  placeholder?: string;

  /**
   * If `true`, the component is disabled.
   * @defaultValue false
   */
  disabled?: boolean;

  /**
   * ComboBox's options, that make up the drop-down list.
   *
   * Could either be a list of static options or set of
   * parameters to dynamically load options
   */
  options:
    | T[]
    | ({
        /**
         * Array of currently loaded options.
         */
        data: T[];
      } & (
        | {
            /**
             * Whether options is currently being fetched.
             */
            isFetching?: boolean;

            /**
             * Whether the next page of options is currently being fetched.
             */
            isFetchingNextPage: boolean;

            /**
             * Whether more pages of options can be loaded.
             * When false, `fetchNextPage` becomes no-op.
             */
            hasNextPage: boolean;

            /**
             * Function to load next page of options.
             * Called when user scrolls to the end of options list.
             */
            fetchNextPage: () => void;
          }
        | {
            /**
             * Whether options is currently being fetched.
             */
            isFetching: boolean;

            hasNextPage?: never;

            isFetchingNextPage?: never;

            fetchNextPage?: never;
          }
      ));

  /**
   * Icon at the start of the field.
   */
  icon?: ReactNode;

  /**
   * Callback fired when the state is changed.
   * @param state - Recommended state for the component after the change.
   */
  onChange: (
    state: ComboBoxState<T>,
    info: {
      invalid: {
        required: boolean;
      };
    },
  ) => void;

  /**
   * Callback fired when dropdown is shown/hidden.
   * @param isOpen - Current state of the dropdown.
   */
  onOpenChange?: (isOpen: boolean) => void;
}) => {
  const getErrorMessage = useErrorMessage();
  const shimmer = useShimmer();
  const inputCss = useTextInputStyle({
    disabled,
    icon: Boolean(icon),
  });
  const typo = useTypo();
  const uiColors = useUiColors();

  const ref = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const listBoxRef = useRef<HTMLUListElement>(null);

  // workaround for https://github.com/adobe/react-spectrum/issues/4016
  // Fix error when popoverRef is null when options loading asynchronously
  if (popoverRef.current === null) {
    popoverRef.current = document.createElement("div");
  }

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: (value: boolean) => {
      setIsFocused(value);
    },
  });

  const optionsList = Array.isArray(options) ? options : options.data;
  const isFetching = Array.isArray(options) ? false : options.isFetching;

  const ariaProps: ComboBoxStateOptions<T> = {
    label,
    description,
    errorMessage: state.errorMessage,

    selectedKey: state.value?.key ?? null,
    inputValue: (state.inputValue || state.value?.label) ?? "",

    validationState:
      state.errorVisible && state.errorMessage ? "invalid" : "valid",

    isDisabled: disabled,

    isRequired: required,

    items: optionsList,
    children: optionsList.map((option) => (
      <Item key={option.key} textValue={option.label}>
        <OptionContent
          label={option.label}
          description={option.description}
          icon={option.icon}
        />
      </Item>
    )),
    allowsEmptyCollection: true,

    onSelectionChange: (key) => {
      const invalidRules = validateComboboxValue({
        value: key,
        required,
      });

      const invalid = Object.values(invalidRules).some(Boolean);

      const errorMessage = invalid ? getErrorMessage(invalidRules) : undefined;

      onChange(
        {
          ...state,
          value:
            // We use only string keys, but in react-aria it also can be
            // `number` or `null` so we check key type explicitly.
            // Also, selected option can be absent from current options
            // e.g. if options are filtered by `inputValue`, so we first try
            // to preserve currently selected option.
            typeof key !== "string"
              ? undefined
              : key === state.value?.key
                ? state.value
                : optionsList.find((option) => option.key === key),
          inputValue: "",
          errorVisible: true,
          errorMessage,
        },
        {
          invalid: {
            required: invalidRules.required,
          },
        },
      );
    },

    onInputChange(inputValue) {
      if (inputValue !== "" && state.inputValue === inputValue) return;

      onChange(
        {
          ...state,
          ...(inputValue === "" && {
            value: undefined,
          }),
          inputValue,
          errorVisible: false,
          errorMessage: undefined,
        },
        {
          invalid: {
            required: false,
          },
        },
      );
    },

    onOpenChange: (isOpen) => {
      onOpenChange?.(isOpen);
    },
  };

  const ariaState = useAriaComboBoxState(ariaProps);

  const {
    labelProps,
    descriptionProps,
    errorMessageProps,
    inputProps,
    buttonProps,
    listBoxProps,
  } = useComboBox(
    {
      ...ariaProps,
      inputRef,
      buttonRef,
      popoverRef,
      listBoxRef,
      "aria-describedby": ariaDescribedBy,
    },
    ariaState,
  );

  const isMobile = useIsMobile();

  // Workaround for https://github.com/adobe/react-spectrum/issues/1513
  // Corrects the triggering of clicks on elements under the overlay
  // by debouncing the open state
  const debouncedIsOpen = useDebouncedState(ariaState.isOpen, 0);
  // apply debounced state only on mobile in `true` -> `false` phase
  const isOpen = (isMobile && debouncedIsOpen) || ariaState.isOpen;

  const isShrunk = isFocused || isOpen || Boolean(state.value);

  const onClearButtonPress = () => {
    inputRef.current?.focus();
    onChange(
      {
        ...state,
        value: undefined,
        inputValue: "",
        errorVisible: false,
      },
      {
        invalid: {
          required: false,
        },
      },
    );
  };

  return (
    <>
      <Field
        fieldProps={focusWithinProps}
        fieldRef={ref}
        shrunk={isShrunk}
        focused={isFocused}
        disabled={disabled}
        stretchInputElement
        icon={icon}
        label={
          <FieldLabel shrunk={isShrunk} labelProps={labelProps}>
            {label}
          </FieldLabel>
        }
        input={
          <input
            {...inputProps}
            ref={inputRef}
            placeholder={placeholder}
            __experimental_placeholderCss={{
              color: uiColors.textMinor,
              opacity: isFocused ? "1" : "0",
              ...(isFocused && { transitionDuration: "300ms" }),
            }}
            css={{
              ...inputCss,
              paddingInlineEnd: "80px",
            }}
          />
        }
        {...(!required && {
          clearButton: (
            <ClearButton
              visible={Boolean(state.value) && (isFocused || isOpen)}
              onPress={onClearButtonPress}
            />
          ),
        })}
        toolbar={
          <ComboBoxButton ariaButtonProps={buttonProps} buttonRef={buttonRef} />
        }
        descriptionAndError={
          state.errorVisible && state.errorMessage ? (
            <FieldErrorMessage errorMessageProps={errorMessageProps}>
              {state.errorMessage}
            </FieldErrorMessage>
          ) : (
            description && (
              <FieldDescription descriptionProps={descriptionProps}>
                {description}
              </FieldDescription>
            )
          )
        }
        onClick={() => {
          if (!isOpen) {
            buttonRef.current?.click();
          }
          inputRef.current?.focus();
        }}
      />

      {isOpen && (Boolean(ariaState.collection.size) || isFetching) && (
        <ListBoxPopover
          popoverRef={popoverRef}
          triggerRef={ref}
          isNonModal={true}
          maxHeight={350}
          state={ariaState}
        >
          {ariaState.collection.size ? (
            <div
              css={{
                display: "grid",
                ...cssFns.overflow("hidden"),
              }}
            >
              <div css={cssFns.gridArea("1")}>
                <ListBox
                  ariaListBoxProps={listBoxProps}
                  listBoxRef={listBoxRef}
                  state={ariaState}
                />
              </div>
              {isFetching ? (
                <div
                  css={{
                    ...shimmer,
                    ...cssFns.gridArea("1"),
                    pointerEvents: "none",
                  }}
                />
              ) : (
                !Array.isArray(options) &&
                options.hasNextPage && (
                  <FetchMore
                    isFetching={options.isFetchingNextPage}
                    onFetchMore={options.fetchNextPage}
                  />
                )
              )}
            </div>
          ) : (
            <div
              css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "260px",
                ...typo({
                  level: "body2",
                  weight: "regular",
                  density: "tight",
                }),
                color: uiColors.textMinor,
              }}
            >
              <Spin />
            </div>
          )}
        </ListBoxPopover>
      )}
    </>
  );
};

const ComboBoxButton = ({
  ariaButtonProps,
  buttonRef,
}: {
  ariaButtonProps: AriaButtonProps;
  buttonRef: RefObject<HTMLButtonElement>;
}) => {
  const uiColors = useUiColors();

  const { buttonProps } = useButton(ariaButtonProps, buttonRef);

  return (
    <button
      {...buttonProps}
      ref={buttonRef}
      css={{
        width: "32px",
        height: "32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...cssFns.border({ style: "none" }),
        ...cssFns.padding("0"),
        outlineStyle: "none",
        color: uiColors.text,
        backgroundColor: "transparent",
        cursor: ariaButtonProps.isDisabled ? "default" : "pointer",
      }}
    >
      <icons.CornerDown aria-hidden="true" />
    </button>
  );
};

type InitComboBoxState<T extends ComboBoxOption> = {
  value?: T;
  inputValue?: string;
  errorVisible?: boolean;
  errorMessage?: string;
};

export const createComboBoxState = <T extends ComboBoxOption>(
  defaultValue?: InitComboBoxState<T>,
): ComboBoxState<T> => {
  return {
    value: undefined,
    inputValue: "",
    errorVisible: false,
    errorMessage: undefined,
    ...defaultValue,
  };
};

export const useComboBoxState = <T extends ComboBoxOption>(
  defaultValue?: InitComboBoxState<T>,
) => {
  return useState<ComboBoxState<T>>(createComboBoxState(defaultValue));
};

const useErrorMessage = () => {
  const message = useMessage();

  return (invalidRules: InvalidRules) => {
    if (invalidRules.required) {
      return message({
        id: "b7868a49-c3da-4667-9527-f58cfe21b0e4",
        context:
          "Combobox. Error message in platform libraries in the Combobox component.",
        default: "Required field",
      });
    }

    return undefined;
  };
};

const validateComboboxValue = ({
  value,
  required,
}: {
  value: Key | null;
  required?: boolean;
}) => {
  const invalid = {
    required: Boolean(required && !value),
  };

  return invalid;
};
