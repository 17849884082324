declare global {
  interface Window {
    gtag?: (...args: any[]) => void;
    dataLayer?: any[];
  }
}

export const initGtag = (ga_id?: string) => {
  if (typeof window.gtag === "undefined") {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      window.dataLayer?.push(arguments);
    };
  }

  window.gtag("js", new Date());
  window.gtag("config", ga_id);
  const script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtag/js?id=${ga_id}`;
  document.head.appendChild(script);
};

export const sendGtagEvent = (eventName: string, eventCategory: string) => {
  window.gtag?.("event", eventName, { event_category: eventCategory });
};
