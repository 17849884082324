/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { CountryCallingCode } from "libphonenumber-js";
import { useState, type ReactNode } from "react";

import { cssFns } from "@superweb/css";
import { useFlag } from "@superweb/flags";
import { useLocale } from "@superweb/intl";
import {
  ExperimentalForm,
  ExperimentalFormRow,
  PhoneField,
  TextField,
  createComboBoxState,
  createPhoneFieldState,
  createTextFieldState,
  useDebouncedState,
  useTypo,
  useUiColors,
  type ComboBoxOption,
  type ComboBoxState,
  type PhoneFieldState,
  type TextFieldState,
} from "@superweb/ui";

import {
  useV1PersonalSubmitPost,
  useV1StationsGet,
} from "@fleet/api/fleet-forms";

import { useLogAction } from "#internal/analytics";
import { Message, useMessage } from "#internal/intl";
import {
  Banner,
  FormSuggest,
  PhoneVerificator,
  SubmitBottomSheet,
  useOperationIdContext,
} from "#internal/ui";
import {
  useErrorMessages,
  useOnChangePartial,
  useOnShowErrors,
  useValidate,
} from "#internal/utils";

import type { ParkInfoData } from "./form";
import { AddressSuggest } from "../ui";

type RegisterFormState = {
  phone: PhoneFieldState;
  first_name: TextFieldState;
  last_name: TextFieldState;
  address: ComboBoxState<ComboBoxOption>;
  address_apartment: TextFieldState;
  doc_address: ComboBoxState<ComboBoxOption>;
  tax_identification_number: TextFieldState;
  tax_system: ComboBoxState<ComboBoxOption>;
  email: TextFieldState;
  tax_authority_code: TextFieldState;
  station_id: ComboBoxState<ComboBoxOption>;
};

export const RegisterForm = ({
  countryCode,
  onSubmit,
  refId,
  onError,
  onSuccess,
  parkInfo,
  bottomSheetContent,
}: {
  countryCode?: CountryCallingCode;
  refId: string;
  onError: (code?: string) => void;
  onSuccess: () => void;
  onSubmit: () => void;
  parkInfo: ParkInfoData;
  bottomSheetContent?: ReactNode;
}) => {
  const message = useMessage();
  const logActionSubmit = useLogAction("submit");
  const logActionInvalid = useLogAction("invalid");
  const locale = useLocale();
  const errorMessages = useErrorMessages();
  const typo = useTypo();
  const uiColors = useUiColors();
  const flag = useFlag();

  const { submissionId, updateSubmissionId } = useOperationIdContext();

  const enablePhoneVerification = parkInfo.phone_verification;

  const enableTestPhones = flag({
    id: "aa814bf7-a087-4378-934f-9374140551cf",
    description: "Enable test phones for testing",
  });

  const additionalFieldsMap = {
    ...parkInfo.optional_fields?.reduce<Record<string, { required: boolean }>>(
      (res, item) => ({
        ...res,
        [item]: { required: false },
      }),
      {},
    ),
    ...parkInfo.required_fields?.reduce<Record<string, { required: boolean }>>(
      (res, item) => ({
        ...res,
        [item]: { required: true },
      }),
      {},
    ),
  };

  const taxSystemOptions = [
    {
      key: "USN",
      label: message({
        id: "5e4f8e56-aae2-425d-88c6-9be0aabbeef4",
        context:
          "Drivers. Drivers form. Details group. Tax system option (USN)",
        default: "Simplified tax system (USN)",
      }),
    },
    {
      key: "USNP",
      label: message({
        id: "16bdb9c2-a0c4-44a3-b1a3-2fd79f317fde",
        context:
          "Drivers. Drivers form. Details group. Tax system option (USNP)",
        default: "Patent tax system (USN)",
      }),
    },
    {
      key: "OSNP",
      label: message({
        id: "d822b300-8d63-4a83-8d86-b01b50c15261",
        context:
          "Drivers. Drivers form. Details group. Tax system option (OSNP)",
        default: "Patent tax system (OSN)",
      }),
    },
    {
      key: "AUSN",
      label: message({
        id: "68f2847d-5bba-4178-9245-46ab48e4002e",
        context:
          "Drivers. Drivers form. Details group. Tax system option (AUTOUSN)",
        default: "Automated simplified tax system (AUSN)",
      }),
    },
    {
      key: "OSN",
      label: message({
        id: "4efe1cba-c103-4005-8693-b8e97932dbf3",
        context:
          "Drivers. Drivers form. Details group. Tax system option (OSN)",
        default: "General tax system (OSN)",
      }),
    },
  ];

  const [formState, setFormState] = useState<RegisterFormState>(() => {
    return {
      phone: createPhoneFieldState({
        value: countryCode ? "+" + countryCode : "",
        errorMessage: countryCode
          ? errorMessages.invalidPhone
          : errorMessages.requiredField,
      }),
      first_name: createTextFieldState({
        value: "",
        errorMessage: errorMessages.requiredField,
      }),
      last_name: createTextFieldState({
        value: "",
        errorMessage: errorMessages.requiredField,
      }),
      address: createComboBoxState({
        errorMessage: additionalFieldsMap["address"]?.required
          ? errorMessages.requiredField
          : undefined,
      }),
      address_apartment: createTextFieldState({
        value: "",
        errorMessage: additionalFieldsMap["address_apartment"]?.required
          ? errorMessages.requiredField
          : undefined,
      }),
      doc_address: createComboBoxState({
        errorMessage: additionalFieldsMap["doc_address"]?.required
          ? errorMessages.requiredField
          : undefined,
      }),
      tax_identification_number: createTextFieldState({
        value: "",
        errorMessage: additionalFieldsMap["tax_identification_number"]?.required
          ? errorMessages.requiredField
          : undefined,
      }),
      tax_system: createComboBoxState({
        errorMessage: additionalFieldsMap["tax_system"]
          ? errorMessages.requiredField
          : undefined,
      }),
      email: createTextFieldState({
        value: "",
        errorMessage: additionalFieldsMap["email"]?.required
          ? errorMessages.requiredField
          : undefined,
      }),
      tax_authority_code: createTextFieldState({
        value: "",
        errorMessage: additionalFieldsMap["tax_authority_code"]?.required
          ? errorMessages.requiredField
          : undefined,
      }),
      station_id: createComboBoxState({
        errorMessage: additionalFieldsMap["station_id"]
          ? errorMessages.requiredField
          : undefined,
      }),
    };
  });
  const [confirmationId, setConfirmationId] = useState("");

  const debouncedStationState = useDebouncedState(formState.station_id, 300);

  const { data: stationData } = useV1StationsGet(
    {
      query: {
        ref_id: refId,
        ...(debouncedStationState.inputValue && {
          text_query: debouncedStationState.inputValue,
        }),
      },
      headers: {
        language: locale.toString(),
      },
    },
    {
      enabled: !!additionalFieldsMap["station_id"],
    },
  );

  const stationOptions =
    stationData?.data.stations.map((item) => ({
      key: item.id,
      label: item.taxi_station_name,
    })) ?? [];

  const onChangePartial = useOnChangePartial(setFormState);
  const onShowErrors = useOnShowErrors(setFormState);

  const { mutate: submit, isLoading } = useV1PersonalSubmitPost({
    onSuccess(data) {
      if (data.ok) {
        onSuccess();
        onSubmit();
        logActionSubmit("submit_form");
      } else {
        updateSubmissionId();

        switch (data.error.code) {
          case "invalid_phone_number": {
            logActionInvalid("invalid_phone_number", {
              onlyYM: true,
            });
            onChangePartial({
              phone: {
                ...formState.phone,
                errorMessage: errorMessages.invalidPhone,
                errorVisible: true,
              },
            });
            break;
          }
          default: {
            onError(data.error.code);
            break;
          }
        }
      }
    },
    onError: () => {
      updateSubmissionId();
      onError();
    },
  });

  const { validateTextField, validateComboboxField, validatePhoneField } =
    useValidate();

  const linkStyles = {
    color: uiColors.text,
    ...cssFns.border({ radius: "6px" }),
    ...typo({
      level: "caption1",
      weight: "regular",
      density: "tight",
    }),
    outlineStyle: "none",
    cursor: "pointer",
  };

  const optionalFieldDescription = message({
    id: "a7fa8cad-6a0e-468e-8dab-1330d779eb7c",
    context: "Yango forms. Optional field description",
    default: "Optional",
  });

  const selfemployedLinks = [
    ...(parkInfo.link_to_selfemployed_app?.android_app
      ? [
          {
            text: message({
              id: "d5c0f99b-b29b-4486-a4ea-7cf529b376b8",
              context:
                "Register form. Self employed banner. Download button. Android",
              default: "Android",
            }),
            href: parkInfo.link_to_selfemployed_app.android_app,
          },
        ]
      : []),
    ...(parkInfo.link_to_selfemployed_app?.ios_app
      ? [
          {
            text: message({
              id: "806b6984-51c9-45cd-892d-e4430627c046",
              context:
                "Register form. Self employed banner. Download button. IOS",
              default: "iOS",
            }),
            href: parkInfo.link_to_selfemployed_app.ios_app,
          },
        ]
      : []),
  ];
  const entrepreneurLinks = parkInfo.link_to_individual_entrepreneur_guide
    ? [
        {
          text: message({
            id: "9e146d4b-c0fe-4bfc-b613-5b23991f1145",
            context: "Register form. Individual entrepreneur banner. Button",
            default: "How to register as an individual entrepreneur",
          }),
          href: parkInfo.link_to_individual_entrepreneur_guide,
        },
      ]
    : [];

  return (
    <ExperimentalForm
      main={
        <>
          {parkInfo.employment_banner_text ? (
            <Banner
              title={parkInfo.employment_banner_text}
              linkButtons={
                parkInfo.employment_type === "selfemployed"
                  ? selfemployedLinks
                  : entrepreneurLinks
              }
            />
          ) : null}
          <ExperimentalFormRow columnsCount={enablePhoneVerification ? 2 : 1}>
            <PhoneField
              required
              label={message({
                id: "41652579-a5a6-45f4-a3ce-2e9ca03078a3",
                context: "Yango forms. Phone number field",
                default: "Phone number",
              })}
              state={formState.phone}
              onChange={(state) => {
                setConfirmationId("");
                const errorMessage = validatePhoneField(state.value, {
                  isPhoneInvalid: enableTestPhones
                    ? state.invalid && !state.value.match(/^\+7000[0-9]{7}$/)
                    : state.invalid,
                });
                onChangePartial({
                  phone: {
                    ...state,
                    errorMessage,
                  },
                });
              }}
            />
            {enablePhoneVerification && (
              <PhoneVerificator
                phone={
                  !formState.phone.invalid && !formState.phone.errorMessage
                    ? formState.phone.value
                    : ""
                }
                state={confirmationId}
                onChange={setConfirmationId}
              />
            )}
          </ExperimentalFormRow>
          <ExperimentalFormRow columnsCount={2}>
            <TextField
              required
              state={formState.first_name}
              label={message({
                id: "e0389ece-798a-4f9f-8182-7a8597fc43c5",
                context: "Yango form. Name field",
                default: "First name",
              })}
              onChange={(state) => {
                const errorMessage = validateTextField(state.value);
                onChangePartial({
                  first_name: {
                    ...state,
                    errorMessage,
                    errorVisible: !!errorMessage,
                  },
                });
              }}
            />
            <TextField
              required
              state={formState.last_name}
              label={message({
                id: "2300180e-4699-4262-9b17-1f17972df3c3",
                context: "Yango forms. Surname field",
                default: "Last name",
              })}
              onChange={(state) => {
                const errorMessage = validateTextField(state.value);
                onChangePartial({
                  last_name: {
                    ...state,
                    errorMessage,
                    errorVisible: !!errorMessage,
                  },
                });
              }}
            />
          </ExperimentalFormRow>
          {Object.keys(additionalFieldsMap).length > 0 &&
            !additionalFieldsMap["station_id"] && (
              <span
                css={{
                  ...typo({
                    level: "body2",
                    weight: "medium",
                    density: "tight",
                  }),
                  ...cssFns.padding("0", "16px"),
                }}
              >
                {message({
                  id: "7b6abcd3-badc-4fab-a813-5f9069d053a6",
                  context: "Yango forms. Additional fields title",
                  default: "Required to get receipts ",
                })}
              </span>
            )}
          {additionalFieldsMap["address"] && (
            <ExperimentalFormRow columnsCount={2}>
              <AddressSuggest
                refId={refId}
                phone={formState.phone.value}
                disabled={!confirmationId}
                required={additionalFieldsMap["address"].required}
                label={message({
                  id: "94047075-d953-4a2b-a903-0c9e79e17f42",
                  context: "Yango forms. Actual adress field",
                  default: "Current address",
                })}
                description={
                  additionalFieldsMap["address"].required
                    ? undefined
                    : optionalFieldDescription
                }
                confirmationId={confirmationId}
                state={formState.address}
                onChange={(state) => {
                  const errorMessage = additionalFieldsMap["address"]?.required
                    ? validateComboboxField(state)
                    : undefined;

                  onChangePartial({
                    address: {
                      ...state,
                      errorMessage,
                      errorVisible: Boolean(errorMessage),
                    },
                  });
                }}
              />
              <TextField
                required={additionalFieldsMap["address_apartment"]?.required}
                state={formState.address_apartment}
                disabled={!confirmationId}
                label={message({
                  id: "959b4af7-bdf6-46a7-9608-bd8985e299e4",
                  context: "Yango forms. Actual adress apartment field",
                  default: "Apartment",
                })}
                description={
                  additionalFieldsMap["address_apartment"]?.required
                    ? undefined
                    : optionalFieldDescription
                }
                onChange={(state) => {
                  onChangePartial({
                    address_apartment: {
                      ...state,
                    },
                  });
                }}
              />
            </ExperimentalFormRow>
          )}
          {additionalFieldsMap["doc_address"] && (
            <ExperimentalFormRow columnsCount={2}>
              <AddressSuggest
                refId={refId}
                phone={formState.phone.value}
                confirmationId={confirmationId}
                disabled={!confirmationId}
                required={additionalFieldsMap["doc_address"].required}
                label={message({
                  id: "8eaea192-11ce-46a6-997c-5bc23ee8b4c4",
                  context: "Yango forms. Registration adress field",
                  default: "Registration address",
                })}
                description={
                  additionalFieldsMap["doc_address"].required
                    ? undefined
                    : optionalFieldDescription
                }
                state={formState.doc_address}
                onChange={(state) => {
                  const errorMessage = additionalFieldsMap["doc_address"]
                    ?.required
                    ? validateComboboxField(state)
                    : undefined;

                  onChangePartial({
                    doc_address: {
                      ...state,
                      errorMessage,
                      errorVisible: Boolean(errorMessage),
                    },
                  });
                }}
              />
            </ExperimentalFormRow>
          )}
          {(additionalFieldsMap["tax_identification_number"] ||
            additionalFieldsMap["tax_system"]) && (
            <ExperimentalFormRow columnsCount={2}>
              {additionalFieldsMap["tax_identification_number"] && (
                <TextField
                  required={
                    additionalFieldsMap["tax_identification_number"].required
                  }
                  state={formState.tax_identification_number}
                  label={message({
                    id: "5a130c9e-e1be-48bc-a8a5-41e0eccdabaf",
                    context: "Yango forms. Taxpayer ID field",
                    default: "INN",
                  })}
                  description={
                    additionalFieldsMap["tax_identification_number"].required
                      ? undefined
                      : optionalFieldDescription
                  }
                  onChange={(state) => {
                    const errorMessage = additionalFieldsMap[
                      "tax_identification_number"
                    ]?.required
                      ? validateTextField(state.value)
                      : undefined;
                    onChangePartial({
                      tax_identification_number: {
                        ...state,
                        errorMessage,
                        errorVisible: !!errorMessage,
                      },
                    });
                  }}
                />
              )}
              {additionalFieldsMap["tax_system"] && (
                <FormSuggest
                  required={additionalFieldsMap["tax_system"].required}
                  data={taxSystemOptions}
                  label={message({
                    id: "827c5a07-baa0-4391-bccf-02afbed77162",
                    context: "Yango forms. Tax system field",
                    default: "Tax system",
                  })}
                  description={
                    additionalFieldsMap["tax_system"].required
                      ? undefined
                      : optionalFieldDescription
                  }
                  state={formState.tax_system}
                  onChange={(state) => {
                    const errorMessage = additionalFieldsMap["tax_system"]
                      ?.required
                      ? validateComboboxField(state)
                      : undefined;
                    onChangePartial({
                      tax_system: {
                        ...state,
                        errorMessage,
                        errorVisible: Boolean(errorMessage),
                      },
                    });
                  }}
                />
              )}
            </ExperimentalFormRow>
          )}
          {(additionalFieldsMap["email"] ||
            additionalFieldsMap["tax_authority_code"] ||
            additionalFieldsMap["station_id"]) && (
            <ExperimentalFormRow columnsCount={2}>
              {additionalFieldsMap["email"] && (
                <TextField
                  required={additionalFieldsMap["email"].required}
                  state={formState.email}
                  label={message({
                    id: "fdd2e68c-cfc5-4de0-bcdf-c71369d22eb3",
                    context: "Yango forms. Email field",
                    default: "Email",
                  })}
                  description={
                    additionalFieldsMap["email"].required
                      ? undefined
                      : optionalFieldDescription
                  }
                  onChange={(state) => {
                    const errorMessage = additionalFieldsMap["email"]?.required
                      ? validateTextField(state.value)
                      : undefined;
                    onChangePartial({
                      email: {
                        ...state,
                        errorMessage,
                        errorVisible: !!errorMessage,
                      },
                    });
                  }}
                />
              )}
              {additionalFieldsMap["tax_authority_code"] && (
                <TextField
                  required={additionalFieldsMap["tax_authority_code"].required}
                  state={formState.tax_authority_code}
                  label={message({
                    id: "617734a2-666e-462a-a9ea-f598a06a6300",
                    context: "Yango forms. Tax authority code field",
                    default: "Tax authority code",
                  })}
                  description={
                    additionalFieldsMap["tax_authority_code"].required
                      ? undefined
                      : optionalFieldDescription
                  }
                  onChange={(state) => {
                    const errorMessage = additionalFieldsMap[
                      "tax_authority_code"
                    ]?.required
                      ? validateTextField(state.value)
                      : undefined;
                    onChangePartial({
                      tax_authority_code: {
                        ...state,
                        errorMessage,
                        errorVisible: !!errorMessage,
                      },
                    });
                  }}
                />
              )}
              {additionalFieldsMap["station_id"] && (
                <FormSuggest
                  required={additionalFieldsMap["station_id"].required}
                  data={stationOptions}
                  label={message({
                    id: "9041397d-baa5-4884-84ca-9880ff4bd19a",
                    context: "Yango forms. Station field",
                    default: "Taxi station",
                  })}
                  description={
                    additionalFieldsMap["station_id"].required
                      ? undefined
                      : optionalFieldDescription
                  }
                  state={formState.station_id}
                  onChange={(state) => {
                    const errorMessage = additionalFieldsMap["station_id"]
                      ?.required
                      ? validateComboboxField(state)
                      : undefined;
                    onChangePartial({
                      station_id: {
                        ...state,
                        errorMessage,
                        errorVisible: Boolean(errorMessage),
                      },
                    });
                  }}
                />
              )}
            </ExperimentalFormRow>
          )}
          <SubmitBottomSheet
            text={message({
              id: "ca0dfe03-7215-463e-8f07-d1db5c04a302",
              context: "Yango forms. Submit button text",
              default: "Register",
            })}
            isLoading={isLoading}
          >
            {bottomSheetContent ?? (
              <Message
                id="3de1d8df-aebb-4759-98bb-362e5044bc37"
                context="Yango forms. Agree processing data. Title"
                default="By submitting this form, I accept the <terms_of_use>Terms Of Use</terms_of_use>, agree to the processing of my personal data for the purpose of reviewing my application, and confirm that I have read the <privacy_notice>Privacy Notice</privacy_notice>."
                __experimental_tags={{
                  terms_of_use: (chunks: ReactNode) => (
                    <a
                      target="_blank"
                      href={parkInfo.legal_docs_urls.url_terms_of_use}
                      css={linkStyles}
                      key="terms_of_use"
                    >
                      {chunks}
                    </a>
                  ),
                  privacy_notice: (chunks: ReactNode) => (
                    <a
                      target="_blank"
                      href={parkInfo.legal_docs_urls.url_privacy_policy}
                      css={linkStyles}
                      key="privacy_notice"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            )}
          </SubmitBottomSheet>
        </>
      }
      onSubmit={() => {
        onShowErrors();
        const hasErrors = Object.keys(formState).some(
          (field) =>
            formState[field as keyof RegisterFormState].errorMessage !==
            undefined,
        );

        if (hasErrors) {
          if (formState.phone.errorMessage === errorMessages.invalidPhone) {
            logActionInvalid("invalid_phone_number", {
              onlyYM: true,
            });
          }

          return;
        }

        submit({
          headers: {
            "x-submission-id": submissionId,
            language: locale.toString(),
            "content-type": "application/json",
          },
          body: {
            first_name: formState.first_name.value,
            last_name: formState.last_name.value,
            phone: formState.phone.value,
            ref_id: refId,
            agree_processing_data: true,
            agree_receive_info: true,
            address: formState.address.value?.key,
            address_apartment: sendValueOrUndefined(
              formState.address_apartment.value,
            ),
            doc_address: formState.doc_address.value?.key,
            tax_identification_number: sendValueOrUndefined(
              formState.tax_identification_number.value,
            ),
            tax_system: formState.tax_system.value?.key,
            tax_authority_code: sendValueOrUndefined(
              formState.tax_authority_code.value,
            ),
            email: sendValueOrUndefined(formState.email.value),
            confirmation_id: sendValueOrUndefined(confirmationId),
            station_id: formState.station_id.value?.key,
          },
        });
      }}
    />
  );
};

const sendValueOrUndefined = (value: string) => {
  return value.length > 0 ? value : undefined;
};
