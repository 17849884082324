/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { createContext, useContext, useMemo, type ReactNode } from "react";

type UiOptionsValue = {
  /** Options for enabling and disabling experimental or testing features */
  experimental?: {
    /**
     * If enabled, row with scroll check scroll position when child or subtree mutate
     */
    checkResizeInnerContainer?: boolean;

    /**
     * If enabled, label includes a description
     */
    enableNewGeoSuggestsLabelHandler?: boolean;

    /**
     * If enabled, fleet popover will be rendered inside popover container
     */
    renderFleetPopoversInsidePopoverContainer?: boolean;

    /**
     * If enabled, activate handler current day in line chart
     */
    enableHandlerCurrentDayLineChart?: boolean;
  };
};

const defaultValue: UiOptionsValue = {
  experimental: {},
};

const context = createContext<UiOptionsValue>(defaultValue);

/**
 * Allows to set options for all components from `@fleet/ui`
 */
export const UiOptions = ({
  value,
  children,
}: {
  value: UiOptionsValue;
  children: ReactNode;
}) => {
  const nextValue = useMemo(() => {
    return {
      ...defaultValue,
      ...value,
      experimental: {
        ...defaultValue.experimental,
        ...value.experimental,
      },
    };
  }, [value]);

  return <context.Provider value={nextValue}>{children}</context.Provider>;
};
/**
 * @internal
 * Hook for get options
 */
export const useUiOptions = () => {
  return useContext(context);
};
