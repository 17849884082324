/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  useCallback,
  useState,
  type ReactNode,
  createContext,
  useContext,
  useLayoutEffect,
} from "react";

import { cssFns, useKeyframes } from "@superweb/css";
import { useLocale } from "@superweb/intl";
import { Button, icons } from "@superweb/ui";

import { useMessage } from "#intl";
import { useGuidanceColors } from "./colors/guidance";

type Story = {
  duration: number;
  content: ReactNode;
};

const Context = createContext<{
  stop: () => void;
  play: () => void;
  next: () => void;
  prev: () => void;
  currentId: number;
  isPaused: boolean;
  stories: Story[];
}>({
  stop: () => {},
  play: () => {},
  next: () => {},
  prev: () => {},
  currentId: 0,
  isPaused: false,
  stories: [],
});

const StoryProgressBar = ({
  onEnd,
  duration,
  isActive,
  isFinished,
  isPaused,
}: {
  duration: number;
  isActive: boolean;
  isFinished: boolean;
  onEnd: () => void;
  isPaused?: boolean;
}) => {
  const guidanceColors = useGuidanceColors();
  const { textInfo } = useLocale();
  const keyframes = useKeyframes();

  return (
    <div
      css={{
        backgroundColor: cssFns.setOpacity(guidanceColors.text, 0.1),
        overflowY: "hidden",
        height: "4px",
        ...cssFns.border({ radius: "22px" }),
      }}
    >
      <div
        css={{
          backgroundColor: guidanceColors.text,
          maxWidth: "0",
          height: "100%",
          transformOrigin: `center ${
            textInfo.direction === "rtl" ? "right" : "left"
          }`,
          ...(isFinished && {
            maxWidth: "100%",
          }),
          ...(isActive && {
            maxWidth: "100%",
            animationName: keyframes({
              0: { transform: "scaleX(0)" },
              100: { transform: "scaleX(1)" },
            }),
            animationDuration: `${duration}ms`,
            animationTimingFunction: "linear",
            animationPlayState: isPaused ? "paused" : "running",
          }),
        }}
        onAnimationEnd={onEnd}
      />
    </div>
  );
};

const StoryProgressBars = ({
  onStoryStart,
  onStoryEnd,
}: {
  onStoryStart?: (index: number) => void;
  onStoryEnd?: (index: number) => void;
}) => {
  const { next, currentId, stories, isPaused } = useContext(Context);
  const duration = stories[currentId]?.duration;

  useLayoutEffect(() => {
    onStoryStart?.(currentId);
  }, [currentId, onStoryStart]);

  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: `repeat(${stories.length}, 1fr)`,
        columnGap: "8px",
        boxSizing: "border-box",
      }}
    >
      {stories.map((_, index) => (
        <StoryProgressBar
          key={index}
          duration={duration || Number.MAX_SAFE_INTEGER}
          isFinished={currentId > index}
          isActive={currentId === index}
          isPaused={isPaused}
          onEnd={() => {
            onStoryEnd?.(currentId);
            next();
          }}
        />
      ))}
    </div>
  );
};

const IconCross = (props: { className?: string }) => {
  const guidanceColors = useGuidanceColors();
  return <icons.CrossL color={guidanceColors.text} {...props} />;
};

export const useStories = () => {
  return useContext(Context);
};

export const Stories = ({
  stories,
  loop,
  narrow,
  paused = false,
  onStoryStart,
  onStoryEnd,
  onClose,
}: {
  stories: Story[];
  loop?: boolean;
  narrow?: boolean;
  paused?: boolean;
  onStoryStart?: (index: number) => void;
  onStoryEnd?: (index: number) => void;
  onClose?: (index: number) => void;
}) => {
  const [currentId, setCurrentId] = useState(0);
  const [isPaused, setIsPaused] = useState(paused);
  const message = useMessage();

  const next = useCallback(() => {
    setCurrentId((currentId) =>
      currentId >= stories.length - 1 ? (loop ? 0 : currentId) : currentId + 1,
    );
  }, [stories, loop]);

  const prev = useCallback(() => {
    setCurrentId((currentId) => (currentId === 0 ? currentId : currentId - 1));
  }, []);

  const stop = useCallback(() => setIsPaused(true), []);

  const play = useCallback(() => setIsPaused(false), []);

  const getOrder = (index: number, pos: number, itemsCount: number) => {
    return index - pos < 0 ? itemsCount + pos - index : index - pos;
  };

  return (
    <Context.Provider
      value={{
        next,
        prev,
        play,
        stop,
        currentId,
        isPaused,
        stories,
      }}
    >
      <div css={{ position: "relative" }}>
        <div
          css={{
            display: "grid",
            alignItems: "start",
          }}
        >
          <ul
            css={{
              width: "100%",
              height: "100%",
              listStyleType: "none",
              position: "relative",
              display: "flex",
              ...cssFns.margin("0px"),
              ...cssFns.padding("0px"),
              overflowX: "hidden",
              outlineStyle: "none",
            }}
          >
            {stories.map((item, index) => (
              <li
                key={index}
                css={{
                  order: getOrder(index, currentId, stories.length).toString(),
                  width: "100%",
                  visibility: currentId !== index ? "hidden" : "visible",
                  ...cssFns.flex({ grow: "0", shrink: "0", basis: "100%" }),
                }}
                aria-hidden={index !== currentId}
              >
                {item.content}
              </li>
            ))}
          </ul>
        </div>
        <div
          css={{
            display: "grid",
            position: "absolute",
            gridTemplateColumns: narrow ? "1fr auto" : undefined,
            alignItems: "center",
            width: "100%",
            top: "0px",
            left: "0px",
            boxSizing: "border-box",
            ...cssFns.padding(narrow ? "16px" : "32px"),
          }}
        >
          <StoryProgressBars
            onStoryStart={onStoryStart}
            onStoryEnd={onStoryEnd}
          />
          {onClose && (
            <div
              css={{
                marginBlockStart: narrow ? undefined : "12px",
                marginInlineStart: "6px",
                marginInlineEnd: narrow ? "-10px" : "-12px",
                justifySelf: "flex-end",
              }}
            >
              <Button
                onPress={() => onClose(currentId)}
                ariaLabel={message({
                  id: "a9a9e9b1-9074-4cb9-8024-913ee9191947",
                  context: "Stories. Close button label",
                  default: "Close",
                })}
                icon={IconCross}
                view="ghost"
                size="s"
              />
            </div>
          )}
        </div>
      </div>
    </Context.Provider>
  );
};
