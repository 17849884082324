// https://a.yandex-team.ru/arcadia/taxi/uservices/services/fleet-forms/docs/yaml/api/external.yaml

import {
  useMutation,
  useQuery,
  type UseMutationOptions,
  type UseQueryOptions,
} from "@superweb/api";

import type * as api from "../../generated/fleet-forms";
import { apiFetch } from "../../fetch";

type UseV1ParkInfoGetRequest =
  api.paths["/fleet-forms/selfreg/v1/park/info"]["GET"]["request"];
type UseV1ParkInfoGetResponse =
  api.paths["/fleet-forms/selfreg/v1/park/info"]["GET"]["response"];
export type UseV1ParkInfoGetResult =
  | {
      status: 200;
      data: Extract<UseV1ParkInfoGetResponse, { status: 200 }>["body"];
    }
  | {
      status: 400;
      error: Extract<UseV1ParkInfoGetResponse, { status: 400 }>["body"];
    }
  | {
      status: 410;
      data: Extract<
        UseV1ParkInfoGetResponse,
        { status: 410 }
      >["body"]["park_info"];
    };

export type UseV1ParkInfoGetParams = Omit<
  UseV1ParkInfoGetRequest,
  "url" | "method"
>;

export type UseV1ParkInfoGetOptions = UseQueryOptions<UseV1ParkInfoGetResult>;

export const useV1ParkInfoGet = (
  { query, headers }: UseV1ParkInfoGetParams,
  options?: UseV1ParkInfoGetOptions,
) => {
  const searchParams = new URLSearchParams(query);
  const url = "/api/fleet-forms/selfreg/v1/park/info";

  return useQuery(
    [url, query, headers],
    () => {
      return apiFetch(
        new Request(`${url}?${searchParams.toString()}`, {
          method: "GET",
          headers,
        }),
        {
          200: async (r) => ({
            status: 200 as const,
            data: (await r.json()) as Extract<
              UseV1ParkInfoGetResponse,
              { status: 200 }
            >["body"],
          }),
          400: async (r) => ({
            status: 400 as const,
            error: (await r.json()) as Extract<
              UseV1ParkInfoGetResponse,
              { status: 400 }
            >["body"],
          }),
          410: async (r) => {
            const response = (await r.json()) as Extract<
              UseV1ParkInfoGetResponse,
              { status: 410 }
            >["body"];
            return {
              status: 410 as const,
              data: response.park_info,
            };
          },
        },
      );
    },
    options,
  );
};

type UseV1CarBrandsGetRequest =
  api.paths["/fleet-forms/selfreg/v1/car/brands"]["GET"]["request"];
type UseV1CarBrandsGetResponse =
  api.paths["/fleet-forms/selfreg/v1/car/brands"]["GET"]["response"];
export type UseV1CarBrandsGetResult = {
  ok: true;
  data: Extract<UseV1CarBrandsGetResponse, { status: 200 }>["body"];
};

export type UseV1CarBrandsGetParams = Omit<
  UseV1CarBrandsGetRequest,
  "url" | "method"
>;

export type UseV1CarBrandsGetOptions = UseQueryOptions<UseV1CarBrandsGetResult>;

export const useV1CarBrandsGet = (
  { query, headers }: UseV1CarBrandsGetParams,
  options?: UseV1CarBrandsGetOptions,
) => {
  const searchParams = new URLSearchParams(query);
  const url = "/api/fleet-forms/selfreg/v1/car/brands";

  return useQuery(
    [url, query, headers],
    () => {
      return apiFetch(
        new Request(`${url}?${searchParams.toString()}`, {
          method: "GET",
          headers,
        }),
        {
          200: async (r) => ({
            ok: true as const,
            data: (await r.json()) as Extract<
              UseV1CarBrandsGetResponse,
              { status: 200 }
            >["body"],
          }),
        },
      );
    },
    options,
  );
};

type UseV1CarModelsGetRequest =
  api.paths["/fleet-forms/selfreg/v1/car/models"]["GET"]["request"];
type UseV1CarModelsGetResponse =
  api.paths["/fleet-forms/selfreg/v1/car/models"]["GET"]["response"];
export type UseV1CarModelsGetResult =
  | {
      ok: true;
      data: Extract<UseV1CarModelsGetResponse, { status: 200 }>["body"];
    }
  | {
      ok: false;
      error: Extract<UseV1CarModelsGetResponse, { status: 404 }>["body"];
    };

export type UseV1CarModelsGetParams = Omit<
  UseV1CarModelsGetRequest,
  "url" | "method"
>;

export type UseV1CarModelsGetOptions = UseQueryOptions<UseV1CarModelsGetResult>;

export const useV1CarModelsGet = (
  { query, headers }: UseV1CarModelsGetParams,
  options?: UseV1CarModelsGetOptions,
) => {
  const searchParams = new URLSearchParams(query);
  const url = "/api/fleet-forms/selfreg/v1/car/models";

  return useQuery(
    [url, query, headers],
    () => {
      return apiFetch(
        new Request(`${url}?${searchParams.toString()}`, {
          method: "GET",
          headers,
        }),
        {
          200: async (r) => ({
            ok: true as const,
            data: (await r.json()) as Extract<
              UseV1CarModelsGetResponse,
              { status: 200 }
            >["body"],
          }),
          404: async (r) => ({
            ok: false as const,
            error: (await r.json()) as Extract<
              UseV1CarModelsGetResponse,
              { status: 404 }
            >["body"],
          }),
        },
      );
    },
    options,
  );
};

type UseV1CarYearsGetRequest =
  api.paths["/fleet-forms/selfreg/v1/car/years"]["GET"]["request"];
type UseV1CarYearsGetResponse =
  api.paths["/fleet-forms/selfreg/v1/car/years"]["GET"]["response"];
export type UseV1CarYearsGetResult = {
  ok: true;
  data: Extract<UseV1CarYearsGetResponse, { status: 200 }>["body"];
};

export type UseV1CarYearsGetParams = Omit<
  UseV1CarYearsGetRequest,
  "url" | "method"
>;

export type UseV1CarYearsGetOptions = UseQueryOptions<UseV1CarYearsGetResult>;

export const useV1CarYearsGet = (
  { query, headers }: UseV1CarYearsGetParams,
  options?: UseV1CarYearsGetOptions,
) => {
  const searchParams = new URLSearchParams(query);
  const url = "/api/fleet-forms/selfreg/v1/car/years";

  return useQuery(
    [url, query, headers],
    () => {
      return apiFetch(
        new Request(`${url}?${searchParams.toString()}`, {
          method: "GET",
          headers,
        }),
        {
          200: async (r) => ({
            ok: true as const,
            data: (await r.json()) as Extract<
              UseV1CarYearsGetResponse,
              { status: 200 }
            >["body"],
          }),
        },
      );
    },
    options,
  );
};

type UseV1CarColorsGetRequest =
  api.paths["/fleet-forms/selfreg/v1/car/colors"]["GET"]["request"];
type UseV1CarColorsGetResponse =
  api.paths["/fleet-forms/selfreg/v1/car/colors"]["GET"]["response"];
export type UseV1CarColorsGetResult = {
  ok: true;
  data: Extract<UseV1CarColorsGetResponse, { status: 200 }>["body"];
};

export type UseV1CarColorsGetParams = Omit<
  UseV1CarColorsGetRequest,
  "url" | "method"
>;

export type UseV1CarColorsGetOptions = UseQueryOptions<UseV1CarColorsGetResult>;

export const useV1CarColorsGet = (
  { query, headers }: UseV1CarColorsGetParams,
  options?: UseV1CarColorsGetOptions,
) => {
  const searchParams = new URLSearchParams(query);
  const url = "/api/fleet-forms/selfreg/v1/car/colors";

  return useQuery(
    [url, query, headers],
    () => {
      return apiFetch(
        new Request(`${url}?${searchParams.toString()}`, {
          method: "GET",
          headers,
        }),
        {
          200: async (r) => ({
            ok: true as const,
            data: (await r.json()) as Extract<
              UseV1CarColorsGetResponse,
              { status: 200 }
            >["body"],
          }),
        },
      );
    },
    options,
  );
};

type UseV1StationsGetRequest =
  api.paths["/fleet-forms/selfreg/v1/stations"]["GET"]["request"];
type UseV1StationsGetResponse =
  api.paths["/fleet-forms/selfreg/v1/stations"]["GET"]["response"];
export type UseV1StationsGetResult = {
  data: Extract<UseV1StationsGetResponse, { status: 200 }>["body"];
};

export type UseV1StationsGetParams = Omit<
  UseV1StationsGetRequest,
  "url" | "method"
>;

export type UseV1StationsGetOptions = UseQueryOptions<UseV1StationsGetResult>;

export const useV1StationsGet = (
  { query, headers }: UseV1StationsGetParams,
  options?: UseV1StationsGetOptions,
) => {
  const searchParams = new URLSearchParams(query);
  const url = "/api/fleet-forms/selfreg/v1/stations";

  return useQuery(
    [url, query, headers],
    () => {
      return apiFetch(
        new Request(`${url}?${searchParams.toString()}`, {
          method: "GET",
          headers,
        }),
        {
          200: async (r) => ({
            data: (await r.json()) as Extract<
              UseV1StationsGetResponse,
              { status: 200 }
            >["body"],
          }),
        },
      );
    },
    options,
  );
};

type UseV1PhoneSubmitPostRequest =
  api.paths["/fleet-forms/selfreg/v1/phone/submit"]["POST"]["request"];
type UseV1PhoneSubmitPostResponse =
  api.paths["/fleet-forms/selfreg/v1/phone/submit"]["POST"]["response"];
export type UseV1PhoneSubmitPostResult =
  | {
      ok: true;
      data: Extract<UseV1PhoneSubmitPostResponse, { status: 200 }>["body"];
    }
  | {
      ok: false;
      error: Extract<UseV1PhoneSubmitPostResponse, { status: 400 }>["body"];
    };

export type UseV1PhoneSubmitPostParams = Omit<
  UseV1PhoneSubmitPostRequest,
  "url" | "method"
>;

export type UseV1PhoneSubmitPostOptions = UseMutationOptions<
  UseV1PhoneSubmitPostResult,
  unknown,
  UseV1PhoneSubmitPostParams
>;

export const useV1PhoneSubmitPost = (options?: UseV1PhoneSubmitPostOptions) => {
  const url = "/api/fleet-forms/selfreg/v1/phone/submit";

  return useMutation(({ headers, body }) => {
    return apiFetch(
      new Request(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      }),
      {
        200: async (r) => ({
          ok: true as const,
          data: (await r.json()) as Extract<
            UseV1PhoneSubmitPostResponse,
            { status: 200 }
          >["body"],
        }),
        400: async (r) => ({
          ok: false as const,
          error: (await r.json()) as Extract<
            UseV1PhoneSubmitPostResponse,
            { status: 400 }
          >["body"],
        }),
      },
    );
  }, options);
};

type UseV1PhoneCommitPostRequest =
  api.paths["/fleet-forms/selfreg/v1/phone/commit"]["POST"]["request"];
type UseV1PhoneCommitPostResponse =
  api.paths["/fleet-forms/selfreg/v1/phone/commit"]["POST"]["response"];
export type UseV1PhoneCommitPostResult =
  | {
      ok: true;
    }
  | {
      ok: false;
      error: Extract<UseV1PhoneCommitPostResponse, { status: 400 }>["body"];
    };

export type UseV1PhoneCommitPostParams = Omit<
  UseV1PhoneCommitPostRequest,
  "url" | "method"
>;

export type UseV1PhoneCommitPostOptions =
  UseQueryOptions<UseV1PhoneCommitPostResult>;

export const useV1PhoneCommitPost = (
  { body, query, headers }: UseV1PhoneCommitPostParams,
  options?: UseV1PhoneCommitPostOptions,
) => {
  const searchParams = new URLSearchParams(query);
  const url = "/api/fleet-forms/selfreg/v1/phone/commit";

  return useQuery(
    [url, query, body, headers],
    () => {
      return apiFetch(
        new Request(`${url}?${searchParams.toString()}`, {
          method: "POST",
          headers,
          body: JSON.stringify(body),
        }),
        {
          200: async () => ({
            ok: true as const,
          }),
          204: async () => ({
            ok: true as const,
          }),
          400: async (r) => ({
            ok: false as const,
            error: (await r.json()) as Extract<
              UseV1PhoneCommitPostResponse,
              { status: 400 }
            >["body"],
          }),
        },
      );
    },
    options,
  );
};
