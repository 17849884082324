/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { Temporal } from "@js-temporal/polyfill";
import { useEffect, useState } from "react";

import {
  Button,
  ExperimentalFormRow,
  TextField,
  usePreviousState,
  useTextFieldState,
  useTypo,
  useUiColors,
  type TextFieldState,
} from "@superweb/ui";
import { useFormat, useLocale } from "@superweb/intl";

import { useAction } from "@fleet/api";

import { useMessage } from "#internal/intl";
import { useErrorMessages } from "#internal/utils";

const useVerificationMessages = () => {
  const message = useMessage();

  return {
    enterCode: message({
      id: "b1c57106-577f-4c57-acc1-b80600718f16",
      context: "Form. Check phone code field",
      default: "Enter code",
    }),
    invalidCode: message({
      id: "2ab8a467-4d37-4ea5-b798-d4dd41e8712c",
      context: "Form. Check phone code field. Wrong code error",
      default: "Invalid code",
    }),
    getCode: message({
      id: "e020fdbe-ab99-4e6d-ba07-d2bc03a83fca",
      context: "Form. Get code button. Default state",
      default: "Get code",
    }),
    requestAgainIn: (value: string) =>
      message({
        id: "50a202ae-c97f-4ddd-83d6-c300f235d61b",
        context: "Form. Get code button. Waiting to send code again state",
        default: "Request again in {value}",
        values: { value },
      }),
    requestCodeAgain: message({
      id: "e020fdbe-ab99-4e6d-ba07-d2bc03a84fca",
      context: "Form. Get code button. Send code again state",
      default: "Request code again",
    }),
  };
};

export const PhoneVerificator = ({
  phone,
  state,
  onChange,
}: {
  phone?: string;
  state?: string;
  onChange: (verificationId: string) => void;
}) => {
  const typo = useTypo();
  const locale = useLocale();
  const message = useMessage();
  const uiColors = useUiColors();
  const { formatDuration } = useFormat();
  const errorMessages = useErrorMessages();
  const messages = useVerificationMessages();

  const prevPhone = usePreviousState(phone);
  const [codeLength, setCodeLength] = useState(6);
  const [sendCodeTimeout, setSendCodeTimeout] = useState(0);
  const [phoneVerificationId, setPhoneVerificationId] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [verificationButtonError, setVerificationButtonError] = useState("");
  const [code, setCode] = useTextFieldState();

  const { action: sendCode, isFetching: isPhoneCodeSending } = useAction({
    url: "/fleet-forms/selfreg/v1/phone/submit",
    method: "POST",
  });
  const { action: phoneCommit, isFetching: isPhoneCodeChecking } = useAction({
    url: "/fleet-forms/selfreg/v1/phone/commit",
    method: "POST",
  });

  const isPhoneVerified = Boolean(state);

  useEffect(() => {
    if (sendCodeTimeout) {
      const timer = setTimeout(() => {
        setSendCodeTimeout((prev) => prev - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
    return;
  }, [sendCodeTimeout]);

  useEffect(() => {
    if (phone === prevPhone) {
      return;
    }

    setCode({ value: "" });
    setPhoneVerificationId("");
    setIsCodeSent(false);
    onChange("");
  }, [phone, prevPhone, onChange, setCode]);

  const verifyPhone = (state: TextFieldState) =>
    phoneCommit(
      {
        body: {
          confirmation_id: phoneVerificationId,
          code: state.value,
        },
        headers: {
          "content-type": "application/json",
          language: locale.toString(),
        },
      },
      {
        onResponse: (res) => {
          if (res.status === 400) {
            const errorCode = res.body.code;
            const errorMessage =
              errorCode &&
              {
                sms_invalid_code: message({
                  id: "5168e6f8-82aa-4786-a8e6-c36a52f56436",
                  context:
                    "Fleet forms. Phone validation error. Sms invalid code",
                  default: "Incorrect code",
                }),
                sms_too_many_attempts: message({
                  id: "1839e2aa-73cb-4f80-bcb9-25800ba552f3",
                  context:
                    "Fleet forms. Phone validation error. Sms too many attempts",
                  default: "Too many attempts",
                }),
              }[errorCode];

            setCode((state) => ({
              ...state,
              errorMessage: errorMessage ?? messages.invalidCode,
              errorVisible: true,
            }));

            return;
          }

          onChange(phoneVerificationId);
          setSendCodeTimeout(0);
          setCode((state) => ({
            ...state,
            errorMessage: undefined,
            errorVisible: false,
          }));
        },
      },
    );

  return (
    <ExperimentalFormRow columnsCount={2}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          rowGap: "4px",
          justifyContent: "start",
        }}
      >
        <TextField
          disabled={
            isPhoneCodeSending ||
            !phoneVerificationId ||
            isPhoneVerified ||
            isPhoneCodeChecking
          }
          label={messages.enterCode}
          state={code}
          onChange={(state) => {
            setVerificationButtonError("");
            setCode({
              ...state,
              errorMessage:
                state.value.length === codeLength
                  ? undefined
                  : state.value.length < 1
                    ? errorMessages.requiredField
                    : messages.invalidCode,
            });

            if (state.value.length === codeLength && !state.errorVisible) {
              setTimeout(() => verifyPhone(state));
            }
          }}
        />
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          rowGap: "4px",
          justifyContent: "start",
        }}
      >
        <Button
          disabled={
            !phone ||
            isPhoneCodeSending ||
            isPhoneCodeChecking ||
            Boolean(sendCodeTimeout) ||
            isPhoneVerified
          }
          progress={isPhoneCodeSending}
          onPress={() => {
            if (!phone) {
              return;
            }

            setVerificationButtonError("");
            setCode({ value: "" });
            sendCode(
              {
                headers: {
                  "content-type": "application/json",
                  language: locale.toString(),
                },
                body: {
                  phone: phone,
                },
              },
              {
                onResponse: (response) => {
                  if (response.status === 200) {
                    setPhoneVerificationId(response.body.confirmation_id);
                    setIsCodeSent(true);
                    if (response.body.code_length)
                      setCodeLength(response.body.code_length);
                    if (response.body.deny_resend_until) {
                      const timeout = Math.ceil(
                        response.body.deny_resend_until - Date.now() / 1000,
                      );
                      setSendCodeTimeout(timeout > 0 ? timeout : 0);
                    }
                  } else {
                    setVerificationButtonError(response.body.message);
                  }
                },
              },
            );
          }}
          size="l"
          text={
            sendCodeTimeout
              ? messages.requestAgainIn(
                  formatDuration(
                    Temporal.Duration.from({
                      seconds: sendCodeTimeout,
                    }).round({
                      largestUnit: "seconds",
                      smallestUnit: "seconds",
                    }),
                  ),
                )
              : !isCodeSent
                ? messages.getCode
                : messages.requestCodeAgain
          }
        />
        {verificationButtonError ? (
          <span
            css={{
              paddingInlineStart: "16px",
              paddingInlineEnd: "16px",
              color: uiColors.statuses.danger,
              ...typo({
                level: "caption1",
                weight: "regular",
                density: "tight",
              }),
            }}
          >
            {verificationButtonError}
          </span>
        ) : null}
      </div>
    </ExperimentalFormRow>
  );
};
