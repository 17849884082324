import { useCallback } from "react";

import { useLocale } from "@superweb/intl";

import { useV1ParkInfoGet } from "@fleet/api/fleet-forms";

import { useRefId } from "#internal/router";
import { useOperationIdContext } from "#internal/ui";

import { sendGtagEvent } from "./gtag";
import { sendFBQEvent } from "./fbq";
import { sendYMHit, sendYMEvent } from "./metrika";
import { sendTTQEvent } from "./ttq";

export const useHitParams = () => {
  const refId = useRefId();
  const locale = useLocale();

  const { sessionId } = useOperationIdContext();

  const { data: parkInfo } = useV1ParkInfoGet(
    {
      headers: {
        language: locale.toString(),
        "x-session-id": sessionId,
      },
      query: { ref_id: refId },
    },
    {
      enabled: Boolean(refId),
    },
  );

  return {
    ref_id: refId,
    lang: locale.toString(),
    ...(parkInfo?.status === 200 && {
      park_brand: parkInfo.data.brand,
      channel_name: parkInfo.data.channel_name,
      channel_id: parkInfo.data.channel_id,
      park_name: parkInfo.data.partner_name,
      park_id: parkInfo.data.park_id,
    }),
  };
};

type Stage = "main" | "vehicle" | "license" | "download_app" | "archive";

type ContactActions = {
  phone_click: {
    stage: Stage;
  };
  messenger_click: { stage: Stage };
};

type SumbitActions = {
  submit_form: undefined;
  submit_vehicle: undefined;
  submit_license: undefined;
  download_app: undefined;
};

type ValidationActions = {
  invalid_phone_number: undefined;
  invalid_driver_license: undefined;
  invalid_car_number: undefined;
  invalid_issue_date: undefined;
  invalid_expiry_date: undefined;
};

type ActionsGoal = {
  submit: SumbitActions;
  invalid: ValidationActions;
  contact: ContactActions;
};

export const useLogAction = <T extends keyof ActionsGoal>(group: T) => {
  const hitParams = useHitParams();

  return useCallback(
    <A extends keyof ActionsGoal[T]>(
      action: A,
      options?: { onlyYM?: boolean; params?: ActionsGoal[T][A] },
    ) => {
      if (!options?.onlyYM) {
        sendGtagEvent(action as string, `form_${hitParams.ref_id}`);
        sendFBQEvent(action as string, `form_${hitParams.ref_id}`);
        sendTTQEvent(action as string, `form_${hitParams.ref_id}`);
      }
      sendYMEvent(action as string, group, hitParams, options?.params);
    },
    [group, hitParams],
  );
};

export const useLogHit = () => {
  const hitParams = useHitParams();

  return useCallback(
    (url: string) => {
      sendYMHit(url, hitParams);
    },
    [hitParams],
  );
};
