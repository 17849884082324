/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { createColorsContext } from "@superweb/ui";

const [PartnersLoyaltyColors, usePartnersLoyaltyColors] = createColorsContext<{
  goldStatus: string;
  silverStatus: string;
}>({
  light: {
    goldStatus: "linear-gradient(88deg, #EBC164 -0.16%, #FFE495 70.87%)",
    silverStatus: "linear-gradient(45deg, #D4D4D4 0%, #F5F5F5 100%)",
  },
  dark: {
    goldStatus: "linear-gradient(88deg, #EBC164 -0.16%, #FFE495 70.87%)",
    silverStatus: "linear-gradient(45deg, #D4D4D4 0%, #F5F5F5 100%)",
  },
});
export { PartnersLoyaltyColors, usePartnersLoyaltyColors };
