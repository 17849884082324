import {
  createContext,
  useContext,
  useMemo,
  type ReactElement,
  type ReactNode,
} from "react";
import { IntlMessageFormat } from "intl-messageformat";
import { useLocale } from "./locale";

const createMessageContext = () => {
  return createContext<
    | {
        messages: Messages | undefined;
        sourceLocale: Intl.Locale;
      }
    | undefined
  >(undefined);
};

const createMessageFn = ({
  locale,
  context,
}: {
  locale: Intl.Locale;
  context:
    | {
        messages: Messages | undefined;
        sourceLocale: Intl.Locale;
      }
    | undefined;
}) => {
  return ({
    values,
    ...params
  }: {
    id: string;
    context: string;
    default: string;
    values?: { [name: string]: unknown };
  }): string => {
    try {
      const m = context?.messages?.[params.id];
      if (m) {
        const mf = new IntlMessageFormat(m, locale.baseName);
        return mf.format(values) as string;
      }
    } catch {}

    try {
      const mf = new IntlMessageFormat(
        params.default,
        context?.sourceLocale.baseName ?? locale.baseName,
      );
      return mf.format(values) as string;
    } catch {}

    return params.default;
  };
};

/**
 * Creates isolated isolated message context that can be
 * safely mixed with other message contexts.
 *
 * ## Usage
 *
 * #### First, configure a subpath import in package.json
 *
 * ```json
 * {
 *   "imports": {
 *     "#intl": "./src/intl.ts"
 *   }
 * }
 * ```
 *
 * #### Then configure importSource in intl.json:
 *
 * ```json
 * {
 *   "importSource": "#intl"
 * }
 * ```
 *
 * #### And create corresponding module (src/intl.ts):
 *
 * ```ts
 * import { createMessages, MessageCache } from "@superweb/intl";
 *
 * const { Messages, Message, useMessage } = createMessages({
 *   cache: new MessageCache(...),
 *   sourceLanguage: "en",
 * });
 *
 * export { Messages, Message, useMessage };
 * ```
 *
 * #### Wrap your root component:
 *
 * ```tsx
 * import { Messages } from "#intl";
 *
 * export const Component = () => {
 *   return (
 *     <Messages>
 *       ...
 *     </Messages>
 *   );
 * }
 * ```
 *
 * #### Finally use it anywhere in the workspace:
 *
 * ```tsx
 * import { Message } from "#intl";
 *
 * <Message
 *   id="..."
 *   context="..."
 *   default="..."
 * />
 * ```
 */
export const createMessages = ({
  cache,
  sourceLanguage,
}: {
  cache: MessageCache | SyncMessageCache;
  sourceLanguage: string;
}): {
  Messages: (props: { children: ReactNode }) => ReactElement;
  Message: (props: {
    id: string;
    context: string;
    default: string;
    values?: { [name: string]: ReactNode };
    __experimental_tags?: {
      [name: string]: (content: ReactNode) => ReactNode;
    };
  }) => ReactElement;
  useMessage: () => (props: {
    id: string;
    context: string;
    default: string;
    values?: { [name: string]: string | number | boolean | undefined | null };
  }) => string;
} => {
  const sourceLocale = new Intl.Locale(sourceLanguage);
  const Context = createMessageContext();

  const Messages = ({ children }: { children: ReactNode }) => {
    const locale = useLocale();
    const value = useMemo(
      () => ({
        messages: cache.get(locale),
        sourceLocale: new Intl.Locale(sourceLocale),
      }),
      [locale],
    );
    return <Context.Provider value={value}>{children}</Context.Provider>;
  };

  const Message = (props: {
    id: string;
    context: string;
    default: string;
    values?: { [name: string]: ReactNode };
    __experimental_tags?: {
      [name: string]: (content: ReactNode) => ReactNode;
    };
  }) => {
    return (
      <>
        {useMessage()({
          ...props,
          values: {
            ...props.values,
            ...props.__experimental_tags,
          },
        })}
      </>
    );
  };

  const useMessage = () => {
    const locale = useLocale();
    const context = useContext(Context);
    return useMemo(
      () => createMessageFn({ locale, context }),
      [context, locale],
    );
  };

  return {
    Messages,
    Message,
    useMessage,
  };
};

type Messages = {
  [id: string]: string;
};

export class SyncMessageCache {
  // Can't use private class properties due to a problem
  // with react-docgen
  // https://st.yandex-team.ru/SUPERWEB-683
  private _key: (locale: Intl.Locale) => string;
  private _load: (key: string) => Messages | undefined;
  private _data = new Map<unknown, Messages>();

  constructor({
    key,
    load,
  }: {
    key: (locale: Intl.Locale) => string;
    load: (key: string) => Messages | undefined;
  }) {
    this._key = key;
    this._load = load;
  }

  get(locale: Intl.Locale) {
    const key = this._key(locale);
    const entry = this._data.get(key);

    if (entry) {
      return entry;
    }

    const result = this._load(key);
    if (result) {
      this._data.set(key, result);
    }

    return result;
  }
}

export class MessageCache {
  // Can't use private class properties due to a problem
  // with react-docgen
  // https://st.yandex-team.ru/SUPERWEB-683
  private _key: (locale: Intl.Locale) => string;
  private _load: (key: string) => Promise<Messages> | undefined;
  private _data = new Map<
    unknown,
    | { status: "pending"; payload: Promise<Messages> }
    | { status: "success"; payload: Messages }
    | { status: "failure"; payload: Error }
  >();

  constructor({
    key,
    load,
  }: {
    key: (locale: Intl.Locale) => string;
    load: (key: string) => Promise<Messages> | undefined;
  }) {
    this._key = key;
    this._load = load;
  }

  get(locale: Intl.Locale) {
    const entry = this._data.get(this._key(locale));
    switch (entry?.status) {
      case "pending":
        throw entry.payload.then(() => {});
      case "success":
        return entry.payload;
      case "failure":
        throw entry.payload;
      case undefined: {
        const key = this._key(locale);
        const promise = this._load(key);
        if (!promise) return undefined;
        throw promise.then(
          (result) => {
            this._data.set(key, { status: "success", payload: result });
          },
          (error) => {
            this._data.set(key, { status: "failure", payload: error });
            throw error;
          },
        );
      }
    }
  }
}
