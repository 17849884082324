/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { createContext, useContext, type ReactNode } from "react";

const context = createContext<string | undefined>(undefined);

const isMobile = (userAgent: string) =>
  /(Android|iPhone|iPad)/i.test(userAgent);

/**
 * Returns `true` if a mobile device detected based on user-agent provided by `UserAgentProvider`.
 */
export const useIsMobile = () => {
  const value = useContext(context);

  if (value === undefined && typeof navigator === "undefined") {
    throw new Error("useIsMobile is used outside of UserAgentProvider");
  }

  if (value === undefined) {
    return isMobile(navigator.userAgent);
  }

  return isMobile(value);
};

/**
 * Provide user-agent string for device detection.
 *
 * In SSR, pass it the "User-Agent" header value.
 * On the client, pass `navigator.userAgent`.
 *
 * By default, `navigator.userAgent` is used on the client.
 * In SSR, an error is thrown if there is no `UserAgentProvider`.
 */
export const UserAgentProvider = ({
  value,
  children,
}: {
  value: string;
  children: ReactNode;
}) => {
  return <context.Provider value={value}>{children}</context.Provider>;
};
