/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef } from "react";
import { mergeProps, useFocusRing, useSearchField } from "react-aria";
import { useSearchFieldState, type SearchFieldProps } from "react-stately";

import { cssFns } from "@superweb/css";
import { icons, useTypo, useUiColors } from "@superweb/ui";

export const Search = ({
  placeholder,
  value,
  onChange,
}: {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
}) => {
  const uiColors = useUiColors();
  const typo = useTypo();

  const { isFocused, focusProps } = useFocusRing({
    isTextInput: true,
    within: true,
  });

  const ariaProps: SearchFieldProps = {
    placeholder,
    autoFocus: true,
    value,
    onChange,
  };

  const ref = useRef<HTMLInputElement>(null);
  let state = useSearchFieldState(ariaProps);
  let { inputProps } = useSearchField(ariaProps, state, ref);

  return (
    <div
      css={{
        display: "inline-grid",
        gridTemplateColumns: "28px auto",
        height: "32px",
        ...cssFns.border({
          width: "2px",
          radius: "8px",
          style: "solid",
          color: isFocused ? uiColors.focus : "transparent",
        }),
        boxSizing: "border-box",
        backgroundColor: isFocused
          ? uiColors.backgroundFloating
          : uiColors.controlMinor,
      }}
    >
      <div
        css={{
          width: "28px",
          height: "28px",
          display: "grid",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <icons.Search width="16px" height="16px" />
      </div>

      <input
        {...mergeProps(inputProps, focusProps)}
        ref={ref}
        placeholder={placeholder}
        css={{
          ...cssFns.margin("0"),
          ...cssFns.border({
            width: "0",
            style: "none",
          }),
          ...cssFns.padding("0"),
          paddingInlineStart: "6px",
          ...typo({
            level: "body2",
            density: "tight",
            weight: "regular",
          }),
          color: uiColors.text,
          backgroundColor: "transparent",
          outlineStyle: "none",
        }}
        __experimental_webkitSearchCancelButtonCss={{
          display: "none",
        }}
        __experimental_placeholderCss={{
          color: uiColors.textMinor,
        }}
      />
    </div>
  );
};
