import { useFontFace, type Style } from "@superweb/css";
import ysTextRegular from "./text-regular.woff2";
import ysTextLight from "./text-light.woff2";
import ysTextMedium from "./text-medium.woff2";
import ysTextBold from "./text-bold.woff2";
import ysTextHeavy from "./text-heavy.woff2";

export const useTypo = () => {
  const fontFace = useFontFace();

  const ysText = fontFace(
    {
      src: `url(${ysTextRegular})`,
      fontWeight: "400",
      fontDisplay: "swap",
    },
    {
      src: `url(${ysTextLight})`,
      fontWeight: "300",
      fontDisplay: "swap",
    },
    {
      src: `url(${ysTextMedium})`,
      fontWeight: "500",
      fontDisplay: "swap",
    },
    {
      src: `url(${ysTextBold})`,
      fontWeight: "700",
      fontDisplay: "swap",
    },
    {
      src: `url(${ysTextHeavy})`,
      fontWeight: "800",
      fontDisplay: "swap",
    },
  );
  return ({
    level,
    weight,
    density,
    tabularNumbers = false,
  }:
    | {
        level: "header";
        density: "normal";
        weight: "regular" | "light" | "medium" | "bold" | "heavy";
        tabularNumbers?: false;
      }
    | {
        level: "header";
        density: "tight";
        weight: "regular" | "light" | "medium" | "bold" | "heavy";
        tabularNumbers?: false;
      }
    | {
        level: "header";
        density: "loose";
        weight: "regular" | "light" | "medium" | "bold";
        tabularNumbers?: false;
      }
    | {
        level: "title1";
        density: "normal";
        weight: "regular" | "light" | "medium" | "bold" | "heavy";
        tabularNumbers?: false;
      }
    | {
        level: "title1";
        density: "tight";
        weight: "regular" | "light" | "medium" | "bold" | "heavy";
        tabularNumbers?: false;
      }
    | {
        level: "title1";
        density: "loose";
        weight: "regular" | "light" | "medium" | "bold";
        tabularNumbers?: false;
      }
    | {
        level: "title2";
        density: "normal";
        weight: "regular" | "light" | "medium" | "bold" | "heavy";
        tabularNumbers?: false;
      }
    | {
        level: "title2";
        density: "tight";
        weight: "regular" | "light" | "medium" | "bold" | "heavy";
        tabularNumbers?: false;
      }
    | {
        level: "title2";
        density: "loose";
        weight: "regular" | "light" | "medium" | "bold";
        tabularNumbers?: false;
      }
    | {
        level: "title3";
        density: "normal";
        weight: "regular" | "light" | "medium" | "bold" | "heavy";
        tabularNumbers?: false;
      }
    | {
        level: "title3";
        density: "tight";
        weight: "regular" | "light" | "medium" | "bold" | "heavy";
        tabularNumbers?: false;
      }
    | {
        level: "title3";
        density: "loose";
        weight: "regular" | "light" | "medium" | "bold";
        tabularNumbers?: false;
      }
    | {
        level: "title4";
        density: "normal";
        weight: "regular" | "light" | "medium" | "bold" | "heavy";
        tabularNumbers?: false;
      }
    | {
        level: "title4";
        density: "tight";
        weight: "regular" | "light" | "medium" | "bold" | "heavy";
        tabularNumbers?: false;
      }
    | {
        level: "title4";
        density: "loose";
        weight: "regular" | "light" | "medium" | "bold";
        tabularNumbers?: false;
      }
    | {
        level: "body1";
        density: "normal";
        weight: "regular" | "light" | "medium" | "bold" | "heavy";
        tabularNumbers?: false;
      }
    | {
        level: "body1";
        density: "normal";
        weight: "regular" | "light" | "medium" | "bold";
        tabularNumbers?: true;
      }
    | {
        level: "body1";
        density: "tight";
        weight: "regular" | "light" | "medium" | "bold" | "heavy";
        tabularNumbers?: false;
      }
    | {
        level: "body1";
        density: "tight";
        weight: "regular" | "light" | "medium" | "bold";
        tabularNumbers?: true;
      }
    | {
        level: "body1";
        density: "loose";
        weight: "regular" | "light" | "medium" | "bold";
        tabularNumbers?: boolean;
      }
    | {
        level: "body2";
        density: "normal";
        weight: "regular" | "medium" | "bold";
        tabularNumbers?: boolean;
      }
    | {
        level: "body2";
        density: "tight";
        weight: "regular" | "medium" | "bold";
        tabularNumbers?: boolean;
      }
    | {
        level: "body2";
        density: "loose";
        weight: "regular" | "medium" | "bold";
        tabularNumbers?: boolean;
      }
    | {
        level: "caption1";
        density: "normal";
        weight: "regular" | "medium" | "bold";
        tabularNumbers?: boolean;
      }
    | {
        level: "caption1";
        density: "tight";
        weight: "regular" | "medium" | "bold";
        tabularNumbers?: boolean;
      }
    | {
        level: "caption1";
        density: "loose";
        weight: "regular" | "medium" | "bold";
        tabularNumbers?: boolean;
      }
    | {
        level: "caption2";
        density: "normal";
        weight: "regular" | "medium" | "bold";
        tabularNumbers?: boolean;
      }
    | {
        level: "caption2";
        density: "tight";
        weight: "regular" | "medium" | "bold";
        tabularNumbers?: boolean;
      }
    | {
        level: "caption2";
        density: "loose";
        weight: "regular" | "medium" | "bold";
        tabularNumbers?: boolean;
      }): Style => {
    const common = {
      fontFamily: `${ysText}, sans-serif`,
      fontVariantNumeric: tabularNumbers
        ? `tabular-nums lining-nums`
        : `proportional-nums lining-nums`,
      fontWeight: {
        regular: "400",
        light: "300",
        medium: "500",
        bold: "700",
        heavy: "800",
      }[weight],
    };

    const switchWeight = (regularOrLight: string, mediumOrBold: string) => {
      return weight === "regular" || weight === "light"
        ? regularOrLight
        : mediumOrBold;
    };

    const switchDensity = (normal: string, tight: string, loose: string) => {
      return { normal, tight, loose }[density];
    };

    switch (level) {
      case "header":
        return {
          ...common,
          fontSize: "48px",
          lineHeight: switchDensity("46px", "40px", "50px"),
          letterSpacing: switchWeight("-0.03em", "-0.02em"),
        };

      case "title1":
        return {
          ...common,
          fontSize: "40px",
          lineHeight: switchDensity("42px", "34px", "44px"),
          letterSpacing: switchWeight("-0.02em", "-0.025em"),
        };

      case "title2":
        return {
          ...common,
          fontSize: "32px",
          lineHeight: switchDensity("35px", "28px", "37px"),
          letterSpacing: switchWeight("-0.02em", "-0.025em"),
        };

      case "title3":
        return {
          ...common,
          fontSize: "28px",
          lineHeight: switchDensity("31px", "24px", "34px"),
          letterSpacing: "-0.015em",
        };

      case "title4":
        return {
          ...common,
          fontSize: "24px",
          lineHeight: switchDensity("27px", "21px", "30px"),
          letterSpacing: "-0.01em",
        };

      case "body1":
        return {
          ...common,
          fontSize: "20px",
          lineHeight: switchDensity("23px", "20px", "26px"),
          letterSpacing: "-0.01em",
        };

      case "body2":
        return {
          ...common,
          fontSize: "16px",
          lineHeight: switchDensity("19px", "17px", "21px"),
          letterSpacing: switchWeight("0", "-0.005em"),
        };

      case "caption1":
        return {
          ...common,
          fontSize: "13px",
          lineHeight: switchDensity("15px", "14px", "17px"),
          letterSpacing: "0",
        };

      case "caption2":
        return {
          ...common,
          fontSize: "11px",
          lineHeight: switchDensity("13px", "12px", "15px"),
          letterSpacing: "0.01em",
        };
    }
  };
};
