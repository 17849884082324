/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useMemo } from "react";

import { cssFns } from "@superweb/css";

const useParkColors = () => {
  return [
    { color: "#68778E", backgroundColor: "#C4CCD7" },
    { color: "#FF6549", backgroundColor: "#FFD4C7" },
    { color: "#FF742B", backgroundColor: "#FFDAB2" },
    { color: "#E68E21", backgroundColor: "#FAE3AA" },
    { color: "#63B11E", backgroundColor: "#D3EDA6" },
    { color: "#46BC48", backgroundColor: "#C5F0C6" },
    { color: "#33BC74", backgroundColor: "#B9F0DA" },
    { color: "#33A6BC", backgroundColor: "#B9EAF0" },
    { color: "#5289D0", backgroundColor: "#CBE1F5" },
    { color: "#7472E6", backgroundColor: "#DAD9FA" },
    { color: "#D06DF0", backgroundColor: "#F5D7FC" },
    { color: "#FF6583", backgroundColor: "#FFD4DF" },
  ];
};

export const ParkAvatar = ({
  id,
  name,
  size = "m",
}: {
  id: string;
  name: string;
  size?: "m" | "l";
}) => {
  const parkColors = useParkColors();
  const parkColor =
    parkColors[
      Array.from(id).reduce((a, b) => a + b.charCodeAt(0), 0) %
        parkColors.length
    ];

  const initials = useMemo(() => getInitials(name), [name]);

  return (
    <div
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: parkColor!.color,
        backgroundColor: parkColor!.backgroundColor,
        ...(size === "l"
          ? {
              width: "40px",
              height: "40px",
              ...cssFns.border({ radius: "10px" }),
            }
          : {
              width: "28px",
              height: "28px",
              ...cssFns.border({ radius: "6px" }),
            }),
      }}
    >
      {initials}
    </div>
  );
};

export const getInitials = (name: string) => {
  const relevantWorlds = name
    .trim()
    .replaceAll(
      /[«»`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\p{Extended_Pictographic}]/giu,
      "",
    )
    .split(" ")
    .filter((e) => e)
    .slice(0, 2);

  if (relevantWorlds.length === 1) {
    const word = relevantWorlds[0];
    return (
      (word?.slice(0, 1).toLocaleUpperCase() ?? "") + (word?.slice(1, 2) ?? "")
    );
  } else {
    return relevantWorlds.reduce((accumulator, word) => {
      return accumulator + word.charAt(0).toUpperCase();
    }, "");
  }
};
