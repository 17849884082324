/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef, type ReactNode, type RefObject } from "react";
import {
  type AriaButtonProps,
  useFocusRing,
  useHover,
  useButton,
  mergeProps,
} from "react-aria";

import { cssFns } from "@superweb/css";
import { useTypo, useUiColors } from "@superweb/ui";

export const UserToolbarButton = ({
  /**
   * The text displayed in the button
   */
  label,
  /**
   * The text that is located under the button's label. It is displayed only when there is a label.
   */
  description,
  icon,
  variant = "default",
  buttonRef,
  externalAriaProps,
}: {
  label?: string;
  description?: string;
  icon?: ReactNode;
  variant?: "default" | "ghost";
  buttonRef?: RefObject<HTMLButtonElement>;
  externalAriaProps?: AriaButtonProps<"button">;
}) => {
  const uiColors = useUiColors();
  const typo = useTypo();

  const ref = useRef<HTMLButtonElement>(null);
  const internalButtonRef = buttonRef || ref;

  const { focusProps, isFocusVisible: isFocused } = useFocusRing();
  const { hoverProps, isHovered } = useHover({});

  const { buttonProps, isPressed } = useButton(
    { ...externalAriaProps },
    internalButtonRef,
  );

  const transparent = cssFns.transparentize(uiColors.everBack, 1);
  const baseColor = {
    default: uiColors.controlMinor,
    ghost: transparent,
  }[variant];

  return (
    <button
      {...mergeProps(buttonProps, hoverProps, focusProps)}
      ref={internalButtonRef}
      css={{
        ...cssFns.margin("0"),
        ...cssFns.padding("0"),
        ...cssFns.border({ style: "none", radius: "10px" }),
        outlineStyle: "none",
        backgroundColor: isPressed
          ? cssFns.blend(baseColor, uiColors.press)
          : isHovered
            ? cssFns.blend(baseColor, uiColors.hover)
            : baseColor,

        boxShadow: isFocused
          ? "inset 0 0 0 2px " + uiColors.focus
          : "0 0 0 0 transparent",
        cursor: "pointer",
      }}
    >
      <div
        css={{
          display: "grid",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          ...cssFns.border({
            radius: "10px",
          }),
          gridTemplateColumns: label ? "min-content auto" : "min-content",
          ...cssFns.overflow("hidden"),
          cursor: "pointer",
          color: uiColors.textMinor,
          outlineStyle: "none",
        }}
      >
        <div
          css={{
            width: "40px",
            height: "40px",
            display: "grid",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon}
        </div>
        {label && (
          <span
            css={{
              marginInlineEnd: "14px",
              ...typo({
                level: "caption1",
                weight: "regular",
                density: "tight",
              }),
              ...cssFns.overflow("hidden"),
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {label}
            {description && (
              <div
                css={{
                  ...typo({
                    level: "caption2",
                    weight: "regular",
                    density: "tight",
                  }),
                  textAlign: "left",
                  ...cssFns.overflow("hidden"),
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {description}
              </div>
            )}
          </span>
        )}
      </div>
    </button>
  );
};
