/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  type ReactNode,
  type MutableRefObject,
} from "react";

import { cssFns } from "@superweb/css";

import { useIsMobile } from "../mobile-context";
import { useUiOptions } from "../ui-options-context";

import { SwSnackbarContainerWrapper } from "./snackbar-container-element";

type SnackbarContextValue = {
  isExpanded: boolean;
  snackbars: {
    id: string;
    height: number;
  }[];
  container: HTMLElement | null;
};

type SnackbarManagementContextValue = {
  addSnackbar: (snackbar: { id: string; height: number }) => void;
  removeSnackbar: (snackbarId: string) => void;
  updateSnackbar: (snackbar: { id: string; height: number }) => void;
};

const SnackbarContext = createContext<SnackbarContextValue | null>(null);
const SnackbarManagementContext =
  createContext<SnackbarManagementContextValue | null>(null);

const SnackbarContextProvider = SnackbarContext.Provider;
const SnackbarManagementContextProvider = SnackbarManagementContext.Provider;

export const useSnackbarContext = () => useContext(SnackbarContext);
export const useSnackbarManagementContext = () =>
  useContext(SnackbarManagementContext);

export const SnackbarContainer = ({ children }: { children: ReactNode }) => {
  const isMobile = useIsMobile();
  const { experimental } = useUiOptions();

  const [isExpanded, setExpanded] = useState(false);
  const [snackbars, setSnackbars] = useState<
    {
      id: string;
      height: number;
    }[]
  >([]);
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!container) {
      return;
    }

    const handlePointerDown = (event: PointerEvent) => {
      if (
        event.target instanceof Element &&
        !container.contains(event.target)
      ) {
        setExpanded(false);
      }
    };

    const handlePointerOver = (e: PointerEvent) => {
      if (e.target instanceof Element && container.contains(e.target)) {
        setExpanded(true);
      }
    };

    const handlePointerOut = (e: PointerEvent) => {
      if (
        e.relatedTarget instanceof Element &&
        !container.contains(e.relatedTarget as Node)
      ) {
        setExpanded(false);
      }
    };

    container.addEventListener("pointerover", handlePointerOver);
    if (isMobile) {
      document.addEventListener("pointerdown", handlePointerDown, true);
    } else {
      container.addEventListener("pointerout", handlePointerOut);
    }

    return () => {
      container.removeEventListener("pointerover", handlePointerOver);
      if (isMobile) {
        document.removeEventListener("pointerdown", handlePointerDown, true);
      } else {
        container.removeEventListener("pointerout", handlePointerOut);
      }
    };
  }, [isMobile, container, setExpanded]);

  const stackManagementContextValue = useMemo(() => {
    const addSnackbar = ({
      id: snackbarId,
      height,
    }: {
      id: string;
      height: number;
    }) =>
      setSnackbars((prevSnackbars) => {
        if (prevSnackbars.some(({ id }) => id === snackbarId)) {
          return prevSnackbars;
        }

        return [
          {
            id: snackbarId,
            height,
          },
          ...prevSnackbars,
        ];
      });

    const removeSnackbar = (snackbarId: string) => {
      setExpanded(false);
      setSnackbars((prevSnackbars) =>
        prevSnackbars.filter(({ id }) => id !== snackbarId),
      );
    };

    const updateSnackbar = ({ id, height }: { id: string; height: number }) =>
      setSnackbars((prevSnackbars) =>
        prevSnackbars.map((snackbar) =>
          snackbar.id === id
            ? {
                id,
                height,
              }
            : snackbar,
        ),
      );

    return {
      addSnackbar,
      removeSnackbar,
      updateSnackbar,
    };
  }, [setSnackbars, setExpanded]);

  return (
    <SnackbarContextProvider
      value={{
        isExpanded,
        snackbars,
        container,
      }}
    >
      <SnackbarManagementContextProvider value={stackManagementContextValue}>
        {children}
        {experimental?.enableSnackbarStackAnimation ? (
          <SwSnackbarContainerWrapper
            ref={(element) => {
              if (element && element !== container) {
                setContainer(element);
              }
            }}
            style={{
              perspective: "500px",
              perspectiveOrigin: "center 70%",
              position: "fixed",
              right: "0",
              bottom: "0",
              left: "0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "500px",
              paddingBlock: "24px",
              paddingInline: "24px",
              pointerEvents: "none",
            }}
          />
        ) : (
          <div
            ref={(element) => {
              if (element && element !== container) {
                setContainer(element);
              }
            }}
            css={{
              position: "fixed",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
              ...cssFns.padding("24px"),
              pointerEvents: "none",
            }}
          />
        )}
      </SnackbarManagementContextProvider>
    </SnackbarContextProvider>
  );
};

export const useSnackbarStackManagement = ({
  id,
  ref,
}: {
  id: string;
  ref: MutableRefObject<HTMLDivElement | null>;
}) => {
  const snackbarContext = useSnackbarContext();
  const snackbarStackManagementContext = useSnackbarManagementContext();

  const snackbarIndex =
    snackbarContext?.snackbars.map(({ id }) => id).indexOf(id) ?? -1;

  const initialSnackbarStyles = {
    position: "absolute",
    boxSizing: "border-box",
    pointerEvents: "auto",
    minWidth: "328px",
    maxWidth: "764px",
    ...cssFns.border({
      radius: "16px",
    }),
    paddingBlockStart: "10px",
    opacity: "0",
    transform: "translateY(100%) translateZ(0)",
    transitionDuration: "200ms, 300ms, 300ms",
    transitionProperty: "opacity, transform",
    transitionTimingFunction: "easy-in-out",
  };
  let styles = {};

  if (snackbarContext?.isExpanded || snackbarIndex === 0) {
    styles = {
      ...initialSnackbarStyles,
      ...cssFns.overflow("auto"),
      transform: `translateY(${
        snackbarContext?.snackbars
          ? -snackbarContext.snackbars
              .slice(0, snackbarIndex)
              .reduce((sum, { height }) => sum + height, 0)
          : 0
      }px) translateZ(0)`,
      opacity: "1",
    };
  } else {
    styles = {
      ...initialSnackbarStyles,
      maxWidth: "328px",
      height: "58px",
      ...cssFns.overflow("hidden"),
      transform: `translateZ(${snackbarIndex * -100}px)`,
      opacity: "1",
    };
  }

  useEffect(() => {
    snackbarStackManagementContext?.addSnackbar({
      id,
      height: ref.current?.offsetHeight ?? 0,
    });

    return () => {
      snackbarStackManagementContext?.removeSnackbar(id);
    };
  }, [snackbarStackManagementContext, id, ref]);

  useEffect(() => {
    const snackbar = ref.current;

    if (!snackbar) {
      return;
    }

    const resizeObserver = new ResizeObserver(() =>
      snackbarStackManagementContext?.updateSnackbar({
        id,
        height: snackbar.offsetHeight,
      }),
    );

    resizeObserver.observe(snackbar);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, snackbarStackManagementContext, id]);

  return {
    container: snackbarContext?.container,
    isExpanded: snackbarContext?.isExpanded ?? false,
    styles: snackbarIndex === -1 ? initialSnackbarStyles : styles,
  };
};
