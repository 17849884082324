import { get as getCookie, remove as removeCookie } from "es-cookie";
import { createContext, useContext, useMemo, type ReactNode } from "react";

import { useFlag } from "@superweb/flags";

import { config } from "#internal/config";

export const logout = () => {
  const attributes = { path: "/", domain: ".yandex.ru" };
  ["ys", "yandex_login", "yandexuid"].forEach((name) =>
    removeCookie(name, attributes),
  );
  window.location.reload();
};

export const authWithPassport = () => {
  const searchParams = new URLSearchParams({
    retpath: window.location.href,
    origin: "fleet-forms",
  });

  window.location.replace(`${config.passportURL}/auth?${searchParams}`);
};

type AuthContextOptions = {
  uid: string;
  login: string;
};

const AuthContext = createContext<AuthContextOptions>({} as AuthContextOptions);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const flag = useFlag();

  const enableAuthentification = flag({
    id: "ba040a2c-b029-4b9f-b5fe-98b936c967c2",
    description: "Enable authentification with passport",
  });
  const profileInfo = useMemo(() => {
    const login = getCookie("yandex_login");
    const uid = getCookie("yandexuid");

    if (!login && enableAuthentification) {
      authWithPassport();
    }

    return uid && login ? { login, uid } : undefined;
  }, [enableAuthentification]);

  if (!enableAuthentification) {
    return children;
  }

  if (!profileInfo) {
    return null;
  }

  return (
    <AuthContext.Provider value={profileInfo}>{children}</AuthContext.Provider>
  );
};
export const useProfile = () => useContext(AuthContext);
