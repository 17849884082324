/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  useRef,
  useEffect,
  type ReactNode,
  type RefObject,
  type DOMAttributes,
} from "react";
import {
  OverlayContainer,
  mergeProps,
  useOverlay,
  useOverlayPosition,
  useTooltipTrigger,
  useTooltip as useTooltipProps,
  type PlacementAxis,
} from "react-aria";
import {
  useTooltipTriggerState,
  type TooltipTriggerState,
} from "react-stately";

import { cssFns } from "@superweb/css";

import { useTypo } from "./typo";
import { useUiColors, useUiShadows } from "./theme";
import { PopoverArrow } from "./tooltip-arrow";

type FocusableElement = Node & { focus: () => void };

export const Tooltip = ({
  state,
  tooltipProps,
  targetRef,
  children,
  placement,
  variant = "default",
  offset = 12,
}: {
  state: TooltipTriggerState;
  children: ReactNode;
  tooltipProps: DOMAttributes<FocusableElement>;
  targetRef: RefObject<HTMLElement>;
  variant?: "default" | "info";
  placement?: "top" | "bottom" | "start" | "end";
  offset?: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const onClose = () => state.close();

  const { overlayProps } = useOverlay(
    {
      onClose,
      isOpen: true,
    },
    ref,
  );

  const {
    overlayProps: positionProps,
    arrowProps,
    placement: axis,
  } = useOverlayPosition({
    targetRef,
    offset,
    isOpen: true,
    shouldFlip: true,
    shouldUpdatePosition: true,
    placement,
    overlayRef: ref as RefObject<HTMLElement>,
  });

  return (
    <OverlayContainer>
      <div
        ref={ref}
        css={{
          display: "flex",
          justifyContent: "center",
          pointerEvents: "none",
        }}
        {...mergeProps(overlayProps, tooltipProps, positionProps)}
      >
        <TooltipContent arrowProps={arrowProps} variant={variant} axis={axis}>
          {children}
        </TooltipContent>
      </div>
    </OverlayContainer>
  );
};

export const useTooltip = (
  ref: RefObject<HTMLElement>,
  props?: {
    /** Handler that is called when the overlay's open state changes. */
    onOpenChange?: (isOpen: boolean) => void;

    /**
     * The delay time for the tooltip to show up
     * @defaultValue 200
     * */
    delay?: number;

    /**
     * The delay time for the tooltip to close
     * @defaultValue 0
     * */
    closeDelay?: number;

    /**
     * The tooltip should close on scroll
     * @defaultValue true
     * */
    shouldCloseOnScroll?: boolean;
  },
) => {
  const {
    delay = 200,
    closeDelay = 0,
    shouldCloseOnScroll = true,
  } = props || {};

  const state = useTooltipTriggerState({
    delay,
    closeDelay,
    onOpenChange: props?.onOpenChange,
  });

  const { tooltipProps: tooltipTriggerProps, triggerProps } = useTooltipTrigger(
    { delay, closeDelay },
    state,
    ref,
  );

  const { tooltipProps } = useTooltipProps(tooltipTriggerProps, state);

  useEffect(() => {
    const scroll = () => {
      if (state.isOpen && shouldCloseOnScroll) {
        state.close();
      }
    };

    window.addEventListener("scroll", scroll);
    return () => {
      window.removeEventListener("scroll", scroll);
    };
  }, [state, shouldCloseOnScroll]);

  return {
    triggerProps,
    tooltipProps,
    state,
  };
};

const TooltipContent = ({
  children,
  variant,
  arrowProps,
  axis,
}: {
  children: ReactNode;
  variant: "default" | "info";
  axis: PlacementAxis | null;
  arrowProps: DOMAttributes<SVGSVGElement>;
}) => {
  const uiColors = useUiColors();
  const typo = useTypo();
  const uiShadows = useUiShadows();

  if (variant === "info") {
    return (
      <>
        <div
          css={{
            display: "grid",
            backgroundColor: uiColors.background,
            color: uiColors.text,
            maxWidth: "320px",
            boxShadow: uiShadows.bottomNormal,
            ...cssFns.padding("9px", "16px"),
            ...cssFns.border({ radius: "13px" }),
            ...typo({ level: "caption1", weight: "regular", density: "tight" }),
          }}
        >
          {children}
        </div>
        {axis ? (
          <PopoverArrow
            axis={axis}
            {...arrowProps}
            fill={uiColors.background}
          />
        ) : null}
      </>
    );
  }

  return (
    <>
      <div
        css={{
          display: "grid",
          color: uiColors.textInvert,
          backgroundColor: uiColors.backgroundInvert,
          maxWidth: "320px",
          ...cssFns.padding("9px", "16px"),
          ...cssFns.border({ radius: "13px" }),
          ...typo({ level: "caption1", weight: "regular", density: "tight" }),
        }}
      >
        {children}
      </div>
      {axis ? (
        <PopoverArrow
          axis={axis}
          {...arrowProps}
          fill={uiColors.backgroundInvert}
        />
      ) : null}
    </>
  );
};
