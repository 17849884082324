import { MessageCache, createMessages } from "@superweb/intl";

const { Messages, Message, useMessage } = createMessages({
  cache: new MessageCache({
    key: (locale) => locale.toString(),
    load: (locale: string) => {
      return import(`./translations/${locale}.json`)
        .then((module) => module.default)
        .catch(() => undefined);
    },
  }),
  sourceLanguage: "en",
});

export { Messages, Message, useMessage };
