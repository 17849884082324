/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  useRef,
  type Key,
  type ReactNode,
  type ReactElement,
  type ComponentType,
  useMemo,
  type MutableRefObject,
  type PointerEvent,
  type HTMLAttributeAnchorTarget,
  createContext,
  useContext,
  useEffect,
  type RefObject,
} from "react";
import {
  DismissButton,
  Overlay,
  usePopover,
  type AriaPopoverProps,
  type AriaMenuProps,
  useMenu,
  useMenuItem,
  useSeparator,
  useHover,
  useMenuSection,
  type AriaButtonProps,
  useFocusRing,
  mergeProps,
} from "react-aria";
import {
  type OverlayTriggerState,
  useTreeState,
  type TreeState,
  type Node,
  type MenuTriggerState,
} from "react-stately";
import { cssFns, type Style } from "@superweb/css";

import { icons } from "../icons";
import { InternalButton } from "../buttons/internal-button";
import { useCustomScrollbar } from "../scrollbar/scrollbar";
import { useUiColors, useUiShadows } from "../theme";
import { useUiOptions } from "../ui-options-context";
import { useTypo } from "../typo";
import { usePopoverContainer } from "../popover";
import { useLayoutOverlayPosition } from "../layouts/layout-container";

export const MaxHeightContext = createContext<string | number | undefined>(
  undefined,
);

export type ButtonSize = "l" | "m" | "s" | "xs";

export type ItemSize = "l" | "m" | "s" | "xs";

const iconsSizeMap: Record<ButtonSize, string> = {
  xs: "16px",
  s: "20px",
  m: "24px",
  l: "24px",
};

const iconSizePaddingMap: Record<ButtonSize, string> = {
  xs: "8px",
  s: "10px",
  m: "12px",
  l: "16px",
};

const heightSizeMap: Record<ButtonSize, string> = {
  xs: "32px",
  s: "40px",
  m: "48px",
  l: "56px",
};

export const BaseMenuButton = ({
  icon,
  label,
  size = "m",
  view = "default",
  shape = "squircle",
  buttonRef,
  menuTriggerProps,
  state,
  isOpen,
  progress = false,
  children,
  onPress,
}: {
  /**
   * Size of open menu button.
   * @defaultValue 'm'
   */
  size?: ButtonSize;

  /**
   * View of open menu button.
   * @defaultValue "default"
   */
  view?: "default" | "ghost";

  /**
   * Shape of open menu button.
   * @defaultValue "squircle"
   */
  shape?: "circle" | "squircle";

  /**
   * Icon of open menu button.
   * @defaultValue icons.More
   */

  icon?: ComponentType<{ className?: string }>;

  /**
   * Label of open menu button.
   */
  label?: string;

  /**
   * Open menu button ref.
   */
  buttonRef: MutableRefObject<null>;

  /**
   * Props from useMenuTrigger that are used in open menu button.
   */
  menuTriggerProps: AriaButtonProps;

  /**
   * State from useMenuTriggerState.
   */
  state: MenuTriggerState;

  /**
   * Whether popover is open or not.
   * Comes from the state, but if it is not a prop can return undefined.
   */
  isOpen: boolean;

  /**
   * If `true`, then the menu button displays the running process as a spinner.
   * @defaultValue false
   */
  progress?: boolean;

  /**
   * Menu itself.
   */
  children: ReactNode;

  /**
   * Callback fired after open menu button was clicked and menu was opened.
   */
  onPress?: () => void;
}) => {
  const buttonProps = useMemo(() => {
    if (label && icon) {
      return { text: label, icon: icon };
    } else if (label && !icon) {
      return { text: label };
    } else if (!label && icon) {
      return { icon: icon, ariaLabel: "Open menu" };
    } else {
      return { icon: icons.More, ariaLabel: "Open menu" };
    }
  }, [label, icon]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        state.close();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [state]);

  return (
    <>
      <InternalButton
        buttonRef={buttonRef}
        ariaButtonProps={menuTriggerProps}
        size={size}
        view={view}
        shape={shape}
        progress={progress}
        onPress={onPress}
        {...buttonProps}
      />
      {isOpen && (
        <Popover state={state} triggerRef={buttonRef} placement="bottom start">
          {children}
        </Popover>
      )}
    </>
  );
};

const Popover = (
  props: {
    children: ReactNode;
    state: OverlayTriggerState;
  } & Omit<AriaPopoverProps, "popoverRef">,
) => {
  const popoverContainer = usePopoverContainer();

  const ref = useRef<HTMLDivElement>(null);
  const { state, children } = props;
  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      popoverRef: ref,
    },
    state,
  );

  const overlayProps = useLayoutOverlayPosition({
    ...props,
    overlayRef: ref,
    targetRef: props.triggerRef,
  });

  return (
    <Overlay portalContainer={popoverContainer}>
      <div
        {...underlayProps}
        css={{
          position: "fixed",
          ...cssFns.inset("0"),
        }}
      />
      <div
        {...mergeProps(popoverProps, overlayProps)}
        ref={ref}
        css={{
          ...cssFns.margin("8px", "0"),
        }}
      >
        <DismissButton onDismiss={state.close} />
        <MaxHeightContext.Provider value={popoverProps.style?.maxHeight}>
          {children}
        </MaxHeightContext.Provider>
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
};

export const Menu = (
  props: {
    /**
     * Href, onClick and target values for links.
     */
    itemsLinks?: Map<
      string,
      {
        href: string;
        onClick?: (e: PointerEvent<HTMLAnchorElement>) => void;
        target?: HTMLAttributeAnchorTarget;
        download?: boolean;
      }
    >;
    /**
     * Set of disabled items.
     */
    disabledItems?: Set<Key>;
    /**
     * Size of item.
     * @defaultValue 'm'
     */
    itemSize?: ItemSize;

    menuRef: RefObject<HTMLUListElement>;
  } & AriaMenuProps<ReactElement>,
) => {
  const state = useTreeState({ ...props });
  const { menuProps } = useMenu(props, state, props.menuRef);

  const { experimental } = useUiOptions();
  const uiColors = useUiColors();
  const uiShadows = useUiShadows();
  const customScrollbarCSS = useCustomScrollbar();

  const maxHeight = useContext(MaxHeightContext);

  // Workaround for https://github.com/adobe/react-spectrum/issues/1513
  // Corrects the triggering of clicks on elements under the overlay
  useEffect(() => {
    const handler = (event: TouchEvent) => {
      const closestMenuItem = (event.target as HTMLElement).closest(
        "[role=menuitem]",
      );
      if (
        event.cancelable &&
        typeof closestMenuItem?.getAttribute("href") !== "string"
      ) {
        event.preventDefault();
      }
    };

    const menuElement = props.menuRef.current;
    menuElement?.addEventListener("touchend", handler);

    return () => {
      menuElement?.removeEventListener("touchend", handler);
    };
  }, [props.menuRef]);

  return (
    <ul
      {...menuProps}
      ref={props.menuRef}
      css={{
        minWidth: props.itemSize === "s" ? "120px" : "160px",
        width: "max-content",
        maxWidth: "min(350px, calc(100vw - 16px))",
        ...cssFns.margin("0"),
        ...cssFns.padding("0"),
        listStyleType: "none",
        backgroundColor: experimental?.enableBackgroundFloatingInPopover
          ? uiColors.backgroundFloating
          : uiColors.background,
        boxShadow: uiShadows.bottomNormal,
        ...cssFns.border({
          radius: props.itemSize === "s" ? "13px" : "16px",
        }),
        overflowY: "auto",
        maxHeight: maxHeight + "px",
      }}
      {...customScrollbarCSS}
    >
      {[...state.collection].map((item, index) => {
        if (item.type === "section") {
          return (
            <MenuItemSection
              key={item.key}
              section={item}
              state={state}
              sectionIndex={
                index === 0
                  ? "first"
                  : index === [...state.collection].length - 1
                    ? "last"
                    : undefined
              }
              itemsLinks={props.itemsLinks}
              disabledItems={props.disabledItems}
              size={props.itemSize}
            />
          );
        }
        return (
          <MenuItem
            key={item.key}
            item={item}
            state={state}
            radiusTop={index === 0}
            radiusBottom={index === [...state.collection].length - 1}
            link={props.itemsLinks?.get(String(item.key))}
            disabled={props.disabledItems?.has(item.key)}
            size={props.itemSize}
          />
        );
      })}
    </ul>
  );
};

const MenuItemSection = ({
  section,
  state,
  sectionIndex,
  itemsLinks,
  disabledItems,
  size,
}: {
  /**
   * Menu section itself.
   */
  section: Node<ReactElement>;
  /**
   * Tree state with a prop children, consists of items in the tree.
   */
  state: TreeState<ReactElement>;
  /**
   * Prop to help menu item use correct border-radius in hover.
   */
  sectionIndex: "first" | "last" | undefined;
  /**
   * Helps MenuItem to determine whether it is a link or a button.
   * And if it is a link, send it appropriate href, onClick and target.
   */
  itemsLinks?: Map<
    string,
    {
      href: string;
      onClick?: (e: PointerEvent<HTMLAnchorElement>) => void;
      target?: HTMLAttributeAnchorTarget;
      download?: boolean;
    }
  >;
  /**
   * Set of disabled items.
   */
  disabledItems?: Set<Key>;
  /**
   * Size of item.
   *
   * @defaultValue 'm'
   */
  size?: ItemSize;
}) => {
  const { itemProps, groupProps } = useMenuSection({
    heading: section.rendered,
    "aria-label": section["aria-label"],
  });
  const { separatorProps } = useSeparator({
    elementType: "li",
  });
  const uiColors = useUiColors();
  return (
    <>
      {section.key !== state.collection.getFirstKey() && (
        <li
          {...separatorProps}
          css={{
            ...cssFns.border({
              width: "0.5px",
              style: "solid",
              color: uiColors.line,
            }),
            opacity: "0.5",
          }}
        />
      )}
      <li {...itemProps}>
        <ul
          {...groupProps}
          css={{ listStyleType: "none", paddingInlineStart: "0" }}
        >
          {[...section.props.children].map((item, index) => (
            <MenuItem
              key={item.key}
              item={item}
              state={state}
              radiusTop={
                !section.props.title && sectionIndex === "first" && index === 0
              }
              radiusBottom={
                sectionIndex === "last" &&
                index === [...section.props.children].length - 1
              }
              link={itemsLinks?.get(String(item.key))}
              disabled={disabledItems?.has(item.key)}
              size={size}
            />
          ))}
        </ul>
      </li>
    </>
  );
};

const MenuItem = ({
  item,
  state,
  radiusTop = false,
  radiusBottom = false,
  link,
  disabled = false,
  size,
}: {
  /**
   * Menu item itself.
   */
  item: Node<ReactElement>;
  /**
   * Tree state with a collection of items in the tree, a selection manager to read
   * and update multiple selection state and etc.
   */
  state: TreeState<ReactElement>;
  /**
   * Prop to use border-radius in hover with first <li>.
   */
  radiusTop?: boolean;
  /**
   * Prop to use border-radius in hover with last <li>.
   */
  radiusBottom?: boolean;
  /**
   * Href, onClick and target of link menu item.
   */
  link?: {
    href: string;
    onClick?: (e: PointerEvent<HTMLAnchorElement>) => void;
    target?: HTMLAttributeAnchorTarget;
    download?: boolean;
  };
  /**
   * Whether item is disabled or not.
   * @defaultValue false
   */
  disabled?: boolean;
  /**
   * Size of item.
   * @defaultValue 'm'
   */
  size?: ItemSize;
}) => {
  const ref = useRef(null);
  const { menuItemProps, isPressed } = useMenuItem(
    {
      key: item.key,
    },
    state,
    ref,
  );
  const { hoverProps, isHovered } = useHover({});

  const { isFocusVisible, focusProps } = useFocusRing();

  const { experimental } = useUiOptions();
  const uiColors = useUiColors();
  const backgroundColor =
    isPressed || isHovered || isFocusVisible
      ? isPressed && !disabled
        ? uiColors.press
        : uiColors.hover
      : experimental?.enableBackgroundFloatingInPopover
        ? uiColors.backgroundFloating
        : uiColors.background;

  const liStyle: Style = {
    backgroundColor: backgroundColor,
    outlineStyle: "none",
    cursor: disabled ? "default" : "pointer",
    borderTopLeftRadius: radiusTop ? (size === "s" ? "13px" : "16px") : "0px",
    borderTopRightRadius: radiusTop ? (size === "s" ? "13px" : "16px") : "0px",
    borderBottomLeftRadius: radiusBottom
      ? size === "s"
        ? "13px"
        : "16px"
      : "0px",
    borderBottomRightRadius: radiusBottom
      ? size === "s"
        ? "13px"
        : "16px"
      : "0px",
    transitionDuration: "0.3s",
    color: disabled ? uiColors.textMinor : uiColors.text,
  };

  if (link) {
    return (
      <li role="none" css={liStyle}>
        <a
          {...mergeProps(menuItemProps, hoverProps, focusProps)}
          href={link.href}
          onClick={link.onClick}
          target={link.target}
          download={link.download}
          ref={ref}
          /**
           * Reassigning these functions is necessary to trigger a link, not an empty onAction.
           * The idea was taken from a discussion of the problem of links as menu items
           * in the Menu Button in react-aria's github.
           * https://github.com/adobe/react-spectrum/issues/1244#issuecomment-1125813249
           */
          onPointerUp={() => {}}
          onKeyDown={() => {}}
          css={{
            textDecorationLine: "none",
            outlineStyle: "none",
            color: disabled ? uiColors.textMinor : uiColors.text,
          }}
          aria-disabled={disabled ? true : menuItemProps["aria-disabled"]}
        >
          {/* Because Items themselves and Items from Selection
                provide slightly different props */}
          {item.rendered || item.props.children}
        </a>
      </li>
    );
  }
  return (
    <li
      {...mergeProps(menuItemProps, hoverProps, focusProps)}
      ref={ref}
      css={liStyle}
      aria-disabled={disabled ? true : menuItemProps["aria-disabled"]}
    >
      {/* Because Items themselves and Items from Selection
                provide slightly different props */}
      {item.rendered || item.props.children}
    </li>
  );
};

export const MenuItemContent = ({
  label,
  description,
  size,
  icon,
  additionalIcon,
  contentRef,
  reserveAdditionalIconSpace,
}: {
  /**
   * Name of menu item.
   */
  label: string;
  /**
   * Description of menu item.
   * Not render in "s" size
   */
  description?: string;
  /**
   * Size of menu item.
   */
  size: ItemSize;

  /**
   * Menu item icon.
   */
  icon?: ReactElement;
  /**
   * Icon that is displayed at the end of menu item.
   * For example, for new tab links or checkboxes.
   */
  additionalIcon?: ReactNode;

  /**
   * The ref for the content element..
   */
  contentRef: RefObject<HTMLDivElement>;

  /**
   * Whether menu item needs to reserve space for additional icon or not.
   * Especially, it is used in checkboxes to prevent long text from jumping when item was checked.
   * @defaultValue false
   */
  reserveAdditionalIconSpace?: Boolean;
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();
  const iconSize = iconsSizeMap[size];
  const withIcon = additionalIcon || reserveAdditionalIconSpace;

  return (
    <div
      ref={contentRef}
      css={{
        display: "grid",
        alignContent: "center",
        justifyContent: "space-between",
        alignItems: "center",
        gridTemplateRows: "16px [content] auto 16px",
        backgroundColor: "transparent",
        height: heightSizeMap[size],
        ...(icon && {
          paddingInlineStart: ["xs", "s"].includes(size) ? "8px" : "16px",
        }),
        ...(icon
          ? cssFns.padding("0px", iconSizePaddingMap[size])
          : cssFns.padding(
              "0px",
              ["xs", "s"].includes(size) ? "12px" : "16px",
            )),
        ...((additionalIcon || reserveAdditionalIconSpace) && {
          paddingInlineEnd: "12px",
        }),
      }}
    >
      <div
        css={{
          display: "grid",
          alignItems: "center",
          justifyContent: "start",
          gridRowStart: "content",
          gridTemplateRows: "[subcontent] auto",
        }}
      >
        {icon && (
          <div
            css={{
              gridRowStart: "subcontent",
              width: iconSize,
              height: iconSize,
              display: "flex",
              alignItems: "center",
              paddingInlineEnd: iconSizePaddingMap[size],
            }}
          >
            {icon}
          </div>
        )}
        <span
          css={{
            ...typo({
              level: size === "xs" ? "caption1" : "body2",
              weight: size === "xs" ? "medium" : "regular",
              density: "tight",
            }),
            ...cssFns.overflow("hidden"),
            gridRowStart: "subcontent",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "block",
          }}
        >
          {label}
          {description && (
            <span
              css={{
                ...cssFns.overflow("hidden"),
                gridRowStart: "subcontent",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                color: uiColors.textMinor,
                ...typo({
                  level: "caption1",
                  weight: "regular",
                  density: "tight",
                }),
                display: "block",
                marginTop: "1px",
              }}
            >
              {description}
            </span>
          )}
        </span>
      </div>

      {withIcon ? (
        <div
          css={{
            gridRowStart: "content",
            paddingInlineStart: "12px",
          }}
        >
          <div
            css={{
              width: iconSize,
              height: iconSize,
              display: "flex",
              alignItems: "center",
            }}
          >
            {additionalIcon}
          </div>
        </div>
      ) : undefined}
    </div>
  );
};
