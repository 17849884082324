/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { ReactNode } from "react";
import { Flags as BaseFlags } from "@superweb/flags";

const testingOnly = import.meta.env.FLEET_FORMS_ENV === "testing";

export const Flags = ({ children }: { children: ReactNode }) => {
  const enableAuthentification = testingOnly;

  return (
    <BaseFlags
      value={{
        "7fd41e03-8f0f-4153-b9ed-a0e613ed5500": Boolean(
          import.meta.env["FLEET_FORMS_VEHICLE_TYPES"],
        ),
        "aa814bf7-a087-4378-934f-9374140551cf": testingOnly,
        "39102927-6a29-4430-9740-f9f54697ca6b": testingOnly,
        "54b9dc94-8033-49da-85e0-f02af3de7072": testingOnly,
        "ba040a2c-b029-4b9f-b5fe-98b936c967c2": enableAuthentification,
        "b1284cfc-875a-4ad2-a123-fe1bd78e30f0": enableAuthentification,
      }}
    >
      {children}
    </BaseFlags>
  );
};
