import { createThemeColorsContext } from "./colors-context/colors-context";

export type BrandColorsValue = {
  background: string;
  backgroundMinor: string;
  text: string;
  textMinor: string;
  hover: string;
  press: string;
  focus: string;
};

const [BrandColors, useBrandColors] =
  createThemeColorsContext<BrandColorsValue>("brandColors");

export { BrandColors, useBrandColors };
