import {
  createContext,
  useContext,
  useMemo,
  useState,
  type ReactNode,
} from "react";
import { v4 as uuidv4 } from "uuid";

const OperationIdContext = createContext<{
  sessionId: string;
  submissionId: string;
  updateSubmissionId: () => void;
}>({ sessionId: "", submissionId: "", updateSubmissionId: () => null });

export const OperationId = ({ children }: { children: ReactNode }) => {
  const sessionId = useMemo(() => uuidv4(), []);
  const [submissionId, setSubmissionId] = useState(uuidv4());
  return (
    <OperationIdContext.Provider
      value={{
        sessionId,
        submissionId,
        updateSubmissionId: () => setSubmissionId(uuidv4()),
      }}
    >
      {children}
    </OperationIdContext.Provider>
  );
};

export const useOperationIdContext = () => useContext(OperationIdContext);
