import { createElement, useInsertionEffect, type ReactNode } from "react";
import { useCssInstance } from "./instance";
import { knownProperties, type Style } from "./style";
import { dash, decl, hash, name, trim } from "./utils";

export type CssProps = {
  css?: Style;
  __experimental_webkitScrollbarCss?: Style;
  __experimental_webkitScrollbarCornerCss?: Style;
  __experimental_webkitScrollbarThumbCss?: Style;
  __experimental_webkitScrollbarThumbHoverCss?: Style;
  __experimental_webkitScrollbarButtonCss?: Style;
  __experimental_placeholderCss?: Style;
  __experimental_webkitSearchCancelButtonCss?: Style;
  __experimental_webkitSearchDecorationCss?: Style;
  __experimental_beforeCss?: Style;
  __experimental_afterCss?: Style;
};

/**
 * ```ts
 * const css = useCss();
 *
 * <div
 *   className={css({
 *     color: "black",
 *     backgroundColor: "white",
 *   })}
 * />;
 * ```
 */
export const useCss = (): ((css: Style) => string) => {
  const { dangerouslyAllowUnknownProperties, inject } = useCssInstance();
  const rulesToBeInjected = new Map<string, string[]>();

  useInsertionEffect(() => {
    for (const [id, rules] of rulesToBeInjected) {
      inject({ id, rules });
    }
  });

  return (css: Style): string => {
    const { className, rules } = composeRules({
      css,
      options: {
        dangerouslyAllowUnknownProperties,
      },
    });

    for (const { id, rule } of rules) {
      rulesToBeInjected.set(id, [rule]);
    }

    return className;
  };
};

export const Css = ({
  css,
  __experimental_placeholderCss,
  __experimental_webkitScrollbarCss,
  __experimental_webkitScrollbarCornerCss,
  __experimental_webkitScrollbarButtonCss,
  __experimental_webkitScrollbarThumbCss,
  __experimental_webkitScrollbarThumbHoverCss,
  __experimental_webkitSearchDecorationCss,
  __experimental_webkitSearchCancelButtonCss,
  __experimental_beforeCss,
  __experimental_afterCss,
  type,
  props,
  children,
}: CssProps & {
  type: string;
  props: {};
  children?: ReactNode;
}) => {
  const { dangerouslyAllowUnknownProperties, inject } = useCssInstance();
  const rulesToBeInjected = new Map<string, string[]>();

  useInsertionEffect(() => {
    for (const [id, rules] of rulesToBeInjected) {
      inject({ id, rules });
    }
  });

  let classNames = "";

  const addRules = ({
    css,
    pseudoElement,
  }: {
    css: Style;
    pseudoElement?: string;
  }) => {
    const { className, rules } = composeRules({
      css,
      pseudoElement,
      options: {
        dangerouslyAllowUnknownProperties,
      },
    });

    for (const { id, rule } of rules) {
      rulesToBeInjected.set(id, [rule]);
    }

    classNames += " " + className;
  };

  if (css) {
    addRules({ css });
  }

  if (__experimental_placeholderCss) {
    addRules({
      css: __experimental_placeholderCss,
      pseudoElement: "placeholder",
    });
  }

  if (__experimental_webkitScrollbarCss) {
    addRules({
      css: __experimental_webkitScrollbarCss,
      pseudoElement: "-webkit-scrollbar",
    });
  }

  if (__experimental_webkitScrollbarCornerCss) {
    addRules({
      css: __experimental_webkitScrollbarCornerCss,
      pseudoElement: "-webkit-scrollbar-corner",
    });
  }

  if (__experimental_webkitScrollbarButtonCss) {
    addRules({
      css: __experimental_webkitScrollbarButtonCss,
      pseudoElement: "-webkit-scrollbar-button",
    });
  }

  if (__experimental_webkitScrollbarThumbCss) {
    addRules({
      css: __experimental_webkitScrollbarThumbCss,
      pseudoElement: "-webkit-scrollbar-thumb",
    });
  }

  if (__experimental_webkitScrollbarThumbHoverCss) {
    addRules({
      css: __experimental_webkitScrollbarThumbHoverCss,
      pseudoElement: "-webkit-scrollbar-thumb:hover",
    });
  }

  if (__experimental_webkitSearchDecorationCss) {
    addRules({
      css: __experimental_webkitSearchDecorationCss,
      pseudoElement: "-webkit-search-decoration",
    });
  }

  if (__experimental_webkitSearchCancelButtonCss) {
    addRules({
      css: __experimental_webkitSearchCancelButtonCss,
      pseudoElement: "-webkit-search-cancel-button",
    });
  }

  if (__experimental_beforeCss) {
    addRules({
      css: __experimental_beforeCss,
      pseudoElement: "before",
    });
  }

  if (__experimental_afterCss) {
    addRules({
      css: __experimental_afterCss,
      pseudoElement: "after",
    });
  }

  return createElement(
    type,
    { ...props, className: classNames.trim() },
    children,
  );
};

const composeRules = ({
  css,
  pseudoElement,
  options,
}: {
  css: Style;
  pseudoElement?: string;
  options: {
    dangerouslyAllowUnknownProperties?: boolean;
  };
}) => {
  let className = "";
  const rules: {
    id: string;
    rule: string;
  }[] = [];

  const addRule = (property: string, value: string) => {
    const p = dash(property);
    const v = trim(value);
    const h = hash(v, hash(p, hash(pseudoElement)));
    const n = name(h);
    const s = pseudoElement ? `.${n}::${pseudoElement}` : `.${n}`;
    const r = `${s}{${decl(p, v)}}`;

    const support =
      pseudoElement &&
      pseudoElement.startsWith("-") &&
      pseudoElement !== "-webkit-search-cancel-button";

    const rule = support ? `@supports selector(${s}) {${r}}` : r;

    rules.push({
      id: n,
      rule,
    });
    className += " " + n;
  };

  // Chrome doesn't support the longhand properties 'text-wrap-mode' and 'text-wrap-style', so we need to add shorthand too
  if (css.textWrapMode === "nowrap") {
    addRule("textWrap", "nowrap");
  } else if (css.textWrapMode === "wrap" || css.textWrapStyle) {
    addRule(
      "textWrap",
      css.textWrapStyle === "auto" ? "wrap" : css.textWrapStyle ?? "wrap",
    );
  }

  for (const [property, value] of Object.entries(css)) {
    if (!value) continue;
    if (
      knownProperties.has(property) ||
      options.dangerouslyAllowUnknownProperties
    ) {
      addRule(property, value);
    }
  }

  className = className.trim();

  return {
    className,
    rules,
  };
};
