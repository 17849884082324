/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { type ReactNode, useMemo, useRef, forwardRef } from "react";

import { cssFns } from "@superweb/css";

import { useUiColors } from "../theme";
import { useTypo } from "../typo";
import { useIsMobile } from "../mobile-context";
import { BottomSheetContextProvider } from "../bottom-sheet/bottom-sheet";
import { LayoutContextProvider } from "./layout-container";
import { useUiOptions } from "../ui-options-context";

const navMenuWidth = 64;
export const headerDesktopHeight = 60;
const headerMobileHeight = 56;
export const mainPaddings = 16;
export const mainPaddingBlockStart = 8;
const mainPaddingBlockEnd = 88; //88px = 56px (height guidance panel) + 32px (inset-block-end and inset-block-start for guidance panel)

export const AppLayout = ({
  menu,
  content,
}: {
  menu?: ReactNode;
  content: ReactNode;
}) => {
  const isMobile = useIsMobile();
  const fullHeight = CSS.supports("height: 100dvh") ? "100dvh" : "100vh";

  const layoutContainerRef = useRef<HTMLDivElement>(null);

  return (
    <LayoutContextProvider
      value={{
        container: layoutContainerRef,
      }}
    >
      <div
        ref={layoutContainerRef}
        css={{
          display: "grid",
          gridTemplateColumns:
            isMobile || !menu ? "1fr" : `${navMenuWidth}px 1fr`,
          gridTemplateRows: `minmax(min-content, ${fullHeight}) 1fr`,
          minHeight: fullHeight,
          isolation: "isolate",
          minWidth: "min-content",
        }}
      >
        {!isMobile && menu ? (
          <div
            css={{
              position: "sticky",
              height: fullHeight,
              insetBlockStart: "0",
              insetInlineStart: "0",
              zIndex: "2",
              display: "grid",
              ...cssFns.gridArea("1"),
            }}
          >
            {menu}
          </div>
        ) : undefined}
        <div>{content}</div>
      </div>
    </LayoutContextProvider>
  );
};

export const PageLayout = ({
  title,
  hiddenTitle,
  burgerMenuButton,
  toolbar,
  userToolbar,
  content,
  stickyHeader = false,
  disableContentMargins = false,
}: {
  burgerMenuButton: ReactNode;
  toolbar?: ReactNode;
  userToolbar?: ReactNode;
  content: ReactNode;
  stickyHeader?: boolean;
  disableContentMargins?: boolean;
} & (
  | { title: ReactNode; hiddenTitle?: never }
  | {
      title?: never;

      /**
       * Gives the page an accessible name.
       * Need to specify when the 'title' is not specified.
       * This prop is required for seo.
       */
      hiddenTitle: string;
    }
)) => {
  const isMobile = useIsMobile();
  const fullHeight = CSS.supports("height: 100dvh") ? "100dvh" : "100vh";
  const { experimental } = useUiOptions();

  const bottomSheetContainerRef = useRef<HTMLDivElement>(null);

  return (
    <BottomSheetContextProvider value={bottomSheetContainerRef}>
      <Header stickyHeader={stickyHeader}>
        <div css={{ display: "flex", alignItems: "center", flexGrow: "1" }}>
          {isMobile && (
            <div
              css={{
                width: "56px",
                height: `${headerMobileHeight}px`,
                display: "grid",
                alignItems: "center",
                justifyItems: "center",
              }}
            >
              {burgerMenuButton}
            </div>
          )}
          <Title {...(hiddenTitle ? { hiddenTitle } : { title })} />
          <div css={{ display: "flex", flexGrow: "1", marginInlineEnd: "8px" }}>
            {toolbar}
          </div>
        </div>
        {userToolbar ? <div>{userToolbar}</div> : undefined}
      </Header>

      <main
        css={{
          display: "grid",
          minHeight: `calc(${fullHeight} - ${
            isMobile ? headerMobileHeight : headerDesktopHeight
          }px)`,
          boxSizing: "border-box",
          ...(!isMobile &&
            !disableContentMargins && {
              ...cssFns.padding(`${mainPaddings}px`),
              paddingBlockStart: `${mainPaddingBlockStart}px`,
            }),
          ...(experimental?.enabledPagePaddingBottom &&
            !disableContentMargins && {
              paddingBlockEnd: `${mainPaddingBlockEnd}px`,
            }),
        }}
      >
        {content}
      </main>
      <BottomSheetPortal ref={bottomSheetContainerRef} />
    </BottomSheetContextProvider>
  );
};

const Header = ({
  stickyHeader,
  children,
}: {
  stickyHeader: boolean;
  children: ReactNode;
}) => {
  const uiColors = useUiColors();

  const scrollWidth = useMemo(() => {
    const div = document.createElement("div");

    div.style.overflowY = "scroll";
    div.style.width = "50px";
    div.style.height = "50px";

    // мы должны вставить элемент в документ, иначе размеры будут равны 0
    document.body.append(div);
    const scrollWidth = div.offsetWidth - div.clientWidth;
    div.remove();

    return scrollWidth;
  }, []);

  const isMobile = useIsMobile();

  return (
    <header
      css={{
        position: "sticky",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: uiColors.backgroundMinor,
        boxSizing: "border-box",
        ...(isMobile
          ? {
              width: `calc(100vw - ${scrollWidth}px)`,
              height: `${headerMobileHeight}px`,
              insetInlineStart: "0px",
              gridTemplateColumns: "min-content auto",
              paddingInlineEnd: "12px",
            }
          : {
              width: `calc(100vw - ${navMenuWidth}px - ${scrollWidth}px)`,
              height: `${headerDesktopHeight}px`,
              insetInlineStart: `${navMenuWidth}px`,
              gridTemplateColumns: "auto",

              paddingInlineStart: "16px",
              paddingInlineEnd: "12px",
              paddingBlockStart: "12px",
              paddingBlockEnd: "8px",
            }),
        ...(stickyHeader && {
          top: "0",
          zIndex: "1",
        }),
      }}
    >
      {children}
    </header>
  );
};

const Title = ({
  title,
  hiddenTitle,
}:
  | { title: ReactNode; hiddenTitle?: never }
  | {
      title?: never;
      hiddenTitle: string;
    }) => {
  const typo = useTypo();
  const uiColors = useUiColors();
  const isMobile = useIsMobile();

  return (
    <h1
      css={{
        ...(hiddenTitle && { visibility: "hidden" }),
        ...cssFns.margin("0"),
        marginInlineEnd: "16px",
        color: uiColors.text,
        ...(isMobile
          ? typo({
              level: "body1",
              weight: "medium",
              density: "tight",
            })
          : typo({
              level: "title3",
              weight: "medium",
              density: "tight",
            })),
      }}
    >
      {title ?? hiddenTitle}
    </h1>
  );
};

const BottomSheetPortal = forwardRef<HTMLDivElement, {}>(({}, ref) => {
  const isMobile = useIsMobile();

  const scrollWidth = useMemo(() => {
    const div = document.createElement("div");

    div.style.overflowY = "scroll";
    div.style.width = "50px";
    div.style.height = "50px";

    // мы должны вставить элемент в документ, иначе размеры будут равны 0
    document.body.append(div);
    const scrollWidth = div.offsetWidth - div.clientWidth;
    div.remove();

    return scrollWidth;
  }, []);

  return (
    <div
      ref={ref}
      css={{
        position: "sticky",
        bottom: "0",
        boxSizing: "border-box",
        ...(isMobile
          ? {
              width: "100vw",
              insetInlineStart: "0",
            }
          : {
              width: `calc(100vw - ${navMenuWidth}px - ${scrollWidth}px)`,
              insetInlineStart: `${navMenuWidth}px`,
            }),
      }}
    />
  );
});
