import {
  createElement,
  forwardRef,
  useEffect,
  useState,
  type HTMLAttributes,
} from "react";

const SwSnackbarContainer = forwardRef<
  HTMLElement,
  HTMLAttributes<HTMLElement>
>((props, ref) => createElement("sw-snackbar-container", { ...props, ref }));

export const SwSnackbarContainerWrapper = forwardRef<
  HTMLElement,
  HTMLAttributes<HTMLElement>
>((props, ref) => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (!render) {
      setRender(true);

      if (!customElements.get("sw-snackbar-container")) {
        customElements.define(
          "sw-snackbar-container",
          class extends HTMLElement {
            constructor() {
              super();
              this.attachShadow({ mode: "open" });
              this.shadowRoot?.append(document.createElement("slot"));
            }

            override removeChild<T extends Node>(child: T) {
              if (child instanceof HTMLElement) {
                const animation = child.animate(
                  [{ opacity: 1 }, { opacity: 0 }],
                  200,
                );

                animation.onfinish = () => super.removeChild(child);
              } else {
                super.removeChild(child);
              }

              return child;
            }
          },
        );
      }
    }
  }, [render]);

  return render ? <SwSnackbarContainer {...props} ref={ref} /> : null;
});
