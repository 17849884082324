import { createThemeColorsContext } from "../colors-context/colors-context";

export type UiShadowsValue = {
  bottomNormal: string;
  bottomClose: string;
  topNormal: string;
};

const [UiShadows, useUiShadows] =
  createThemeColorsContext<UiShadowsValue>("uiShadows");

export { UiShadows, useUiShadows };
