export const config =
  import.meta.env.FLEET_FORMS_ENV === "production"
    ? // production
      {
        version: "yandex",
        passportURL: /\.com$/.test(window.location.hostname)
          ? "https://passport.yandex.com"
          : "https://passport.yandex.ru",
      }
    : // testing
      {
        version: "yandex",
        passportURL: /\.com$/.test(window.location.hostname)
          ? "https://passport-test.yandex.com"
          : "https://passport-test.yandex.ru",
      };
